import * as React from "react"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { Link } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { mainListItems, secondaryListItems } from "../Nav/listItems"
import Chart from "../Items/Chart"
import Overview from "../Items/Overview"
import RecentTable from "../Items/RecentTable"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import useMediaQuery from "@mui/material/useMediaQuery"
import Footer from "../Items/Copyright"
import { useEffect } from "react"

export default function Support() {
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  useEffect(() => {
    document.title = "BGA Warranty | Support"
  }, [])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: isMobile ? "1em" : "2em",
              marginTop: "0",
              marginBottom: "13vh",
            }}
          >
            {
              <video controls width="900" height="500">
                <source src="videos/BGA Warranty System.mp4" type="video/mp4" />
              </video>
            }
            <Typography
              variant={isMobile ? "body1" : "h6"}
              align="center"
              color="text.secondary"
              paragraph
              sx={{ marginTop: "2em" }}
            >
              For all enquiries related to claims, contact{" "}
              <a
                href="mailto:warranty@bgautomotive.co.uk"
                style={{ color: "grey" }}
              >
                <strong>warranty@bgautomotive.co.uk</strong>
              </a>
            </Typography>
            <Typography
              variant={isMobile ? "body1" : "h6"}
              align="center"
              color="text.secondary"
              paragraph
              sx={{ marginBottom: "3em" }}
            >
              For technical/IT related enquiries, contact{" "}
              <a
                href="mailto:itsupport@bgautomotive.co.uk"
                style={{ color: "grey" }}
              >
                <strong>itsupport@bgautomotive.co.uk</strong>
              </a>
            </Typography>
            <Footer />
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
