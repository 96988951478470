import React, { useState, useContext } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Copyright from "../Items/Copyright"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import AuthContext from "../../AuthContext"
import "../Pages/style.css"
import { API_URL } from "../../config"

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    padding: "8.5px 14px",
  },
  "& .MuiInputLabel-root": {
    top: "-6px",
  },
})

/**
 * Form for uploading images
 * @returns {JSX.Element}
 */
function ImageForm() {
  const [formData, setFormData] = useState({
    attachments: [],
  })

  const [errors, setErrors] = useState({})
  const navigate = useNavigate()
  const { token } = useContext(AuthContext)

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      attachments: event.target.files,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const data = new FormData()
    Array.from(formData.attachments).forEach((file) => {
      data.append("attachments", file)
    })

    axios
      .post(`${API_URL}/attachment`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        navigate("/success")
      })
      .catch((error) => {
        console.error("Error posting claim:", error)
      })
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: 4,
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 0, mb: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Image Form
            </Typography>

            <Paper
              sx={{
                p: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <form className="claim-form" onSubmit={handleSubmit}>
                <hr
                  style={{
                    border: "1px solid #ccc",
                    width: "93%",
                    margin: "5px",
                    marginBottom: "20px",
                    marginTop: "25px",
                  }}
                />
                <label className="form-label">
                  Upload Files
                  <input
                    className="form-input"
                    type="file"
                    accept="image/*, application/pdf"
                    name="attachments"
                    multiple
                    onChange={handleFileChange}
                  />
                </label>
                <input className="form-submit" type="submit" value="Submit" />
              </form>
            </Paper>
          </Box>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  )
}

export default ImageForm
