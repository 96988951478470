import React, { useState } from "react"
import Button from "@mui/material/Button"
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer"
import logo from "../../assets/POPPY-BGA-LOGO.png" // Ensure the path to your logo is correct
import reportStyle from "./ReportStyle.js"

import { PDFImageContainer } from "../Items/Report.js"

const PDFRow = ({ label, value }) => (
  <View style={reportStyle.row}>
    <Text style={reportStyle.label}>{label}</Text>
    <Text style={reportStyle.value}>{value}</Text>
  </View>
)

const CARDocument = ({ formData }) => (
  <Document>
    <Page size="A4" style={reportStyle.page}>
      {/* Header with Logo */}
      <View style={reportStyle.header}>
        <Image style={reportStyle.logo} src={logo} />
      </View>
      <Text style={reportStyle.title}>Corrective Action Report</Text>

      {/* Report Information */}
      <View style={reportStyle.sectionHeader}>
        <Text>Report Information</Text>
      </View>
      <PDFRow label="Report Number:" value={formData.reportNumber} />
      <PDFRow label="Inspected by:" value={formData.inspectedBy} />
      <PDFRow
        label="Inspected at:"
        value={new Date(formData.inspectedAt).toDateString()}
      />
      <PDFRow
        label="Component Description:"
        value={formData.componentDescription}
      />
      <PDFRow label="Factory Number:" value={formData.factoryNumber} />
      <PDFRow label="Supplier Name:" value={formData.supplierName} />
      <PDFRow label="Supplier Reference:" value={formData.supplierReference} />
      <PDFRow label="Year: " value={formData.year} />
      <PDFRow label="Month: " value={formData.month} />
      <PDFRow label="Product: " value={formData.product} />
      <PDFRow label="Product ID: " value={formData.productId} />
      <PDFRow label="Reference Status:" value={formData.referenceStatus} />
      <PDFRow label="Batch Code:" value={formData.batchCode} />
    </Page>
    {formData.sections &&
      formData.sections.map((section, index) => (
        <Page size="A4" style={reportStyle.page}>
          <View key={index}>
            <View style={reportStyle.sectionHeader}>
              <Text>
                Section {index + 1} - {section.title}
              </Text>
            </View>
            <PDFRow label="Description:" value={section.description} />
            <PDFRow label="Actions to be taken:" value={section.actions} />
            <PDFImageContainer images={section.images} index={index} />
          </View>
        </Page>
      ))}
  </Document>
)

function RenderCAR({ formData, style }) {
  return (
    <PDFViewer style={style}>
      <CARDocument formData={formData} />
    </PDFViewer>
  )
}

function DownloadCAR({ formData, children }) {
  return (
    <PDFDownloadLink
      document={<CARDocument formData={formData} />}
      fileName="CAR_Report.pdf"
    >
      {children}
    </PDFDownloadLink>
  )
}

function UploadCAR({ formData, children }) {
  return (
    <PDFDownloadLink
      document={<CARDocument formData={formData} />}
      fileName="CAR_Report.pdf"
    >
      {children}
    </PDFDownloadLink>
  )
}

export { RenderCAR, DownloadCAR, UploadCAR }
