import React, { useState } from "react"
import Button from "@mui/material/Button"
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer"
import logo from "../../assets/POPPY-BGA-LOGO.png" // Ensure the path to your logo is correct
import reportStyle from "./ReportStyle.js"

const PDFRow = ({ label, value }) => (
  <View style={reportStyle.row}>
    <Text style={reportStyle.label}>{label}</Text>
    <Text style={reportStyle.value}>{value}</Text>
  </View>
)

const InspectionDocument = ({ formData }) => (
  <Document>
    <Page size="A4" style={reportStyle.page}>
      {/* Header with Logo */}
      <View style={reportStyle.header}>
        <Image style={reportStyle.logo} src={logo} />
      </View>
      <Text style={reportStyle.title}>Claim Examination Document</Text>

      {/* Customer Details Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Customer Details</Text>
      </View>
      <PDFRow
        label="Customer Details:"
        value={formData.claim.customerDetails.customer}
      />
      <PDFRow
        label="Customer Account Code:"
        value={formData.claim.customerDetails.acc_code}
      />
      <PDFRow
        label="Claim Unique Identifier:"
        value={formData.claim.details.unique_ref}
      />

      {/* 
      <Text>{JSON.stringify(formData.claim)}</Text>
      */}
      <View style={reportStyle.sectionHeader}>
        <Text>Admin Details</Text>
      </View>

      <PDFRow label="Contact Name:" value={formData.info.contactName} />
      <PDFRow
        label="Contact No:"
        value={formData.claim.details.contact_number}
      />
      <PDFRow label="Claims Advisor:" value={formData.info.claimsAdvisor} />
      <PDFRow label="Contact details." value={formData.info.contactDetails} />

      {/* Examination formData.claim.details.Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Examination details</Text>
      </View>
      <PDFRow
        label="Date of Examination:"
        value={new Date(formData.info.inspectedAt).toDateString()}
      />
      <PDFRow label="Product Part No:" value={formData.claim.details.art_num} />
      <PDFRow label="Batch Code:" value={formData.claim.details.batch_code} />
      <PDFRow
        label="Vehicle Registration Number:"
        value={formData.claim.details.vehicle_reg}
      />
      <PDFRow
        label="Chassis Number:"
        value={formData.claim.details.chassis_num}
      />
      <PDFRow
        label="Vehicle Manufacturer:"
        value={formData.info.vehicleManufacturer}
      />
      <PDFRow label="Vehicle Model:" value={formData.info.vehicleModel} />
      <PDFRow
        label="Year Vehicle Manufactured:"
        value={new Date(formData.info.yearVehicleManufactured).toDateString()}
      />
      <PDFRow label="Engine CC:" value={formData.info.engineCC} />
      <PDFRow label="Engine Code:" value={formData.info.engineCode} />
      <PDFRow
        label="Date of Installation:"
        value={new Date(
          formData.claim.details.installation_date
        ).toDateString()}
      />
      <PDFRow
        label="Date of Removal:"
        value={new Date(formData.claim.details.removal_date).toDateString()}
      />
      <PDFRow
        label="Vehicle Mileage:"
        value={
          formData.claim.details.mileage_failed +
          " " +
          formData.claim.details.mileage_units
        }
      />
      <PDFRow
        label="Mileage of Claimed Component:"
        value={
          formData.info.mileage + " " + formData.claim.details.mileage_units
        }
      />
      <PDFRow label="SRN Number:" value={formData.claim.collection.srn} />

      {/* Complaint Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Complaint</Text>
      </View>
      <Text style={{ fontSize: 12 }}>{formData.info.complaint} </Text>

      {/* Observations Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Observations</Text>
      </View>
      <Text style={{ fontSize: 12 }}>{formData.info.observations} </Text>

      {/* Conclusion Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Conclusion</Text>
      </View>
      <Text style={{ fontSize: 12 }}>{formData.info.conclusion} </Text>

      {/* Appendix Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Appendix</Text>
      </View>
      {formData.info.images &&
        formData.info.images.map((image, index) => (
          <Image
            key={index}
            style={reportStyle.image}
            src={URL.createObjectURL(image)}
          />
        ))}
    </Page>
  </Document>
)

function RenderInspection({ formData, style }) {
  return (
    <PDFViewer style={style}>
      <InspectionDocument formData={formData} />
    </PDFViewer>
  )
}

function DownloadInspection({ formData, children }) {
  return (
    <PDFDownloadLink
      document={<InspectionDocument formData={formData} />}
      fileName="Inspection_Report.pdf"
    >
      {children}
    </PDFDownloadLink>
  )
}

export { RenderInspection, DownloadInspection }
