import * as React from "react"
import { Link } from "react-router-dom"
import { Box, Container, Typography } from "@mui/material"
import logo from "../../assets/150x150.png" // Adjust the path as needed

/**
 * Copyright information for the footer
 * @param {*} props
 * @returns
 */
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="#" style={{ color: "inherit", textDecoration: "none" }}>
        BGA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

/**
 * Footer component containing the copyright information
 * @returns
 */
export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === theme.palette.grey[800],
      }}
    >
      <Container maxWidth="sm" align="center">
        <img
          src={logo}
          alt="BG Automotive Logo"
          style={{ width: "40px", marginBottom: "0px" }}
        />
        <Typography variant="body2">
          <Link
            to="/policy"
            style={{ textDecoration: "none", color: "gray" }}
            sx={{
              "&:hover": {
                color: "orange",
              },
            }}
          >
            Claim Policy
          </Link>
        </Typography>
        <Copyright />
      </Container>
    </Box>
  )
}
