import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useLocation, Link } from "react-router-dom"
import exampleAccCode from "../../assets/example_acc_code2.png"
import axios from "axios"
import Spinner from "../Items/Spinner"
import InfoTooltip from "../Items/InfoTooltip"
import AuthContext from "../../AuthContext"
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material"

const Login = () => {
  const [accCode, setAccCode] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const navigate = useNavigate()
  const location = useLocation()
  const { login } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = "BGA Warranty | Login"
  }, [])

  useEffect(() => {
    if (location.state && location.state.successMessage) {
      setSuccessMessage(location.state.successMessage)
    }
  }, [location.state])

  const handleLogin = async (e) => {
    setLoading(true)
    e.preventDefault()

    const success = await login(accCode, password)
    if (success) {
      navigate("/dashboard")
    } else {
      setError("Login failed. Please check your credentials and try again.")
    }
    setLoading(false)
  }

  return (
    <Container sx={{ width: "100%" }}>
      {loading && <Spinner />}
      <Box
        p={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="white"
        sx={{ maxWidth: "30rem", margin: "auto", marginTop: "10rem" }}
      >
        {successMessage && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        )}
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
        </Box>
        <form onSubmit={handleLogin}>
          <Box mb={2}>
            <InfoTooltip
              placement="right-end"
              title={"Account code"}
              content={
                <>
                  <p>
                    {
                      "You can find your account code in your invoices under 'A/C No.'"
                    }
                  </p>
                  <img src={exampleAccCode} alt="" height="100%" width="100%" />
                  <p>{"In the image above, the account code is AAA001"}</p>
                </>
              }
            />
            <TextField
              fullWidth
              name="accCode"
              label="Account Code"
              placeholder="e.g. AAA001"
              variant="outlined"
              value={accCode}
              onChange={(e) => {
                setAccCode(e.target.value)
                setError("")
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
                setError("")
              }}
            />
          </Box>
          <Box mt={2}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
            >
              Login
            </Button>
          </Box>
          {error && (
            <Box mt={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
        </form>
        <Box mt={2} textAlign="center">
          <Typography variant="body2">
            <Link
              style={{ color: "#636363", textDecoration: "none" }}
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default Login
