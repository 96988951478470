import React, { useContext } from "react"
import "./StepBar.css"
import { ProgressBar, Step } from "react-step-progress-bar"
import { useNavigate, useLocation } from "react-router-dom"
import AuthContext from "../../AuthContext"

/**
 * Component for the progress bar in the claim page
 * @param {*} param0
 * @returns
 */
const StepBar = ({ currentStatus }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const claimNo = searchParams.get("id")
  const claimStage = searchParams.get("stage")
  const { userRole } = useContext(AuthContext)

  let stepPercentage = 0
  let currentStep = 0

  // const steps = ["Submit", "Collection", "Decision", "Pricing", "Resolved"];
  const steps =
    userRole === "admin"
      ? ["Submit", "Collection", "Decision", "Pricing", "Resolved"]
      : ["Submit", "Collection", "Decision", "Resolved"]

  const stageIndex =
    userRole === "admin"
      ? {
          submit: 1,
          collection: 2,
          decision: 3,
          pricing: 4,
          resolved: 5,
        }
      : {
          submit: 1,
          collection: 2,
          decision: 3,
          resolved: 4,
        }

  let currentStageIndex = stageIndex[claimStage]

  function getCurrentStep(userRole, currentStatus, steps) {
    switch (currentStatus) {
      case "Submit":
        return 1
      case "Collection":
        return 2
      case "Decision":
        return 3
      case "Pricing":
        return userRole === "admin" ? 4 : 3 // "Pricing" only for admin
      case "Resolved":
        return userRole === "admin" ? steps.length : 4 // different for admin vs others
      default:
        return 0
    }
  }

  currentStep = getCurrentStep(userRole, currentStatus, steps)

  stepPercentage = (currentStep / steps.length) * 100

  const onPageNumberClick = (page, step) => {
    if (step <= currentStep) {
      switch (page) {
        case "Submit":
          navigate(`/claim?id=${claimNo}&stage=submit`)
          break
        case "Collection":
          navigate(`/claim?id=${claimNo}&stage=collection`)
          break
        case "Decision":
          navigate(`/claim?id=${claimNo}&stage=decision`)
          break
        case "Pricing":
          navigate(`/claim?id=${claimNo}&stage=pricing`)
          break
        case "Resolved":
          navigate(`/claim?id=${claimNo}&stage=resolved`)
          break
        default:
          break
      }
    }
  }

  const getCursorStyle = (step) => {
    return step <= currentStep ? "pointer" : "default"
  }

  return (
    <div className="stepBar" style={{ marginTop: "4rem" }}>
      <ProgressBar percent={stepPercentage}>
        {steps.map((step, index) => (
          <Step key={index}>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${
                  index + 1 <= currentStep ? "accomplished" : ""
                }  ${currentStageIndex === index + 1 ? "current-step" : ""}`}
                onClick={() => onPageNumberClick(step, index + 1)}
                style={{ cursor: getCursorStyle(index + 1) }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "0px",
                  }}
                >
                  {step}
                </span>
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  )
}

export default StepBar
