import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
function Unauthorized() {
  const navigate = useNavigate()
  useEffect(() => {
    document.title = "BGA Warranty | Unauthorized"
  }, [])
  return (
    <Box
      sx={{
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        mt: 30,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center", marginTop: "0px" }}>
        <h1>403 - Unauthorized</h1>
        <p>You do not have permission to view this page.</p>
        <input
          className="inputButton"
          type="button"
          onClick={() => navigate(`/dashboard`)}
          value="Back to Dashboard"
          style={{
            marginTop: "20px",
            padding: "6px 12px",
            fontSize: "18px",
            cursor: "pointer",
          }}
        />
      </div>
    </Box>
  )
}

export default Unauthorized
