import * as React from "react"
import moment from "moment"
import { useState, useEffect, useContext, useRef } from "react"
import { DataGrid } from "@mui/x-data-grid"
import Title from "../Items/Title"
import Spinner from "../Items/Spinner"
import { useNavigate, useLocation } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AuthContext from "../../AuthContext"
import { Paper, Container, Typography } from "@mui/material"
import axios from "axios"
const { API_URL } = require("../../config")
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

import QRCode from "react-qr-code"

export default function Confirm() {
  useEffect(() => {
    document.title = "BGA Warranty | Confirm"
  }, [])

  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [loading, setLoading] = useState(false)
  // const [selectedRowIds, setSelectedRowIds] = useState([])
  // const [selectedRows, setSelectedRows] = useState([])
  const [policyAccepted, setPolicyAccepted] = useState(false)
  const [error, setError] = useState("")
  const { token } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [details, setDetails] = useState({})

  useEffect(() => {
    const { state } = location
    if (state && state.uploadedData) {
      const rowsWithId = state.uploadedData.map((row, index) => ({
        id: index + 1, // Ensure unique IDs
        ...row,
        files: [],
      }))
      setRows(rowsWithId)
    } else {
    }

    fetchData()
  }, [location])

  const fetchData = async () => {
    const response = await axios.get(`${API_URL}/auth/whoami`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    setDetails(response.data)
  }

  // useEffect(() => {
  //   const selectedRowData = rows.filter((row) =>
  //     selectedRowIds.includes(row.id)
  //   )
  //   setSelectedRows(selectedRowData)
  // }, [selectedRowIds, rows])

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const handleFileChange = (rowId, event) => {
    const selectedFiles = event.target.files
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId ? { ...row, files: selectedFiles } : row
      )
    )
  }

  const handleContactNumberChange = (event) => {
    setContactNumber(event.target.value)
  }

  const handleConfirm = async () => {
    setLoading(true)
    if (contactNumber === "") {
      setError("Please provide a contact number.")
      setLoading(false)
      return
    }

    let forms = []

    console.log(details)

    if (token) {
      for (const [index, row] of rows.entries()) {
        const formData = new FormData()

        formData.append("index", index)

        for (const file of row.files) {
          formData.append("files", file)
        }
        formData.append("customer_ref", row.customer_ref)
        formData.append("art_num", row.art_num)
        formData.append("batch_code", row.batch_code)
        formData.append("parts_value", row.parts_value)
        formData.append("vehicle_reg", row.vehicle_reg)
        formData.append("chassis_num", row.chassis_num)
        formData.append("mileage_units", row.mileage_units)
        formData.append("mileage_fitted", row.mileage_fitted)
        formData.append("mileage_failed", row.mileage_failed)

        formData.append(
          "installation_date",
          moment(row.installation_date, "DD/MM/YYYY").format("YYYY-MM-DD")
          // row.installation_date
        )
        formData.append(
          "removal_date",
          moment(row.removal_date, "DD/MM/YYYY").format("YYYY-MM-DD")
        )

        formData.append("claim_description", row.claim_description)
        formData.append("contact_number", contactNumber)

        formData.append("customer_acc_code", details.acc_code)

        try {
          const response = await axios.post(
            `${API_URL}/claims/new/check_csv`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
        } catch (error) {
          if (error.response && error.response.status === 422) {
            setError(error.response.data)
          } else if (error.response && error.response.status === 500) {
            setError(
              "Invalid inputs, one of the parts you have submitted may be invalid"
            )
          } else {
            // setError(
            //   "An error occurred while confirming your claims. Please log out and log back in again."
            // )
            navigate("/session-expired")
          }
          setLoading(false)
          return
        }

        forms.push(formData)
        setError("")
      }

      for (const form of forms) {
        try {
          const response = await axios.post(
            `${API_URL}/claims/new/no_quibble`,
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
        } catch (error) {
          setError(
            "Failed to upload claims, please check your connection and try logging out and back in."
          )
          setLoading(false)
          return
        }
      }
      navigate("/success")
    }
  }

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = rows.map((row) => (row.id === newRow.id ? newRow : row))
    setRows(updatedRows)
    return newRow
  }

  const filteredRows = rows.filter(
    (row) =>
      row.art_num.toLowerCase().includes(search.toLowerCase()) ||
      (row.customer_ref || "").toLowerCase().includes(search.toLowerCase())
  )

  const columns = [
    {
      field: "files",
      headerName: "Attachments",
      width: 190,
      editable: false,
      renderCell: (params) => (
        <input
          type="file"
          multiple
          onChange={(event) => handleFileChange(params.row.id, event)}
        />
      ),
      excludeProcessing: true,
    },
    {
      field: "customer_ref",
      headerName: "Customer Ref",
      width: 120,
      editable: true,
    },
    { field: "art_num", headerName: "Part Num", width: 100, editable: true },
    {
      field: "batch_code",
      headerName: "Batch Code",
      width: 100,
      editable: true,
    },
    {
      field: "parts_value",
      headerName: "Total Parts Value",
      width: 125,
      editable: true,
    },
    {
      field: "vehicle_reg",
      headerName: "Vehicle Reg",
      width: 125,
      editable: true,
    },
    {
      field: "chassis_num",
      headerName: "Chassis Num",
      width: 125,
      editable: true,
    },
    {
      field: "mileage_units",
      headerName: "Mileage Units",
      width: 100,
      type: "singleSelect",
      valueOptions: ["miles", "km"],
      editable: true,
    },
    {
      field: "mileage_fitted",
      headerName: "Mileage Fitted",
      width: 100,
      editable: true,
    },
    {
      field: "mileage_failed",
      headerName: "Mileage Failed",
      width: 100,
      editable: true,
    },
    {
      field: "installation_date",
      headerName: "Installation Date",
      width: 150,
      editable: true,
      valueGetter: (value) => moment(value, "DD/MM/YYYY").toDate(),
      // valueFormatter: (params) =>
      //   params.toDateString("en-US", {
      //     day: "numeric",
      //     month: "numeric",
      //     year: "numeric",
      //   }),
      type: "date",
    },
    {
      field: "removal_date",
      headerName: "Removal Date",
      width: 150,
      editable: true,
      valueGetter: (value) => moment(value, "DD/MM/YYYY").toDate(),
      // valueFormatter: (params) =>
      //   params.toDateString("en-US", {
      //     day: "numeric",
      //     month: "numeric",
      //     year: "numeric",
      //   }),
      type: "date",
    },
    {
      field: "claim_description",
      headerName: "Details",
      width: 200,
      editable: true,
    },
  ]

  return (
    <React.Fragment>
      <Paper sx={{ margin: "3rem", padding: 3, marginBottom: "10rem" }}>
        <Title>Confirm Upload</Title>
        <Box sx={{ marginBottom: 2, width: "100%" }}>
          <TextField
            label="Search"
            variant="outlined"
            value={search}
            onChange={handleSearchChange}
            fullWidth
          />
        </Box>
        <span style={{ fontSize: "0.85em" }}>
          Double click on fields to edit
        </span>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            disableVirtualization
            disableRowSelectionOnClick
            checkboxSelection={false}
            // onRowSelectionModelChange={(ids) => {
            //   const selectedIDs = new Set(ids)
            //   const selectedRowData = filteredRows.filter((row) =>
            //     selectedIDs.has(row.id)
            //   )
            //   setSelectedRowIds(ids)
            //   setSelectedRows(selectedRowData)
            //   if (ids.length > 0) {
            //     setError("") // Clear error message if there are selected rows
            //   }
            // }}
            // selectionModel={selectedRowIds}
            processRowUpdate={handleProcessRowUpdate}
          />
        </Box>
        <Box>
          <TextField
            label="Contact Number"
            variant="outlined"
            value={contactNumber}
            onChange={handleContactNumberChange}
            required
            fullWidth
          />
        </Box>
        <FormControlLabel
          sx={{ mb: "10px" }}
          control={
            <Checkbox
              checked={policyAccepted}
              onChange={(e) => setPolicyAccepted(e.target.checked)}
              color="primary"
            />
          }
          label={
            <Typography sx={{ fontSize: "0.8em" }}>
              {"I accept the "}
              <a
                style={{ color: "#F2AC00" }}
                href="/policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                claim policy
              </a>
              {" and have submitted the required details/documents."}
            </Typography>
          }
        />
        {error && (
          <Typography color="error" sx={{ marginTop: 2 }}>
            {error}
          </Typography>
        )}
        <Box sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleConfirm}
            disabled={!policyAccepted}
          >
            Confirm Upload
          </Button>
        </Box>
      </Paper>
      {loading && <Spinner />}
    </React.Fragment>
  )
}
