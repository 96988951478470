import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import InfoIcon from "@mui/icons-material/Info"

import { styled } from "@mui/material/styles"

/**
 * Custom styled tooltip with white background
 * @type {import("@mui/material").ComponentNameToClassKey}
 * @returns {JSX.Element}
 * @constructor
 * @param {object} props
 */
export const WhiteTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

/**
 * Custom tooltip with info icon
 * @param {*} param0
 * @returns
 */
export default function InfoToolTip({ className, title, content, ...props }) {
  return (
    <WhiteTooltip
      title={
        <>
          <Typography color="inherit">
            <u>{title}</u>
            <br />
            {content}
          </Typography>
        </>
      }
      {...props}
      className={className}
    >
      <InfoIcon
        sx={{ marginLeft: "5px", opacity: "40%", fontSize: "inherit" }}
      />
    </WhiteTooltip>
  )
}
