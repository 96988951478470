import React, { useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import ResponsiveAppBar from "./Components/Nav/AppBar"
import Drawer from "./Components/Nav/Drawer"
import PrivateRoute from "./PrivateRoute"
import AdminRoute from "./Components/Admin/AdminRoute"
import { AuthProvider } from "./AuthContext"
import Dashboard from "./Components/Pages/Dashboard"
import Claims from "./Components/Pages/Claims"
import Home from "./Components/Pages/Home"
import Login from "./Components/Pages/Login"
import Register from "./Components/Pages/Register"
import Profile from "./Components/Pages/Profile"
import Invite from "./Components/Pages/Invite"
import AdminPanel from "./Components/Admin/AdminPanel"
import NoQuibble from "./Components/Pages/NoQuibble"
import FullClaim from "./Components/Pages/FullClaim"
import Resolved from "./Components/Pages/Resolved"
import Analytics from "./Components/Pages/Analytics"
import Integrations from "./Components/Pages/Integrations"
import Claim from "./Components/Claim/Claim"
import NewClaim from "./Components/Pages/NewClaim"
import NoQuibbleForm from "./Components/Forms/NoQuibbleForm"
import FullClaimForm from "./Components/Forms/FullClaimForm"
import Success from "./Components/Pages/Success"
import Confirm from "./Components/Pages/Confirm"
import ConfirmFPS from "./Components/Pages/ConfirmFPS"
import SessionExpired from "./Components/Pages/SessionExpired"
import Unauthorized from "./Components/Pages/Unauthorized"
import ImageForm from "./Components/Forms/ImageForm"
import DraftSuccess from "./Components/Pages/DraftSuccess"
import PDF from "./Components/Items/PDF"
import ErrorBoundary from "./Components/Items/ErrorBoundary"
import Support from "./Components/Pages/Support"
import ResponsiveToolbar from "./Components/Nav/Toolbar"
import ForgotPassword from "./Components/Pages/ForgotPassword"
import ResetPassword from "./Components/Pages/ResetPassword"
import SetupAccount from "./Components/Pages/SetupAccount"
import ChangePassword from "./Components/Pages/ChangePassword"
import Policy from "./Components/Pages/Policy"
import PricingConfirm from "./Components/Pages/PricingConfirm"
import DenySuccess from "./Components/Pages/DenySuccess"
import ApproveSuccess from "./Components/Pages/ApproveSuccess"
import CARReport from "./Components/Items/CARReport"
import FDRReport from "./Components/Items/FDRReport"
import InspectionReport from "./Components/Items/InspectionReport"

import { API_URL } from "./config"

const theme = createTheme({
  palette: {
    primary: {
      main: "#326633",
    },
    secondary: {
      main: "#F2AC00",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Target the root slot
          color: "#ffffff", // Set the text color to blue
        },
      },
    },
  },
})

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("")

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <div className="App">
          <ErrorBoundary>
            <Router>
              <ResponsiveAppBar />
              <ResponsiveToolbar />
              <Box sx={{ display: "flex" }}>
                <Drawer />
                <CssBaseline />
                <Box
                  component="main"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                  }}
                >
                  <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/request-an-account" element={<Register />} />
                    <Route
                      path="/pricing-confirm"
                      element={<PricingConfirm />}
                    />
                    <Route
                      path="/approve-success"
                      element={<ApproveSuccess />}
                    />
                    <Route path="/deny-success" element={<DenySuccess />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/setup-account" element={<SetupAccount />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route
                      path="/session-expired"
                      element={<SessionExpired />}
                    />
                    <Route path="/support" element={<Support />} />

                    <Route element={<PrivateRoute />}>
                      <Route
                        path="/"
                        element={
                          <Home
                            email={email}
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/claims" element={<Claims />} />
                      <Route path="/no-quibble" element={<NoQuibble />} />
                      <Route path="/full-claim" element={<FullClaim />} />
                      <Route path="/resolved" element={<Resolved />} />
                      <Route path="/analytics" element={<Analytics />} />
                      <Route path="/integrations" element={<Integrations />} />
                      <Route
                        path="/change-password"
                        element={<ChangePassword />}
                      />
                      <Route path="/policy" element={<Policy />} />
                      <Route
                        path="/new-claim/no-quibble"
                        element={<NoQuibbleForm />}
                      />
                      <Route
                        path="/new-claim/full-claim"
                        element={<FullClaimForm />}
                      />
                      <Route path="/new-claim/confirm" element={<Confirm />} />
                      <Route
                        path="/new-claim/confirm-fps"
                        element={<ConfirmFPS />}
                      />
                      <Route path="/claim" element={<Claim />} />
                      <Route path="/new-claim" element={<NewClaim />} />
                      <Route path="/success" element={<Success />} />
                      <Route path="/draft/success" element={<DraftSuccess />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/invite" element={<Invite />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/image" element={<ImageForm />} />
                      <Route path="/pdf-viewer" element={<PDF />} />
                      <Route path="/car-report" element={<CARReport />} />
                      <Route path="/fdr-report" element={<FDRReport />} />
                      <Route
                        path="/inspection-report"
                        element={<InspectionReport />}
                      />
                      <Route element={<AdminRoute />}>
                        <Route path="/admin" element={<AdminPanel />} />
                      </Route>
                    </Route>
                  </Routes>
                </Box>
              </Box>
            </Router>
          </ErrorBoundary>
        </div>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
