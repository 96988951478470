import * as React from "react"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { Link } from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { mainListItems, secondaryListItems } from "../Nav/listItems"
import Chart from "../Items/Chart"
import Overview from "../Items/Overview"
import RecentTable from "../Items/RecentTable"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { InfoTooltip, WhiteTooltip } from "../Items/InfoTooltip"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { useContext } from "react"
import Copyright from "../Items/Copyright"
import AuthContext from "../../AuthContext"
import axios from "axios"
import { useEffect } from "react"

import { API_URL } from "../../config"

export default function NewClaim() {
  const navigate = useNavigate()
  const { isAuthenticated, token, userRole } = useContext(AuthContext)
  const [bulkFile, setBulkFile] = React.useState(null)

  const handleBulkUpload = async (event) => {
    event.preventDefault()
    if (!bulkFile) {
      return
    }

    const data = new FormData()
    data.append("file", bulkFile)

    try {
      const response = await axios.post(`${API_URL}/claims/new/fps_csv`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })

      navigate("/new-claim/confirm-fps", {
        state: { uploadedData: response.data },
      })
    } catch (error) {}
  }

  const handleBulkFileChange = (event) => {
    setBulkFile(event.target.files[0])
  }

  useEffect(() => {
    document.title = "BGA Warranty | New Claim"
  }, [])

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "105vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
              >
                New Claim
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="text.secondary"
                paragraph
              >
                Please select the type of claim you would like to make.
              </Typography>

              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <WhiteTooltip
                  placement="bottom"
                  title={
                    <>
                      <Typography color="inherit">
                        <u>{"No Quibble"}</u>
                        <br />
                        {
                          "A claim on the BGA part itself, excluding any additional costs."
                        }
                      </Typography>
                    </>
                  }
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Link to="/new-claim/no-quibble">
                      {" "}
                      <Button
                        variant="contained"
                        color="info"
                        sx={{
                          width: { xs: "150px", md: "200px" },
                          height: { xs: "75px", md: "100px" },
                        }}
                      >
                        <p>No-Quibble</p>
                      </Button>
                    </Link>
                  </Box>
                </WhiteTooltip>
                <Box sx={{ flexGrow: 1, marginTop: "30px", m: "50px" }}>OR</Box>
                <WhiteTooltip
                  placement="bottom"
                  title={
                    <>
                      <Typography color="inherit">
                        <u>{"Full Claim"}</u>
                        <br />
                        {
                          "A claim on the full repair, including the BGA part and any additional costs."
                        }
                      </Typography>
                    </>
                  }
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Link to="/new-claim/full-claim">
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          width: { xs: "150px", md: "200px" },
                          height: { xs: "75px", md: "100px" },
                        }}
                      >
                        <p>Full-Claim</p>
                      </Button>
                    </Link>
                  </Box>
                </WhiteTooltip>
              </Paper>
            </Box>
          </Container>
          {userRole === "admin" && (
            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
              <Divider sx={{ my: 3 }} />

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    paddingBottom: "1vh",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="center"
                    color="text.primary"
                    gutterBottom
                  >
                    FPS Upload
                  </Typography>
                </Box>
                <Paper
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <form className="claim-form" onSubmit={handleBulkUpload}>
                    <label className="form-label">
                      Upload CSV:
                      <input
                        className="form-input"
                        type="file"
                        name="csv_file"
                        accept=".csv"
                        onChange={handleBulkFileChange}
                      />
                    </label>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="warning"
                        type="submit"
                        name="submit_csv"
                        sx={{ mb: 2 }}
                      >
                        <p>Upload CSV</p>
                      </Button>
                    </Box>
                  </form>
                </Paper>
              </Grid>
            </Container>
          )}

          <Copyright sx={{ pt: 4 }} />
        </Box>
      </Box>
    </Box>
  )
}
