import React, { useEffect, useState } from "react"
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
} from "@mui/material"
import { API_URL } from "../../config"
import axios from "axios"

const ForgotPassword = () => {
  useEffect(() => {
    document.title = "BGA Warranty | Forgot Password"
  }, [])
  const [accCode, setAccCode] = useState("")
  const [message, setMessage] = useState("")

  const handleForgotPassword = async (e) => {
    e.preventDefault()
    try {
      await axios.post(`${API_URL}/auth/forgot_password`, { acc_code: accCode })
      setMessage(
        "If an account with that code exists, a password reset link has been sent to the associated email."
      )
    } catch (error) {
      setMessage("An error occurred. Please try again later.", error)
    }
  }

  return (
    <Container maxWidth="sm" sx={{ padding: "130px" }}>
      <Box mt={5} p={3} boxShadow={3} borderRadius={2} bgcolor="white">
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Forgot Password
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Please enter your account code to reset your password.
          </Typography>
        </Box>
        <form onSubmit={handleForgotPassword}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Account Code"
              variant="outlined"
              value={accCode}
              onChange={(e) => setAccCode(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
            >
              Reset Password
            </Button>
          </Box>
          {message && (
            <Box mt={2}>
              <Alert severity="info">{message}</Alert>
            </Box>
          )}
        </form>
      </Box>
    </Container>
  )
}

export default ForgotPassword
