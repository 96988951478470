import * as React from "react"
import PropTypes from "prop-types"
import Typography from "@mui/material/Typography"

/**
 * Title component to display a title with a specific style
 * @param {*} props
 * @returns
 */
function Title(props) {
  return (
    <Typography
      component="h2"
      variant="h6"
      color="#000000"
      fontWeight={600}
      gutterBottom
    >
      {props.children}
    </Typography>
  )
}

Title.propTypes = {
  children: PropTypes.node,
}

export default Title
