import { useState, useEffect, useRef, useContext } from "react"
import { IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload"
import { Paper, Button } from "@mui/material"
import Spinner from "../Items/Spinner"
import AuthContext from "../../AuthContext"
import { useSearchParams } from "react-router-dom"
import { RenderCAR, DownloadCAR, UploadCAR } from "../Reports/GenerateCAR.js"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { Select } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { TextField } from "@mui/material"
import { fetchClaimData } from "./Report.js"
import reportStyle from "../Reports/ReportStyle.js"
const { API_URL } = require("../../config")

/**
 * Component for the CAR report page
 * @returns {JSX.Element}
 */
export default function CARReport() {
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [jsonOutput, setJsonOutput] = useState(null)
  const { token } = useContext(AuthContext)

  const sectionsRef = useRef([])

  const reportNumberRef = useRef(null)
  const inspectedByRef = useRef(null)
  const inspectedAtRef = useRef(new Date().toDateString())

  const componentDescriptionRef = useRef(null)
  const factoryNumberRef = useRef(null)
  const supplierNameRef = useRef(null)
  const supplierReferenceRef = useRef(null)
  const yearRef = useRef(null)
  const monthRef = useRef(null)
  const productRef = useRef(null)
  const productIdRef = useRef(null)
  const referenceStatusRef = useRef(null)
  const batchCodeRef = useRef(null)

  const [claim, setClaim] = useState({})

  const [loading, setLoading] = useState(true)

  const [searchParams] = useSearchParams()
  const claimNo = searchParams.get("id")

  useEffect(() => {
    async function getData() {
      try {
        const result = await fetchClaimData(claimNo, token)
        setClaim(result)
      } catch (err) {
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [claimNo, token])

  const addSection = () => {
    sectionsRef.current.push({
      title: "",
      description: "",
      actions: "",
      images: [],
    })
    setTriggerUpdate((prev) => !prev)
  }

  const handleSectionChange = (index, field, value) => {
    sectionsRef.current[index] = {
      ...sectionsRef.current[index],
      [field]: value,
    }
  }

  const handleDeleteSection = (index) => {
    // Filter out the section to be deleted
    const updatedSections = sectionsRef.current.filter((_, i) => i !== index)

    // Update the ref with the filtered sections
    sectionsRef.current = updatedSections

    // Trigger a re-render manually by updating state or using a dummy state change

    // Example: setting a dummy state to force re-render
    setTriggerUpdate((prev) => !prev)
  }

  const handleImageUpload = (index, event) => {
    const files = Array.from(event.target.files)
    sectionsRef.current[index].images = files
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = {
      claim: claim,
      details: claim.details,
      collection: claim.collection,
      decision: claim.decision,
      pricing: claim.pricing,
      reportNumber: reportNumberRef.current.value,
      inspectedBy: inspectedByRef.current.value,
      inspectedAt: inspectedAtRef.current.value,

      componentDescription: componentDescriptionRef.current.value,
      factoryNumber: factoryNumberRef.current.value,
      supplierName: supplierNameRef.current.value,
      supplierReference: supplierReferenceRef.current.value,
      year: yearRef.current.value,
      month: monthRef.current.value,
      product: productRef.current.value,
      productId: productIdRef.current.value,
      referenceStatus: referenceStatusRef.current.value,
      batchCode: batchCodeRef.current.value,
      sections: sectionsRef.current.map((section) => ({
        title: section.title,
        description: section.description,
        actions: section.actions,
        images: section.images,
      })),
    }

    setJsonOutput(formData)
  }

  return (
    <div style={reportStyle.outerStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ ...reportStyle.cardStyle, padding: 3, boxShadow: 3 }}>
            {loading ? (
              <Spinner />
            ) : (
              <div className="main-wrapper">
                <header>
                  <div className="header-wrapper">
                    <h2>Claim Details #{claim.details.unique_ref}</h2>
                  </div>
                </header>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <TextField
                    label="Report Number"
                    defaultValue={
                      claim.adminOverride.new_art_num !== null
                        ? claim.adminOverride.new_art_num
                        : claim.details.art_num
                    }
                    inputRef={reportNumberRef}
                    fullWidth
                  />
                  <TextField
                    label="Inspected by"
                    defaultValue="Kyall Stead"
                    inputRef={inspectedByRef}
                    fullWidth
                  />

                  <TextField
                    label="Inspected at"
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    inputRef={inspectedAtRef}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Component Description"
                    defaultValue={claim.decision.component_description}
                    inputRef={componentDescriptionRef}
                    fullWidth
                  />

                  <TextField
                    label="Factory Number"
                    defaultValue={claim.decision.factory_number}
                    inputRef={factoryNumberRef}
                    fullWidth
                  />

                  <TextField
                    label="Supplier Name"
                    defaultValue={claim.decision.supplier}
                    inputRef={supplierNameRef}
                    fullWidth
                  />

                  <TextField
                    label="Supplier Reference"
                    defaultValue={claim.decision.supplier_code}
                    inputRef={supplierReferenceRef}
                    fullWidth
                  />

                  <TextField
                    label="Year"
                    defaultValue={
                      (claim.decision.year && "20" + claim.decision.year) || ""
                    }
                    inputRef={yearRef}
                    fullWidth
                  />

                  <TextField
                    label="Month"
                    defaultValue={claim.decision.month}
                    inputRef={monthRef}
                    fullWidth
                  />

                  <TextField
                    label="Product"
                    defaultValue={claim.decision.product}
                    inputRef={productRef}
                    fullWidth
                  />

                  <TextField
                    label="Product ID"
                    defaultValue={claim.decision.product_id}
                    inputRef={productIdRef}
                    fullWidth
                  />

                  <FormControl fullWidth>
                    <InputLabel>Reference Status</InputLabel>
                    <Select
                      label="Reference Status"
                      defaultValue="Accepted"
                      inputRef={referenceStatusRef}
                    >
                      <MenuItem value="Accepted">Accepted</MenuItem>
                      <MenuItem value="Requires Improvement">
                        Requires Improvement
                      </MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    label="Batch Code"
                    defaultValue={claim.details.batch_code}
                    inputRef={batchCodeRef}
                    fullWidth
                  />
                  {sectionsRef.current.map((section, index) => (
                    <Paper
                      key={index}
                      sx={{
                        border: "1px solid #ccc",
                        padding: 2,
                        marginBottom: 2,
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
                        Section {index + 1}
                      </Typography>
                      <TextField
                        label="Title"
                        defaultValue={section.title}
                        onChange={(e) =>
                          handleSectionChange(index, "title", e.target.value)
                        }
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Description"
                        defaultValue={section.description}
                        onChange={(e) =>
                          handleSectionChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                        fullWidth
                        multiline
                        rows={4}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Actions"
                        defaultValue={section.actions}
                        onChange={(e) =>
                          handleSectionChange(index, "actions", e.target.value)
                        }
                        fullWidth
                        multiline
                        rows={4}
                        sx={{ marginBottom: 2 }}
                      />
                      <Box
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          my: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ marginBottom: 4, margin: "auto" }}
                        >
                          <DriveFolderUploadIcon sx={{ marginRight: "3px" }} />
                          <p>Upload Images</p>
                          <input
                            type="file"
                            multiple
                            hidden
                            onChange={(e) => handleImageUpload(index, e)}
                          />
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {index === sectionsRef.current.length - 1 && (
                          <IconButton
                            onClick={() => handleDeleteSection(index)}
                            color="error"
                            aria-label="delete section"
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Paper>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addSection}
                  >
                    <p>Add Section</p>
                  </Button>
                  <Button type="submit" variant="contained" color="secondary">
                    <p>Render</p>
                  </Button>
                </Box>
              </div>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ ...reportStyle.cardStyle, padding: 3, boxShadow: 3 }}>
            {jsonOutput && (
              <DownloadCAR formData={jsonOutput}>
                <Button variant="contained" color="secondary">
                  <p>Download</p>
                </Button>
              </DownloadCAR>
            )}
            {jsonOutput && (
              <UploadCAR formData={jsonOutput}>
                <Button variant="contained" color="primary">
                  <p>Upload (TODO)</p>
                </Button>
              </UploadCAR>
            )}
            {jsonOutput && (
              <RenderCAR
                formData={jsonOutput}
                style={{ width: "100%", minHeight: "90vh" }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
