import React from "react"
import { Typography, Box, Button } from "@mui/material"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"

/**
 * Error boundary component to catch errors in the application
 * and display a user-friendly error message
 * @extends React.Component
 * @returns {JSX.Element}
 * @constructor
 * @param {object} props
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by Error Boundary: ", error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            mt: 30,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", marginTop: "0px" }}>
            <h1>Something went wrong.</h1>
            <p>{this.state.error?.message}</p>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => window.location.reload()}
            >
              <ArrowBackIosIcon />
              <p>Try Again</p>
            </Button>
          </div>
        </Box>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
