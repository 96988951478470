import { createTheme } from "@mui/material/styles"
import { LineChart, axisClasses } from "@mui/x-charts"
import axios from "axios"
import Title from "./Title"
import React, { useEffect, useContext, useState } from "react"
import AuthContext from "../../AuthContext"
import { API_URL } from "../../config"

// Utility function to group claims by date
const groupClaimsByDate = (claims) => {
  const now = new Date()
  const sixtyDaysAgo = new Date(now.setDate(now.getDate() - 60))

  const groupedData = claims.reduce((acc, claim) => {
    const claimDate = new Date(claim.claim_date)
    if (claimDate >= sixtyDaysAgo && claim.status === "Resolved") {
      const dateStr = claim.claim_date.split("T")[0] // Extract the date part
      if (!acc[dateStr]) {
        acc[dateStr] = 0
      }
      acc[dateStr] += 1
    }
    return acc
  }, {})

  // Convert grouped data into an array of objects
  return Object.keys(groupedData)
    .sort()
    .map((date) => ({
      time: date,
      amount: groupedData[date],
    }))
}

/**
 * Chart component to display resolved claims by date
 * @returns {JSX.Element}
 */
export default function Chart() {
  const [data, setData] = useState([])
  const { token } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/claims/all`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          const groupedData = groupClaimsByDate(response.data)
          setData(groupedData)
        } catch (error) {
          console.error("Error fetching claims:", error)
        }
      } else {
        console.error("No token available")
      }
    }

    fetchData()
  }, [token])

  const theme = createTheme({
    palette: {
      primary: {
        main: "#F2AC00",
        light: "#F2AC50",
      },
      secondary: {
        main: "#326633",
      },
      text: {
        primary: "#000000",
        secondary: "#777777",
      },
    },
    typography: {
      body2: {
        fontSize: "0.875rem",
      },
      body1: {
        fontSize: "1rem",
      },
      h2: {
        color: "#000000",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#ffffff",
          },
        },
      },
    },
  })

  return (
    <React.Fragment>
      <Title>Resolved Claims by Date (Last 60 Days)</Title>
      <div style={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
        <LineChart
          dataset={data}
          margin={{
            top: 16,
            right: 20,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: "point",
              dataKey: "time",
              tickNumber: 10,
              tickLabelStyle: theme.typography.body2,
              label: "Date",
              labelStyle: {
                ...theme.typography.body1,
                fill: theme.palette.text.primary,
              },
            },
          ]}
          yAxis={[
            {
              label: "Resolved Claims",
              labelStyle: {
                ...theme.typography.body1,
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2,
              tickNumber: 5,
              format: (value) => value.toFixed(0), // Display y-axis with zero decimal places
            },
          ]}
          series={[
            {
              dataKey: "amount",
              showMark: false,
              color: theme.palette.primary.main,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: {
              stroke: theme.palette.text.secondary,
            },
            [`.${axisClasses.root} text`]: {
              fill: theme.palette.text.secondary,
            },
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: "translateX(-25px)",
            },
          }}
        />
      </div>
    </React.Fragment>
  )
}
