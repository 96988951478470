import * as React from "react"
import { useState, useEffect, useContext, useRef } from "react"
import { DataGrid } from "@mui/x-data-grid"
import Title from "../Items/Title"
import { useNavigate, useLocation } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AuthContext from "../../AuthContext"
import { Paper, Container, Typography } from "@mui/material"
import axios from "axios"
const { API_URL } = require("../../config")
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"

export default function ConfirmFPS() {
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState("")
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [claimType, setClaimType] = useState("")
  const [error, setError] = useState("")
  const { token } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    document.title = "BGA Warranty | FPS Confirm"
  }, [])

  useEffect(() => {
    const { state } = location
    if (state && state.uploadedData) {
      const rowsWithId = state.uploadedData.map((row, index) => ({
        id: index + 1, // Ensure unique IDs
        ...row,
      }))
      setRows(rowsWithId)
    } else {
    }
    setClaimType(state.uploadedData[0].claim_type)
  }, [location])

  useEffect(() => {
    const selectedRowData = rows.filter((row) =>
      selectedRowIds.includes(row.id)
    )
    setSelectedRows(selectedRowData)
  }, [selectedRowIds, rows])

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
  }

  const handleConfirm = async () => {
    if (selectedRows.length === 0) {
      setError("Please select at least one row to confirm.")
      return
    }

    if (token) {
      try {
        const response = await axios.post(
          `${API_URL}/claims/new/confirm_fps`,
          // add an additional field to each of the rows
          selectedRows.map((rows) => ({
            ...rows,
          })),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        navigate("/success")
      } catch (error) {
        if (error.response && error.response.status === 500) {
          setError("Invalid inputs" + JSON.stringify(error.response, null, 2))
        } else {
          setError("An error occurred while confirming the claims.")
        }
      }
    } else {
    }
  }

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = rows.map((row) => (row.id === newRow.id ? newRow : row))
    setRows(updatedRows)
    return newRow
  }

  const filteredRows = rows.filter(
    (row) =>
      row.art_num.toLowerCase().includes(search.toLowerCase()) ||
      (row.customer_ref || "").toLowerCase().includes(search.toLowerCase())
  )

  const columns = [
    {
      field: "customer_ref",
      headerName: "Customer Ref",
      width: 120,
      editable: true,
    },
    { field: "art_num", headerName: "Part Num", width: 100, editable: true },
    {
      field: "batch_code",
      headerName: "Batch Code",
      width: 100,
      editable: true,
    },
    // { field: "quantity", headerName: "Quantity", width: 100, editable: true },
    {
      field: "parts_value",
      headerName: "Total Parts Value",
      width: 125,
      editable: true,
    },
    {
      field: "vehicle_reg",
      headerName: "Vehicle Reg",
      width: 125,
      editable: true,
    },
    {
      field: "chassis_num",
      headerName: "Chassis Num",
      width: 125,
      editable: true,
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      width: 150,
      editable: true,
    },
    {
      field: "mileage_units",
      headerName: "Mileage Units",
      width: 100,
      editable: true,
    },
    {
      field: "mileage_fitted",
      headerName: "Mileage Fitted",
      width: 100,
      editable: true,
    },
    {
      field: "mileage_failed",
      headerName: "Mileage Failed",
      width: 100,
      editable: true,
    },
    {
      field: "installation_date",
      headerName: "Installation Date",
      width: 150,
      editable: true,
    },
    {
      field: "removal_date",
      headerName: "Removal Date",
      width: 150,
      editable: true,
    },
    {
      field: "claim_description",
      headerName: "Details",
      width: 200,
      editable: true,
    },
  ]

  const additionalColumns = [
    {
      field: "labour_value",
      headerName: "Labour Value",
      width: 200,
      editable: true,
    },
    {
      field: "labour_description",
      headerName: "Labour Description",
      width: 200,
      editable: true,
    },
    {
      field: "expense_value",
      headerName: "Expense Value",
      width: 200,
      editable: true,
    },
    {
      field: "expense_description",
      headerName: "Expense Description",
      width: 200,
      editable: true,
    },
  ]

  return (
    <React.Fragment>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",

          marginBottom: "20rem",
        }}
      >
        <Paper sx={{ p: 3, width: "100%", maxWidth: "1200px" }}>
          <Title>Confirm Upload</Title>
          <Box sx={{ marginBottom: 2, width: "100%" }}>
            <TextField
              label="Search"
              variant="outlined"
              value={search}
              onChange={handleSearchChange}
              fullWidth
            />
          </Box>
          <span style={{ fontSize: "0.85em" }}>
            Double click on fields to edit
          </span>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={filteredRows}
              disableVirtualization
              columns={
                //   ? columns
                //   : columns + additionalColumns
                [
                  ...columns,
                  ...(claimType === "Full Claim" ? additionalColumns : []),
                ]
              }
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 25]}
              pagination
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids)
                const selectedRowData = filteredRows.filter((row) =>
                  selectedIDs.has(row.id)
                )
                setSelectedRowIds(ids)
                setSelectedRows(selectedRowData)
                if (ids.length > 0) {
                  setError("") // Clear error message if there are selected rows
                }
              }}
              selectionModel={selectedRowIds}
              processRowUpdate={handleProcessRowUpdate}
            />
          </Box>
          {
            <FormControl
              variant="outlined"
              fullWidth
              sx={{ mb: "10px", mt: "10px" }}
            >
              <InputLabel>Detected claim type</InputLabel>
              <Select
                label="Detected claim type"
                name="name"
                value={claimType}
                onChange={(e) => {
                  setClaimType(e.target.value)
                }}
                fullWidth
              >
                <MenuItem value="No Quibble">No Quibble</MenuItem>
                <MenuItem value="Full Claim">Full Claim</MenuItem>
              </Select>
            </FormControl>
          }
          {error && (
            <Typography color="error" sx={{ marginTop: 2 }}>
              {error}
            </Typography>
          )}
          <Box sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleConfirm}
            >
              Confirm Upload
            </Button>
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  )
}
