import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import {
  Box,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material"
import AuthContext from "../../AuthContext"
import AdminCustomerPanel from "./AdminCustomerPanel"
import Title from "../Items/Title"
import { Container } from "@mui/system"
import { Link } from "@mui/material"
import AdminFullTable from "../Items/AdminFullTable"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"
const { API_URL } = require("../../config")
import { getClaimTypeColor, getClaimStatusColor } from "../Items/Colors"
import Footer from "../Items/Copyright"
import Snackbar from "@mui/material/Snackbar"

/**
 * Component for the Admin Panel page which displays analytics and a table of claims data.
 * This is a protected route and requires the user to be logged in as an admin.
 * The only component that shows the next step for each claim in the table.
 */
export default function AdminPanel() {
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [claims, setClaims] = useState([])
  const [loading, setLoading] = useState(true)
  const [analytics, setAnalytics] = useState({
    totalClaims: 0,
    submitClaims: 0,
    collectClaims: 0,
    decisionClaims: 0,
    pricingClaims: 0,
    resolvedClaims: 0,
    noQuibbleClaims: 0,
    fullClaimClaims: 0,
  })
  const { token } = useContext(AuthContext)
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState("")
  const location = useLocation()
  const navigate = useNavigate()
  const status = new URLSearchParams(location.search).get("status")
  const type = new URLSearchParams(location.search).get("type")

  useEffect(() => {
    document.title = "BGA Warranty | Admin"
  }, [])
  useEffect(() => {
    if (location.state && location.state.message) {
      setShowSnackbar(true)
      setSnackbarMessage(location.state.message)
    }
  }, [location.state])

  useEffect(() => {
    if (token) {
      axios
        .get(`${API_URL}/claims/steps`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((error) => {
          console.error("Error updating steps:", error)
          setLoading(false)
        })
      axios
        .get(`${API_URL}/claims/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setClaims(response.data)
          updateAnalytics(response.data)
          setLoading(false)
        })
        .catch((error) => {
          console.error("Error fetching claims:", error)
          setLoading(false)
        })
    } else {
      console.error("No token available")
      setLoading(false)
    }
  }, [token])

  const updateAnalytics = (claims) => {
    const totalClaims = claims.length
    const submitClaims = claims.filter(
      (claim) => claim.status === "Submit"
    ).length
    const collectClaims = claims.filter(
      (claim) => claim.status === "Collection"
    ).length
    const decisionClaims = claims.filter(
      (claim) => claim.status === "Decision"
    ).length
    const pricingClaims = claims.filter(
      (claim) => claim.status === "Pricing"
    ).length
    const resolvedClaims = claims.filter(
      (claim) => claim.status === "Resolved"
    ).length
    const noQuibbleClaims = claims.filter(
      (claim) => claim.claim_type === "No Quibble"
    ).length
    const fullClaimClaims = claims.filter(
      (claim) => claim.claim_type === "Full Claim"
    ).length
    setAnalytics({
      totalClaims,
      submitClaims,
      collectClaims,
      decisionClaims,
      pricingClaims,
      resolvedClaims,
      noQuibbleClaims,
      fullClaimClaims,
    })
  }

  const columns = [
    { field: "unique_ref", headerName: "ID", width: 110 },
    { field: "customer_ref", headerName: "Customer Ref", width: 120 },
    { field: "acc_code", headerName: "A/C No.", width: 80 },
    {
      field: "claim_type",
      headerName: "Claim Type",
      width: 120,
      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: getClaimTypeColor(params.row.claim_type),
            padding: "0.25em 0.5em",
            borderRadius: "4px",
          }}
        >
          {params.row.claim_type}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,

      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: getClaimStatusColor(params.row.status),
            padding: "0.25em 0.5em",
            borderRadius: "4px",
          }}
        >
          {params.row.status}
        </span>
      ),
    },
    { field: "admin_comment", headerName: "Admin Comment", width: 150 },
    { field: "next_step", headerName: "Next Step", width: 150 },
    { field: "claim_date", headerName: "Date", width: 110 },
    { field: "art_num", headerName: "Part Number", width: 130 },
    {
      field: "claim_description",
      headerName: "Description",
      width: 300,
      align: "right",
      headerAlign: "right",
    },
  ]

  return (
    <React.Fragment>
      <Snackbar
        name="snackbar"
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          marginTop: "13vh",
          border: "2px solid lightgray",
          borderRadius: "5px",
        }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Container
        maxWidth="lg"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          paddingTop: "3vh",
          paddingBottom: "130px",
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h5"
            sx={{ paddingBottom: "10px", fontWeight: "bold" }}
          >
            Analytics
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ paddingBottom: "20px" }}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 110,
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/admin")}
              >
                <Typography variant="subtitle1">Total Claims</Typography>
                <Typography variant="h3">{analytics.totalClaims}</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={2}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 80,
                  backgroundColor: "red",
                  border: status === "submit" ? "5px solid grey" : "none", // Corrected line
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/admin?type=${type}&status=submit`)}
              >
                <Typography variant="subtitle1">Submit</Typography>
                <Typography variant="h4">{analytics.submitClaims}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 80,
                  backgroundColor: "orange",
                  border: status === "collection" ? "5px solid grey" : "none", // Corrected line
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(`/admin?type=${type}&status=collection`)
                }
              >
                <Typography variant="subtitle1">Collection</Typography>
                <Typography variant="h4">{analytics.collectClaims}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 80,
                  backgroundColor: "gold",
                  border: status === "decision" ? "5px solid grey" : "none", // Corrected line
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/admin?type=${type}&status=decision`)}
              >
                <Typography variant="subtitle1">Decision</Typography>
                <Typography variant="h4">{analytics.decisionClaims}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 80,
                  backgroundColor: "lightblue",
                  border: status === "pricing" ? "5px solid grey" : "none", // Corrected line
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/admin?type=${type}&status=pricing`)}
              >
                <Typography variant="subtitle1">Pricing</Typography>
                <Typography variant="h4">{analytics.pricingClaims}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 80,
                  backgroundColor: "green",
                  border: status === "resolved" ? "5px solid grey" : "none", // Corrected line
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/admin?type=${type}&status=resolved`)}
              >
                <Typography variant="subtitle1">Resolved</Typography>
                <Typography variant="h4">{analytics.resolvedClaims}</Typography>
              </Paper>
            </Grid>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sx={{ paddingBottom: "20px", marginTop: "25px" }}
              >
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 80,
                      width: 150,
                      backgroundColor: "#0288d1",
                      color: "white",
                      border: type === "no_quibble" ? "5px solid grey" : "none", // Corrected line
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(`/admin?type=no_quibble&status=${status}`)
                    }
                  >
                    <Typography variant="subtitle1">No Quibble</Typography>
                    <Typography variant="h4">
                      {analytics.noQuibbleClaims}
                    </Typography>
                  </Paper>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 80,
                      width: 150,
                      backgroundColor: "#d32f2f",
                      border: type === "full_claim" ? "5px solid grey" : "none", // Corrected line
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(`/admin?type=full_claim&status=${status}`)
                    }
                  >
                    <Typography variant="subtitle1">Full Claim</Typography>
                    <Typography variant="h4">
                      {analytics.fullClaimClaims}
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
              <Box>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 40,
                    backgroundColor: "white",
                    color: "orange",
                    border:
                      type !== null || status !== null
                        ? "3px solid orange"
                        : "none", // Corrected line
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/admin`)}
                >
                  <Typography variant="subtitle1">CLEAR</Typography>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            {loading ? (
              <CircularProgress sx={{ alignItems: "center" }} />
            ) : (
              <AdminFullTable
                title="Claims"
                columns={columns}
                rows={claims}
                pageSize={pageSize}
                status={status}
                type={type}
              />
            )}
          </Paper>
        </Box>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <AdminCustomerPanel />
        </Paper>
        <Footer />
      </Container>
    </React.Fragment>
  )
}
