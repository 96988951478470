import React from "react"
import { useNavigate, useLocation, Link } from "react-router-dom"
import { Container, Typography, Box, Button, Paper } from "@mui/material"
import { useEffect } from "react"

const Home = (props) => {
  const { loggedIn, email } = props
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "BGA Warranty | Home"
  }, [])

  const onButtonClick = () => {
    if (loggedIn) {
      localStorage.removeItem("token")
      props.setLoggedIn(false)
    } else {
      navigate("/login")
    }
  }

  // const onButtonClickRegister = () => {
  //   if (loggedIn) {
  //     localStorage.removeItem("token")
  //     props.setLoggedIn(false)
  //   } else {
  //     navigate("/register")
  //   }
  // }

  return (
    <Container>
      <Box
        p={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="white"
        sx={{
          maxWidth: "30rem",
          margin: "auto",
          marginTop: "10rem",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box textAlign="center" mb={3}>
          <Typography variant="h3" gutterBottom>
            BGA <br />
            Warranty Portal
          </Typography>
        </Box>
        <Box textAlign="center" mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onButtonClick}
            style={{ width: "200px", fontSize: "1.5rem" }}
          >
            {loggedIn ? "Log out" : "Log in"}
          </Button>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography
            variant="body2"
            style={{ color: "#636363", textDecoration: "none" }}
          >
            Don't have an account?
          </Typography>
          <Typography variant="h6">
            <Link
              style={{ color: "#636363", textDecoration: "none" }}
              to="/request-an-account"
            >
              Request an account
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default Home
