import React, { useState, useEffect } from "react"
import { Document, Page } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { pdfjs } from "react-pdf"
import "./PDFViewer.css" // Import a custom CSS file for styling
import { useLocation, useNavigate } from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import DownloadIcon from "@mui/icons-material/Download"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { Paper } from "@mui/material"
import Spinner from "../Items/Spinner"

// Correctly set the workerSrc using a URL with the appropriate version
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

/**
 * PDF Viewer component to display PDF files
 * @returns {JSX.Element}
 */
export default function PDF() {
  const location = useLocation()
  const navigate = useNavigate() // Initialize the navigate function
  const fileLink = location.state?.fileLink // Get file link from location state
  const [loading, setLoading] = useState(true) // Add a loading state
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(0.6)
  const [error, setError] = useState(null)

  useEffect(() => {
    // This effect will be triggered when the fileLink changes.
    if (fileLink) {
      setLoading(false) // Set loading to true initially
    }
  }, [fileLink])

  function onDocumentLoadSuccess({ numPages }) {
    setLoading(false) // Set loading to false after the PDF is fully loaded
    setNumPages(numPages)
    setError(null)
  }

  function onDocumentLoadError(err) {
    console.error("Failed to load PDF document", err)
    setLoading(false)
    setError("Failed to load PDF document. Please refresh.")
  }

  const zoomIn = () => setScale(scale + 0.2)
  const zoomOut = () => setScale(scale - 0.2)
  const downloadPDF = () => {
    const link = document.createElement("a")
    link.href = fileLink
    link.download = "Document.pdf"
    link.click()
  }

  return (
    <Paper
      sx={{
        padding: "20px",
        margin: "20px",
        maxWidth: "800px",
        margin: "auto",
        marginTop: "50px",
        marginBottom: "150px",
      }}
    >
      <div className="pdf-viewer">
        <header>
          <div className="header-wrapper" style={{ marginBottom: 0 }}>
            <h2>PDF Viewer</h2>
          </div>
        </header>

        {loading ? (
          <Spinner /> // Show spinner while loading
        ) : (
          <>
            <div className="control-main">
              <div className="back-controls">
                <button className="pdf-btn" onClick={() => navigate(-1)}>
                  <ArrowBackIosIcon />
                  Back
                </button>
              </div>
              <div className="controls">
                <button
                  className="pdf-btn"
                  onClick={zoomOut}
                  disabled={scale <= 0.5}
                >
                  <ZoomOutIcon />
                </button>
                <button
                  className="pdf-btn"
                  onClick={zoomIn}
                  disabled={scale >= 1}
                >
                  <ZoomInIcon />
                </button>
              </div>
              <div className="download-controls">
                <button className="pdf-btn download-btn" onClick={downloadPDF}>
                  <DownloadIcon />
                </button>
              </div>
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="pdf-document">
              <Document
                file={fileLink}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
              >
                <Page pageNumber={pageNumber} scale={scale} />
              </Document>
            </div>
            <br />

            <div className="page-controls">
              <button
                className="pdf-btn"
                disabled={pageNumber <= 1}
                onClick={() => setPageNumber(pageNumber - 1)}
              >
                <ArrowBackIcon />
              </button>
              <button
                className="pdf-btn"
                disabled={pageNumber >= numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                <ArrowForwardIcon />
              </button>
            </div>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </>
        )}
      </div>
    </Paper>
  )
}
