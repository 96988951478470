import React, { useState, useContext } from "react"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
} from "@mui/material"
import { API_URL } from "../../config"
import axios from "axios"
import AuthContext from "../../AuthContext"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

const ChangePassword = () => {
  useEffect(() => {
    document.title = "BGA Warranty | Change Password"
  }, [])
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const { token } = useContext(AuthContext)
  const navigate = useNavigate()

  const Requirement = ({ condition, children }) => {
    return (
      <Box>
        {condition ? (
          <span style={{ color: "black" }}>
            <CheckBoxIcon style={{ color: "#326633" }} /> {children}
          </span>
        ) : (
          <span style={{ color: "grey" }}>
            <CheckBoxOutlineBlankIcon /> {children}
          </span>
        )}
      </Box>
    )
  }

  const requirements = [
    {
      text: "10 or more characters",
      condition_regex: /^.{10,}$/,
    },
    { text: "Lowercase characters", condition_regex: /[a-z]/ },
    { text: "Uppercase characters", condition_regex: /[A-Z]/ },
    { text: "Numbers", condition_regex: /[0-9]/ },
    {
      text: "Symbols",
      condition_regex: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    },
  ]

  const handleChangePassword = async (e) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setError("New passwords do not match")
      return
    }
    try {
      await axios.post(
        `${API_URL}/auth/change_password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setMessage(
        "Your password has been successfully changed. Redirecting to dashboard..."
      )
      setError("")
      setTimeout(() => {
        navigate("/dashboard")
      }, 3000)
    } catch (error) {
      setError("An error occurred. Please try again later.")
      setMessage("")
    }
  }

  return (
    <Container maxWidth="sm" sx={{ padding: "130px" }}>
      <Box mt={5} p={3} boxShadow={3} borderRadius={2} bgcolor="white">
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Change Password
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Please enter your current and new password.
          </Typography>
        </Box>
        <form onSubmit={handleChangePassword}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Current Password"
              type="password"
              variant="outlined"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="New Password"
              type="password"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Confirm New Password"
              type="password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Box>
          <Box mb={2} sx={{}}>
            <div>Make sure your password contains:</div>

            {requirements.map((req) => (
              <Requirement
                condition={newPassword.search(req.condition_regex, "g") != -1}
              >
                {req.text}
              </Requirement>
            ))}
          </Box>

          <Box mt={2}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              disabled={
                newPassword !== confirmPassword ||
                !requirements.every(
                  (req) => newPassword.search(req.condition_regex, "g") != -1
                )
              }
            >
              Set Password
            </Button>
          </Box>
          {message && (
            <Box mt={2}>
              <Alert severity="success">{message}</Alert>
            </Box>
          )}
          {error && (
            <Box mt={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
        </form>
      </Box>
    </Container>
  )
}

export default ChangePassword
