import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
} from "@mui/material"
import { API_URL } from "../../config"
import axios from "axios"

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const navigate = useNavigate()
  const location = useLocation()

  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search)
    return params.get("token")
  }

  useEffect(() => {
    document.title = "BGA Warranty | Reset Password"
  }, [])

  const handleResetPassword = async (e) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match")
      return
    }

    try {
      const token = getTokenFromUrl()
      await axios.post(
        `${API_URL}/auth/reset_password`,
        { new_password: newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setMessage(
        "Your password has been successfully reset. You can now log in with your new password."
      )
      setTimeout(() => {
        navigate("/login")
      }, 3000) // Redirect to login after 3 seconds
    } catch (error) {
      setError("An error occurred. Please try again later.")
    }
  }

  const Requirement = ({ condition, children }) => {
    return (
      <Box>
        {condition ? (
          <span style={{ color: "black" }}>
            <CheckBoxIcon style={{ color: "#326633" }} /> {children}
          </span>
        ) : (
          <span style={{ color: "grey" }}>
            <CheckBoxOutlineBlankIcon /> {children}
          </span>
        )}
      </Box>
    )
  }

  const requirements = [
    {
      text: "10 or more characters",
      condition_regex: /^.{10,}$/,
    },
    { text: "Lowercase characters", condition_regex: /[a-z]/ },
    { text: "Uppercase characters", condition_regex: /[A-Z]/ },
    { text: "Numbers", condition_regex: /[0-9]/ },
    {
      text: "Symbols",
      condition_regex: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    },
  ]

  return (
    <Container maxWidth="sm" sx={{ padding: "130px" }}>
      <Box mt={5} p={3} boxShadow={3} borderRadius={2} bgcolor="white">
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Reset Password
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Please enter your new password.
          </Typography>
        </Box>
        <form onSubmit={handleResetPassword}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="New Password"
              type="password"
              variant="outlined"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value)
                setError("")
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value)
                setError("")
              }}
            />
          </Box>
          <Box mb={2} sx={{}}>
            <div>Make sure your password contains:</div>

            {requirements.map((req) => (
              <Requirement
                condition={newPassword.search(req.condition_regex, "g") != -1}
              >
                {req.text}
              </Requirement>
            ))}
          </Box>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            disabled={
              newPassword !== confirmPassword ||
              !requirements.every(
                (req) => newPassword.search(req.condition_regex, "g") != -1
              )
            }
          >
            Reset Password
          </Button>
          {message && (
            <Box mt={2}>
              <Alert severity="success">{message}</Alert>
            </Box>
          )}
          {error && (
            <Box mt={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
        </form>
      </Box>
    </Container>
  )
}

export default ResetPassword
