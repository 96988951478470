import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, Box, Typography, Container } from "@mui/material"
import { useEffect } from "react"
function DenySuccess() {
  useEffect(() => {
    document.title = "BGA Warranty | Pricing Denied"
  }, [])
  return (
    <Container
      sx={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box textAlign="center">
        <Typography variant="h4" gutterBottom>
          Pricing Denied Successfully!
        </Typography>
        <Typography variant="body1" gutterBottom>
          The claim pricing has now been denied. Thank you.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/dashboard"
          sx={{ marginTop: 5 }}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  )
}

export default DenySuccess
