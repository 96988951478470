import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, Box, Typography, Container } from "@mui/material"

function DraftSuccess() {
  useEffect(() => {
    document.title = "BGA Warranty | Draft Saved"
  }, [])
  return (
    <Container
      sx={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box textAlign="center">
        <Typography variant="h4" gutterBottom>
          Draft Saved Successfully!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your draft has been saved. You may continue with the draft either
          using the dropdown when creating a new claim, or from the side menu.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/dashboard"
          sx={{ marginTop: 5 }}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  )
}

export default DraftSuccess
