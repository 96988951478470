import { useState, useEffect, useRef, useContext } from "react"
import { IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload"
import { Paper, Button } from "@mui/material"
import Spinner from "../Items/Spinner"
import axios from "axios"
import AuthContext from "../../AuthContext"
import { useSearchParams } from "react-router-dom"
import { RenderFDR } from "../Reports/GenerateFDR.js"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { Select } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { TextField } from "@mui/material"
import { fetchClaimData } from "./Report.js"
import reportStyle from "../Reports/ReportStyle.js"
const { API_URL } = require("../../config")

/**
 * Report for FDR
 * @returns {JSX.Element}
 */
export default function FDRReport() {
  const [jsonOutput, setJsonOutput] = useState(null)
  const { token } = useContext(AuthContext)

  const claimNumberRef = useRef(null)
  const srnRef = useRef(null)
  const supplierNumberRef = useRef(null)
  const supplierRef = useRef(null)
  const carReferenceNumberRef = useRef(null)
  const bgaPartNumberRef = useRef(null)
  const batchCodeRef = useRef(null)
  const currencyRef = useRef(null)
  const claimCostRef = useRef(null)
  const dateFittedRef = useRef(null)
  const dateRemovedRef = useRef(null)
  const distanceCoveredRef = useRef(null)
  const distanceUnitsRef = useRef(null)

  const dateRaisedRef = useRef(new Date().toDateString())
  const raisedByRef = useRef(null)

  const explanationRef = useRef(null)
  const imagesRef = useRef([])
  const [claim, setClaim] = useState({})
  const [sections, setSections] = useState([])
  const [loading, setLoading] = useState(true)

  const [searchParams] = useSearchParams()
  const claimNo = searchParams.get("id")

  useEffect(() => {
    async function getData() {
      try {
        const result = await fetchClaimData(claimNo, token)
        setClaim(result)
      } catch (err) {
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [claimNo, token])

  const handleImageUpload = (event) => {
    imagesRef.current = Array.from(event.target.files)
  }

  const changeSupplierTo = async (event) => {
    if (supplierNumberRef.current.value.length < 3) {
      supplierRef.current.value = ""
      return
    }
    const supplierResponse = await axios.get(
      `${API_URL}/products/supplier?q=${supplierNumberRef.current.value}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    const supplierResult = supplierResponse.data[0] || { company: "" }

    supplierNumberRef.current.value =
      supplierNumberRef.current.value.toUpperCase()
    supplierRef.current.value = supplierResult.company
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = {
      claim: claim,
      info: {
        claimNumber: claimNumberRef.current.value,
        srn: srnRef.current.value,
        supplierNumber: supplierNumberRef.current.value,
        supplier: supplierRef.current.value,
        carReferenceNumber: carReferenceNumberRef.current.value,
        bgaPartNumber: bgaPartNumberRef.current.value,
        batchCode: batchCodeRef.current.value,
        claimCost: claimCostRef.current.value,
        currency: currencyRef.current.value,
        dateFitted: dateFittedRef.current.value,
        dateRemoved: dateRemovedRef.current.value,
        distanceCovered: distanceCoveredRef.current.value,
        distanceUnits: distanceUnitsRef.current.value,
        dateRaised: dateRaisedRef.current.value,
        raisedBy: raisedByRef.current.value,
        explanation: explanationRef.current.value,
        images: imagesRef.current,
      },

      sections: sections.map((section) => ({
        title: section.title,
        description: section.description,
        actions: section.actions,
        images: section.images,
      })),
    }

    setJsonOutput(formData)
  }

  const inputStyle = {
    flex: "1 0 100%",
  }

  return (
    <div style={reportStyle.outerStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ ...reportStyle.cardStyle, padding: 3, boxShadow: 3 }}>
            {loading ? (
              <Spinner />
            ) : (
              <div className="main-wrapper">
                <header style={{ marginBottom: -48 }}>
                  <div className="header-wrapper">
                    <h2>FDR #{claim.details.unique_ref}</h2>
                  </div>
                </header>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <TextField
                    label="Claim Number"
                    defaultValue={claim.details.unique_ref}
                    inputRef={claimNumberRef}
                    sx={{
                      ...inputStyle,
                    }}
                  />

                  <TextField
                    label="SRN"
                    defaultValue={claim.collection.srn}
                    inputRef={srnRef}
                    sx={{
                      ...inputStyle,
                    }}
                  />
                  <TextField
                    label="Supplier Number"
                    defaultValue={claim.supplier[0].acc_code}
                    inputRef={supplierNumberRef}
                    onChange={(value) => changeSupplierTo(value)}
                    sx={{
                      ...inputStyle,
                    }}
                  />
                  <TextField
                    label="Supplier"
                    defaultValue={claim.supplier[0].company}
                    inputRef={supplierRef}
                    sx={{
                      ...inputStyle,
                    }}
                  />
                  <TextField
                    label="CAR Reference Number"
                    defaultValue={(() => {
                      const re = /.*\/CAR\/(.*)\.pdf/
                      const carFileName = claim.details.reports.car
                      if (carFileName === null) {
                        return ""
                      }
                      const reportName = carFileName.match(re)
                      return reportName[1]
                    })()}
                    inputRef={carReferenceNumberRef}
                    sx={{
                      ...inputStyle,
                    }}
                  />

                  <TextField
                    label="Product Part Number"
                    defaultValue={
                      claim.adminOverride.new_art_num !== null
                        ? claim.adminOverride.new_art_num
                        : claim.details.art_num
                    }
                    inputRef={bgaPartNumberRef}
                    sx={{
                      ...inputStyle,
                    }}
                  />
                  <TextField
                    label="Batch Code"
                    defaultValue={claim.details.batch_code}
                    inputRef={batchCodeRef}
                    sx={{
                      ...inputStyle,
                    }}
                  />
                  <FormControl
                    sx={{
                      flex: "0 0 8rem",
                    }}
                  >
                    <InputLabel>Currency</InputLabel>
                    <Select
                      label="Currency"
                      defaultValue=""
                      inputRef={currencyRef}
                    >
                      <MenuItem value="£">GBP - £</MenuItem>
                      <MenuItem value="€">EUR - €</MenuItem>
                      <MenuItem value="$">USD - $</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Claim Cost"
                    defaultValue={claim.pricing.parts.reduce(
                      (sum, part) =>
                        part.confirmed ? sum + part.debit_value : sum,
                      0
                    )}
                    sx={{
                      flex: "1 0 auto",
                    }}
                    inputRef={claimCostRef}
                  />
                  <TextField
                    label="Date Fitted"
                    type="date"
                    defaultValue={claim.details.installation_date}
                    inputRef={dateFittedRef}
                    sx={{ ...inputStyle }}
                  />
                  <TextField
                    label="Date Removed"
                    type="date"
                    defaultValue={claim.details.removal_date}
                    inputRef={dateRemovedRef}
                    sx={{ ...inputStyle }}
                  />
                  <TextField
                    label="Distance Covered"
                    defaultValue={
                      claim.details.mileage_failed -
                      claim.details.mileage_fitted
                    }
                    inputRef={distanceCoveredRef}
                    sx={{ flex: "1 0 auto" }}
                  />
                  <TextField
                    label="Distance Units"
                    defaultValue={claim.details.mileage_units}
                    inputRef={distanceUnitsRef}
                    sx={{ flex: "0 0 8rem" }}
                  />
                  <TextField
                    label="Date Raised"
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    inputRef={dateRaisedRef}
                    sx={{ ...inputStyle }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Report raised by"
                    defaultValue=""
                    inputRef={raisedByRef}
                    fullWidth
                  />
                  <TextField
                    label="Explanation"
                    inputRef={explanationRef}
                    multiline
                    rows={6}
                    fullWidth
                  />
                  <Box
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      my: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ marginBottom: 4, margin: "auto", flex: "0 0 auto" }}
                    >
                      <DriveFolderUploadIcon sx={{ marginRight: "3px" }} />
                      <p>Upload Images</p>
                      <input
                        type="file"
                        multiple
                        hidden
                        onChange={(e) => handleImageUpload(e)}
                      />
                    </Button>
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ marginBottom: 4, margin: "auto", flex: "1 0 auto" }}
                  >
                    <p>Render</p>
                  </Button>
                </Box>
              </div>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ ...reportStyle.cardStyle, padding: 3, boxShadow: 3 }}>
            {jsonOutput && (
              <RenderFDR
                formData={jsonOutput}
                style={{ width: "100%", minHeight: "90vh" }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
