import * as React from "react"
import { useState, useEffect, useContext } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Title from "./Title"
import Spinner from "./Spinner"
import { Link } from "react-router-dom"
import axios from "axios"
import AuthContext from "../../AuthContext"
import { styled } from "@mui/system"
const { API_URL } = require("../../config")

const StyledTableContainer = styled("div")({
  width: "100%",
  overflowX: "auto",
  whiteSpace: "nowrap",
})

const StyledTableCell = styled(TableCell)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "200px",
  padding: "0.5em",
})

/**
 * Table to display the most recent claims
 * @param {*} props
 * @returns
 */
export default function RecentTable(props) {
  const [rows, setRows] = useState([])
  const { token } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (token) {
      setLoading(true)
      axios
        .get(`${API_URL}/claims/all?recent=10`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRows(response.data)
        })
        .catch((error) => {
          console.error("Error fetching claims:", error)
        })
      setLoading(false)
    } else {
      console.error("No token available")
    }
  }, [token])

  const getRowStatus = (status) => status.split(" ")[0].toLowerCase()

  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Customer Ref</StyledTableCell>
              <StyledTableCell>Account Code</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Part Number</StyledTableCell>
              <StyledTableCell align="right">Description</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <Spinner />}
            {rows.length > 0 ? (
              rows.map((row) => {
                const rowStatus = getRowStatus(row.status)
                return (
                  <TableRow key={row.unique_ref}>
                    <StyledTableCell>
                      <Link
                        to={`/claim?id=${row.unique_ref}&stage=${rowStatus}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {row.unique_ref}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        to={`/claim?id=${row.unique_ref}&stage=${rowStatus}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {row.customer_ref}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        to={`/claim?id=${row.unique_ref}&stage=${rowStatus}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {row.acc_code}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        to={`/claim?id=${row.unique_ref}&stage=${rowStatus}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <span
                          style={{
                            color: "white",
                            backgroundColor:
                              row.claim_type === "No Quibble"
                                ? "#0288d1"
                                : row.claim_type === "Full Claim"
                                  ? "#d32f2f"
                                  : "inherit",
                            padding: "0.25em 0.5em",
                            borderRadius: "4px",
                          }}
                        >
                          {row.claim_type}
                        </span>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        to={`/claim?id=${row.unique_ref}&stage=${rowStatus}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <span
                          style={{
                            color: "white",
                            backgroundColor:
                              row.status === "Submit"
                                ? "red"
                                : row.status === "Resolved"
                                  ? "green"
                                  : row.status === "Collection"
                                    ? "orange"
                                    : row.status === "Decision"
                                      ? "gold"
                                      : row.status === "Pricing"
                                        ? "lightblue"
                                        : "inherit",
                            padding: "0.25em 0.5em",
                            borderRadius: "4px",
                          }}
                        >
                          {row.status}
                        </span>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        to={`/claim?id=${row.unique_ref}&stage=${rowStatus}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {row.claim_date}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Link
                        to={`/claim?id=${row.unique_ref}&stage=${rowStatus}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {row.art_num}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Link
                        to={`/claim?id=${row.unique_ref}&stage=${rowStatus}`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        {row.claim_description}
                      </Link>
                    </StyledTableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={6} align="center">
                  No claims available
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Link
        to="/claims"
        style={{ color: "#F2AC00", textDecoration: "none", marginTop: 5 }}
      >
        See more claims
      </Link>
    </React.Fragment>
  )
}
