import React, { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import AuthContext from "../../AuthContext" // make sure the path is correct

/**
 * Component that protects the route from unauthorized access, and only allows access to users with the role of "admin"
 * @param {*} param0
 * @returns
 */

const AdminRoute = ({ children }) => {
  const { userRole, token } = useContext(AuthContext)
  if (token && userRole) {
    if (userRole !== "admin") {
      return <Navigate to="/unauthorized" />
    }
  }

  return children ? children : <Outlet />
}

export default AdminRoute
