import * as React from "react"
import { useState, useEffect } from "react"
import Typography from "@mui/material/Typography"
import Title from "./Title"
import axios from "axios"
import { createTheme } from "@mui/material/styles"
import { Link } from "react-router-dom"
import { useContext } from "react"
import AuthContext from "../../AuthContext"
import { API_URL } from "../../config"

/**
 * Overview component to display the most recent claim
 * @returns {JSX.Element}
 */
export default function Overview() {
  const [recentClaim, setRecentClaim] = useState(null)
  const { token, userRole } = useContext(AuthContext)

  useEffect(() => {
    // Fetch the most recent claim from the backend
    axios
      .get(`${API_URL}/claims/all?recent=1`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.length > 0) {
          setRecentClaim(response.data[0])
        }
      })
      .catch((error) => {
        console.error("Error fetching recent claim:", error)
      })
  }, [])

  if (!recentClaim) {
    return (
      <>
        <Title>Last Claim</Title>
      </>
    )
  }

  return (
    <>
      <Title>Last Claim</Title>
      <Typography color="text.secondary">{recentClaim.unique_ref}</Typography>
      <Typography component="p" variant="h4">
        {recentClaim.art_num}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on {new Date(recentClaim.claim_date).toLocaleDateString()}
      </Typography>
      <div>
        <Link
          style={{
            color: "#F2AC00",
            textDecoration: "none",
            fontWeight: 600,
          }}
          to={`/claim?id=${
            recentClaim.unique_ref
          }&stage=${recentClaim.status.toLowerCase()}`}
        >
          View Claim
        </Link>
      </div>
    </>
  )
}
