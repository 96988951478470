import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "./AuthContext";

const PrivateRoute = () => {
  const { token } = useContext(AuthContext);
  // console.log("Token in PrivateRoute:", token); // Debug log
  return token ? <Outlet /> : <Navigate to="/home" />;
};

export default PrivateRoute;
