import InfoTooltip from "../Items/InfoTooltip"
import Typography from "@mui/material/Typography"

/**
 * Renders form fields based on the fields array passed to it
 * @param {*} param0
 * @returns
 */
export default function FieldRenderer({ fields, handler }) {
  return fields.map((field) => {
    if (field.hiddenIf) {
      return
    }
    if (field.type === "line") {
      return (
        <hr
          key={field.fieldName}
          style={{
            border: "1px solid #ccc",
            margin: "5px",
            marginBottom: "20px",
            marginTop: "25px",
            verticalAlign: "top",
          }}
        />
      )
    }

    return (
      <label style={field.style} className="form-label" key={field.fieldName}>
        {field.name + (field.required ? "* " : "")}
        {field.description && (
          <InfoTooltip
            placement="right-end"
            title={field.name}
            content={field.description}
          />
        )}
        {field.type === "textarea" ? (
          <textarea
            className="form-input"
            type={field.type}
            name={field.fieldName}
            value={field.value}
            onChange={handler}
            {...field.inputProps}
          />
        ) : field.type === "select" ? (
          <select
            name={field.fieldName}
            className="form-input"
            onChange={handler}
            defaultValue={field.default}
          >
            {field.options.map((option) => {
              return <option value={option.value}>{option.text}</option>
            })}
          </select>
        ) : (
          <input
            className={"form-input"}
            type={field.type}
            name={field.fieldName}
            value={field.value}
            onChange={handler}
            {...field.inputProps}
          />
        )}
        {field.error && <Typography color="error">{field.error}</Typography>}
      </label>
    )
  })
}
