import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Box } from "@mui/material"
function SessionExpired() {
  const navigate = useNavigate()
  useEffect(() => {
    document.title = "BGA Warranty | Session Expired"
  }, [])
  return (
    <Box
      sx={{
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        mt: 30,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center", marginTop: "0px" }}>
        <h1>Your session has timed out</h1>
        <p>You need to log back in to continue using this service</p>
        <input
          className="inputButton"
          type="button"
          onClick={() => navigate(`/login`)}
          value="Back to Login"
          style={{
            marginTop: "20px",
            padding: "6px 12px",
            fontSize: "18px",
            cursor: "pointer",
          }}
        />
      </div>
    </Box>
  )
}

export default SessionExpired
