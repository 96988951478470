import * as React from "react"
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon"
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"
import AssignmentIcon from "@mui/icons-material/Assignment"
import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect, useContext } from "react"
import {
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material"
import axios from "axios"
import AuthContext from "../../AuthContext"
import { API_URL } from "../../config"

export const mainListItems = (
  <React.Fragment>
    <Link to="/no-quibble" style={{ textDecoration: "none" }}>
      <ListItemButton>
        <ListItemIcon>
          <InsertEmoticonIcon />
        </ListItemIcon>
        <ListItemText
          primary={<Typography color="#636363">No Quibble</Typography>}
        />
      </ListItemButton>
    </Link>
    <Link to="/full-claim" style={{ textDecoration: "none" }}>
      <ListItemButton>
        <ListItemIcon>
          <TimeToLeaveIcon />
        </ListItemIcon>
        <ListItemText
          primary={<Typography color="#636363">Full Claim</Typography>}
        />
      </ListItemButton>
    </Link>
    <Link to="/resolved" style={{ textDecoration: "none" }}>
      <ListItemButton>
        <ListItemIcon>
          <VerifiedUserIcon />
        </ListItemIcon>
        <ListItemText
          primary={<Typography color="#636363">Resolved</Typography>}
        />
      </ListItemButton>
    </Link>
  </React.Fragment>
)

const SecondaryListItems = ({ drafts = [], onDraftSaved }) => {
  const navigate = useNavigate()

  // Group drafts by claim_type
  const groupedDrafts = drafts.reduce((acc, draft) => {
    if (!acc[draft.claim_type]) {
      acc[draft.claim_type] = []
    }
    acc[draft.claim_type].push(draft)
    return acc
  }, {})

  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Saved drafts
      </ListSubheader>
      {Object.keys(groupedDrafts).map((claimType) =>
        groupedDrafts[claimType]
          .slice()
          .reverse()
          .map((draft, index) => (
            <ListItemButton
              key={draft.id}
              onClick={() =>
                navigate(
                  `/new-claim/${draft.claim_type.toLowerCase().replace(" ", "-")}?draftId=${draft.id}&draftIndex=${groupedDrafts[claimType].length - index}`
                )
              }
            >
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="#636363">{`${draft.claim_type} - Draft ${groupedDrafts[claimType].length - index}`}</Typography>
                }
              />
            </ListItemButton>
          ))
      )}
    </React.Fragment>
  )
}

export { SecondaryListItems }
