import { useState, useEffect, useContext } from "react"
import { DataGrid } from "@mui/x-data-grid"
import axios from "axios"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import AuthContext from "../../AuthContext"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { API_URL } from "../../config"
export default function AdminCustomerPanel({
  className,
  title,
  content,
  ...props
}) {
  const [pageSize, setPageSize] = useState(10)
  const { token } = useContext(AuthContext)
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (token) {
      let url = `${API_URL}/customer/requests`
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          response.data.forEach((item, i) => {
            item.id = i + 1
          })
          setRows(response.data)

          // Get unique "Next Steps" values
          // const uniqueNextSteps = Array.from(
          //   new Set(response.data.map((row) => row.next_step))
          // )
          // setNextStepsOptions(uniqueNextSteps)
        })
        .catch((error) => {
          console.error("Error fetching account requests:", error)
        })
    } else {
      console.error("No token available")
    }
  }, [token])

  return (
    <div {...props}>
      <Box
        sx={{
          marginBottom: 2,
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <p>Account Requests</p>
      </Box>
      <Box sx={{ marginBottom: 2, display: "flex", gap: 2 }}>
        <Select value={""} onChange={() => {}} displayEmpty>
          <MenuItem value="">
            <em>Show completed</em>
          </MenuItem>
        </Select>
      </Box>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          style={{ cursor: "pointer" }}
          rows={rows}
          columns={[
            {
              field: "acc_code",
              headerName: "A/C No.",
              width: 110,
              editable: true,

              disableClickEventBubbling: true,
            },
            {
              field: "email",
              headerName: "Email",
              width: 300,
              editable: true,

              disableClickEventBubbling: true,
            },
            {
              field: "first_name",
              headerName: "Name",
              width: 120,
              editable: true,

              disableClickEventBubbling: true,
            },
            {
              field: "company",
              headerName: "Company",
              width: 240,
              editable: true,

              disableClickEventBubbling: true,
            },
            {
              field: "contact_number",
              headerName: "Contact Number",
              width: 140,
              editable: true,

              disableClickEventBubbling: true,
            },
            {
              field: "completed",
              headerName: "Action",
              width: 200,
              renderCell: (params) => {
                const onClick = (e) => {
                  e.stopPropagation() // don't select this row after clicking

                  const api = params.api

                  const fields = api
                    .getAllColumns()
                    .map((c) => c.field)
                    .filter((c) => c !== "__check__" && !!c)
                  const thisRow = {}

                  fields.forEach((f) => {
                    thisRow[f] = params.row[f]
                  })

                  return alert(JSON.stringify(thisRow, null, 4))
                }

                return (
                  <>
                    <Button
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="primary"
                      onClick={onClick}
                    >
                      ✓
                    </Button>

                    <Button
                      sx={{ mx: 1 }}
                      variant="contained"
                      color="error"
                      onClick={onClick}
                    >
                      ✗
                    </Button>
                  </>
                )
              },
            },
          ]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 25]}
          pagination
          checkboxSelection
          disableRowSelectionOnClick
          checkboxSelection={false}
        />
      </div>
    </div>
  )
}
