import { StyleSheet } from "@react-pdf/renderer"

const reportStyle = StyleSheet.create({
  //used in report form
  outerStyle: {
    display: "flex",
    width: "100%",
    maxWidth: "100rem",
    margin: "auto",
    flexWrap: "wrap",
    padding: "3rem",
    gap: "2rem",
    marginBottom: "40rem",
  },
  cardStyle: {
    flex: "1 0 40rem",
    height: "fit-content",
  },

  //used in pdf document
  inputStyle: {
    display: "flex",
    flexDirection: "column",
    "& input": {
      width: "100%",
    },
  },
  page: {
    backgroundColor: "#f5f5f5",
    color: "#333",
    padding: 30,
    fontFamily: "Helvetica",
  },
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30, // Increased spacing
  },
  logo: {
    width: 100,
    height: 100,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    color: "#333",
    textTransform: "uppercase",
    marginBottom: 20,
  },
  sectionHeader: {
    fontSize: 18,
    marginBottom: 15, // Increased spacing
    color: "#333",
    borderBottom: "2px solid #333",
    paddingBottom: 5,
    marginTop: 20, // Added top margin to separate sections
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 8, // Increased spacing
  },
  label: {
    flex: 1,
    fontSize: 12,
    fontWeight: "bold",
    color: "#333",
  },
  value: {
    flex: 2,
    fontSize: 12,
    color: "#666",
  },
  imageSectionHeader: {
    fontSize: 16,
    marginTop: 20, // Added spacing before the image section
    marginBottom: 10,
    color: "#333",
    textDecoration: "underline", // Added underline to distinguish the section
  },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10, // Added top margin for spacing
    gap: 12,
  },
  image: {
    flex: "0 0 45%",
    width: "auto",
    height: "auto",
    maxWidth: "45%",
  },
  imageCaption: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 5, // Added margin for better spacing
    marginX: "auto",
  },
})

export default reportStyle
