import React, { useState, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Typography,
} from "@mui/material"
import AuthContext from "../../AuthContext"
import Spinner from "../Items/Spinner"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"
import { set } from "date-fns"

const { API_URL } = require("../../config")

const PricingConfirm = () => {
  useEffect(() => {
    document.title = "BGA Warranty | Pricing Confirmation"
  }, [])
  const [searchParams] = useSearchParams()
  const claimNo = searchParams.get("id")
  const token = searchParams.get("token")
  const navigate = useNavigate()
  const [claimPricing, setClaimPricing] = useState({})
  const [claimDetails, setClaimDetails] = useState({})
  const [customerDetails, setCustomerDetails] = useState({})

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/claims/${claimNo}/pricing`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        setClaimPricing(response.data)

        const claimDetailsResponse = await axios.get(
          `${API_URL}/claims/${claimNo}/submit`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const customerDetailsResponse = await axios.get(
          `${API_URL}/customer/${claimDetailsResponse.data.customer_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        setCustomerDetails(customerDetailsResponse.data)

        setClaimDetails(claimDetailsResponse.data)
      } catch (error) {
        setError("Error fetching pricing data")
      }
      setLoading(false)
    }

    fetchPricingData()
  }, [claimNo, token])

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <div>{error}</div>
  }
  const labourTimePart = claimPricing.parts?.find(
    (part) => part.part === "Labour time"
  )
  const labourRatePart = claimPricing.parts?.find(
    (part) => part.part === "Labour rate"
  )
  const filteredParts = claimPricing.parts?.filter(
    (part) => part.part !== "Labour time" && part.part !== "Labour rate"
  )

  // Calculate the totals for claim value and confirmed value ignoring labour time and rate
  const totalOriginalValue =
    filteredParts?.reduce((sum, part) => sum + part.original_value, 0) || 0
  const totalCreditValue =
    filteredParts?.reduce(
      (sum, part) => sum + parseFloat(part.credit_value || 0),
      0
    ) || 0
  const totalDebitValue =
    filteredParts?.reduce(
      (sum, part) => sum + parseFloat(part.debit_value || 0),
      0
    ) || 0

  const handleApprove = () => {
    axios
      .post(
        `${API_URL}/claims/${claimNo}/director_confirm`,
        { accept: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        navigate("/approve-success")
      })
      .catch((error) => {
        setError("Error updating pricing")
      })
  }

  const handleDeny = () => {
    axios
      .post(
        `${API_URL}/claims/${claimNo}/director_confirm`,
        { accept: false },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        navigate("/deny-success")
      })
      .catch((error) => {
        setError("Error updating pricing")
      })
  }

  return (
    <Grid container justifyContent="center" sx={{ mt: 4, mb: 25 }}>
      <Grid item xs={12} md={10} lg={8}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            minHeight: "700px",
            minWidth: {
              xs: "100%", // Mobile
              sm: "100%", // Tablet
              lg: "800px", // Desktop
            },
          }}
        >
          <Box className="main-wrapper">
            <header>
              <div className="header-wrapper" style={{ margin: 0 }}>
                <h2>Claim Details #{claimDetails.unique_ref}</h2>
              </div>
            </header>
            <header></header>
            <div className="claim-details">
              <div className="text-wrapper">
                <p className="detail-field">
                  <strong>Claim Type: </strong>
                  {claimDetails.claim_type}
                </p>
                <p className="detail-field">
                  <strong>Claim Number: </strong>
                  {claimDetails.id}
                </p>
                <p className="detail-field">
                  <strong>Date: </strong>
                  {claimDetails.claim_date}
                </p>
                <p className="detail-field">
                  <strong>BGA Ref: </strong>
                  {claimDetails.art_num}
                </p>
                <br></br>

                {claimDetails.claim_type === "Full Claim" && (
                  <>
                    <p
                      className="detail-field"
                      style={{ marginBottom: "10px" }}
                    >
                      <strong>Labour Description: </strong>
                      {claimDetails.labour_description}
                    </p>
                    <br></br>
                    <p
                      className="detail-field"
                      style={{ marginBottom: "10px" }}
                    >
                      <strong>Expense Description: </strong>
                      {claimDetails.expense_description}
                    </p>
                  </>
                )}
              </div>
              <br />
              <div className="text-wrapper">
                <p className="detail-field">
                  <strong>Customer Name: </strong>
                  {customerDetails.first_name} {customerDetails.last_name}
                </p>
                <p className="detail-field">
                  <strong>Customer Email: </strong>
                  {customerDetails.email}
                </p>
              </div>
              <br />

              <div className="text-wrapper" style={{ minHeight: "150px" }}>
                <p className="detail-field">
                  <strong>Claim Description: </strong>
                  {claimDetails.description}
                </p>
              </div>
              <br />
            </div>
            <Box className="claim-details">
              <TableContainer>
                <Table
                  sx={{
                    minWidth: 650,
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                >
                  <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: "bold", border: "1px solid #e0e0e0" }}
                      >
                        Part
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", border: "1px solid #e0e0e0" }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", border: "1px solid #e0e0e0" }}
                      >
                        Original Value
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", border: "1px solid #e0e0e0" }}
                      >
                        Credited value
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: "bold", border: "1px solid #e0e0e0" }}
                      >
                        Debited value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {claimPricing.parts?.map((part, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                          {part.part}
                        </TableCell>
                        <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                          {part.description}
                        </TableCell>
                        <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                          {part.original_value}
                        </TableCell>
                        <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                          {part.credit_value}
                        </TableCell>
                        <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                          {part.debit_value}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        sx={{ fontWeight: "bold", border: "1px solid #e0e0e0" }}
                      >
                        Total
                      </TableCell>
                      <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                        {totalOriginalValue.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                        {totalCreditValue.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid #e0e0e0" }}>
                        {totalDebitValue.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box>
                {labourTimePart && (
                  <Box sx={{ mt: 2 }}>
                    <p className="detail-field">
                      <strong>Labour Time (Hours): </strong>
                      {labourTimePart.credit_value}
                    </p>
                  </Box>
                )}
                {labourRatePart && (
                  <Box sx={{ mt: 2, mb: 5 }}>
                    <p className="detail-field">
                      <strong>Labour Rate: </strong>
                      {labourRatePart.credit_value}
                    </p>
                  </Box>
                )}
              </Box>
              <Box sx={{ mb: 5 }}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Comments
                </Typography>
                <Typography>{claimPricing.comments}</Typography>
              </Box>
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApprove}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDeny}
                >
                  Deny
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PricingConfirm
