import { useState } from "react"
import "./InfoToolTip.css" // Importing CSS for styling
import FullscreenIcon from "@mui/icons-material/Fullscreen"
export default function InfoToolTip({ urls, ...props }) {
  const [index, setIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const length = urls.length

  const mod = (n, m) => ((n % m) + m) % m
  const nextImage = () => {
    setIndex(mod(index - 1, length))
  }
  const prevImage = () => {
    setIndex(mod(index + 1, length))
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <div className="image-viewer-container">
      <div className="image-wrapper">
        <img
          src={urls[index]}
          alt={`Image ${index + 1}`}
          className="image-viewer-img"
          onClick={toggleModal} // Open modal on image click
        />
      </div>
      <div className="image-viewer-controls">
        <button className="viewer-button" onClick={nextImage}>
          &#8249; {/* Left arrow */}
        </button>
        <span className="image-count">
          {index + 1} / {length}
        </span>
        <button className="viewer-button" onClick={prevImage}>
          &#8250; {/* Right arrow */}
        </button>
      </div>
      <button className="fullscreen-button" onClick={toggleModal}>
        <FullscreenIcon />
      </button>

      {/* Modal Popup */}
      {isModalOpen && (
        <div className="modal" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={toggleModal}>
              &times;
            </span>
            <img
              src={urls[index]}
              alt={`Image ${index + 1}`}
              className="modal-image"
            />
          </div>
        </div>
      )}
    </div>
  )
}
