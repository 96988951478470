import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
} from "@mui/material"
import { API_URL } from "../../config"
import axios from "axios"

const InitPassword = () => {
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const [accCode, setAccCode] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [valid, setValid] = useState("")
  const [loading, setLoading] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    document.title = "BGA Warranty | Set Password"
  }, [])

  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search)
    return params.get("token")
  }

  const getAccCodeFromUrl = () => {
    const params = new URLSearchParams(location.search)
    return params.get("acc_code")
  }

  const handleInitPassword = async (e) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match")
      return
    }

    try {
      const token = getTokenFromUrl()
      await axios.post(`${API_URL}/auth/set_password`, {
        name: name,
        acc_code: accCode,
        new_password: newPassword,
        token: token,
      })
      setMessage(
        "Your password has been set successfully. You can now log in with your new password."
      )
      setTimeout(() => {
        navigate("/login")
      }, 3000) // Redirect to login after 3 seconds
    } catch (error) {
      setError("An error occurred. Please try again later.")
    }
  }
  useEffect(() => {
    const fetchTokenData = async () => {
      setLoading(true)
      try {
        const token = getTokenFromUrl()
        axios.get(`${API_URL}/auth/read_init/${token}`).then((res) => {
          setName(res.data.name)
          setAccCode(res.data.acc_code)
          setEmail(res.data.email)
          setValid(res.data.token_valid)
        })
      } catch (error) {
        setError("Error fetching data")
      }
      setLoading(false)
    }

    fetchTokenData()
  }, [])

  const Requirement = ({ condition, children }) => {
    return (
      <Box>
        {condition ? (
          <span style={{ color: "black" }}>
            <CheckBoxIcon style={{ color: "#326633" }} /> {children}
          </span>
        ) : (
          <span style={{ color: "grey" }}>
            <CheckBoxOutlineBlankIcon /> {children}
          </span>
        )}
      </Box>
    )
  }

  const requirements = [
    {
      text: "10 or more characters",
      condition_regex: /^.{10,}$/,
    },
    { text: "Lowercase characters", condition_regex: /[a-z]/ },
    { text: "Uppercase characters", condition_regex: /[A-Z]/ },
    { text: "Numbers", condition_regex: /[0-9]/ },
    {
      text: "Symbols",
      condition_regex: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    },
  ]

  return (
    <Container maxWidth="sm" sx={{ padding: "130px" }}>
      {valid ? (
        <Box p={3} boxShadow={3} borderRadius={2} bgcolor="white">
          <Box textAlign="center" mb={2}>
            <Typography variant="h4" gutterBottom>
              Set your password
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Please enter your new password.
            </Typography>
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Account Code"
              type="text"
              variant="outlined"
              value={accCode}
              disabled
            />
          </Box>
          <form onSubmit={handleInitPassword}>
            <Box mb={2}>
              <TextField
                fullWidth
                label="Email"
                type="text"
                variant="outlined"
                disabled
                value={email}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                label="Name"
                type="text"
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                  setError("")
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                label="New Password"
                type="password"
                variant="outlined"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                  setError("")
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                  setError("")
                }}
              />
            </Box>
            <Box mb={2} sx={{}}>
              <div>Make sure your password contains:</div>

              {requirements.map((req) => (
                <Requirement
                  condition={newPassword.search(req.condition_regex, "g") != -1}
                >
                  {req.text}
                </Requirement>
              ))}
            </Box>

            <Box mt={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="secondary"
                disabled={
                  newPassword !== confirmPassword ||
                  !requirements.every(
                    (req) => newPassword.search(req.condition_regex, "g") != -1
                  )
                }
              >
                Set Password
              </Button>
            </Box>
            {message && (
              <Box mt={2}>
                <Alert severity="success">{message}</Alert>
              </Box>
            )}
            {error && (
              <Box mt={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
          </form>
        </Box>
      ) : (
        <Box mt={5} p={3} boxShadow={3} borderRadius={2} bgcolor="white">
          <Box textAlign="center" mb={2}>
            <Typography variant="h4" gutterBottom>
              This link is no longer valid
            </Typography>
            <Typography variant="body2" color="textSecondary">
              If you want to reset your password, contact an administrator at{" "}
              <a href="mailto:itsupport@bgautomotive.co.uk">
                itsupport@bgautomotive.co.uk
              </a>
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  )
}

export default InitPassword
