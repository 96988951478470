const colors = {
  NO_QUIBBLE: "#0288d1",
  FULL_CLAIM: "#d32f2f",
  SUBMIT: "red",
  COLLECTION: "orange",
  DECISION: "gold",
  PRICING: "lightblue",
  RESOLVED: "green",
}

/**
 * Color for claim type
 * @param {*} claimType
 * @returns
 */
export const getClaimTypeColor = (claimType) => {
  switch (claimType) {
    case "No Quibble":
      return colors.NO_QUIBBLE
    case "Full Claim":
      return colors.FULL_CLAIM
    default:
      return "inherit"
  }
}

/**
 * Color for claim status
 * @param {*} claimStatus
 * @returns
 */
export const getClaimStatusColor = (claimStatus) => {
  switch (claimStatus) {
    case "Submit":
      return colors.SUBMIT
    case "Collection":
      return colors.COLLECTION
    case "Decision":
      return colors.DECISION
    case "Pricing":
      return colors.PRICING
    case "Resolved":
      return colors.RESOLVED
    default:
      return "inherit"
  }
}
