import React, { useState, useEffect, useContext } from "react"
import { Toolbar, IconButton, Divider, List } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { styled } from "@mui/material/styles"
import axios from "axios"
import AuthContext from "../../AuthContext"
import { API_URL } from "../../config"
import { mainListItems, SecondaryListItems } from "./listItems" // Updated import
import MuiDrawer from "@mui/material/Drawer"

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

function ResponsiveAppBar() {
  const [open, setOpen] = useState(false)
  const [recentDrafts, setRecentDrafts] = useState([])
  const { token } = useContext(AuthContext)

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const fetchDrafts = () => {
    if (token) {
      axios
        .get(`${API_URL}/drafts/all?recent=10`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRecentDrafts(response.data)
        })
        .catch((error) => {
          console.error("Error fetching drafts:", error)
        })
    } else {
      console.error("No token available")
    }
  }

  useEffect(() => {
    fetchDrafts()
  }, [token])

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {mainListItems}
        <Divider sx={{ my: 1 }} />
        <SecondaryListItems drafts={recentDrafts} onDraftSaved={fetchDrafts} />
      </List>
    </Drawer>
  )
}

export default ResponsiveAppBar
