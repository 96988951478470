import React, { createContext, useState, useEffect } from "react"
import { API_URL } from "./config"
import { useNavigate, useLocation } from "react-router-dom"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"))
  const [userRole, setUserRole] = useState(null)
  const [loading, setLoading] = useState(true)
  const [accCode, setAccCode] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [email, setEmail] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  // const navigate = useNavigate()

  useEffect(() => {
    const savedToken = localStorage.getItem("token")
    if (savedToken) {
      setToken(savedToken)
      setIsAuthenticated(true)
      fetchUserInfo(savedToken)
    } else {
      setLoading(false)
      // navigate("/home")
      console.log("Session has expired")
    }
  }, [])

  const fetchUserInfo = async (token) => {
    try {
      const response = await fetch(`${API_URL}/auth/whoami`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })

      if (response.status === 200) {
        const data = await response.json()
        setUserRole(data.is_admin ? "admin" : "user")
        setAccCode(data.acc_code)
        setFirstName(data.first_name)
        setEmail(data.email)
      } else {
        console.log("Failed to fetch user role")
      }
    } catch (error) {
      console.error("Error fetching user role:", error)
    } finally {
      setLoading(false)
    }
  }

  const login = async (acc_code, password) => {
    const response = await fetch(`${API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ acc_code, password }),
    })

    if (response.status === 200) {
      const data = await response.json()
      if (data.token) {
        setToken(data.token)
        setIsAuthenticated(true)
        localStorage.setItem("token", data.token)
        fetchUserInfo(data.token)
        return true
      } else {
        console.log("Failed to retrieve token. Please try again.")
      }
    } else {
      console.log("Login failed. Please check your credentials and try again.")
    }
    return false
  }

  const logout = () => {
    setToken(null)
    setUserRole(null)
    setIsAuthenticated(false)
    setAccCode(null)
    setFirstName(null)
    setEmail(null)
    localStorage.removeItem("token")
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        isAuthenticated,
        userRole,
        login,
        logout,
        loading,
        accCode,
        firstName,
        email,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
