import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Button, Box, Typography, Container } from "@mui/material"
import { useEffect } from "react"
function Success() {
  useEffect(() => {
    document.title = "BGA Warranty | Success"
  }, [])
  return (
    <Container
      sx={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box textAlign="center">
        <Typography variant="h4" gutterBottom>
          Claim Submitted Successfully!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your claim has been submitted. We will get back to you soon.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/dashboard"
          sx={{ marginTop: 5 }}
        >
          Back to Home
        </Button>
      </Box>
    </Container>
  )
}

export default Success
