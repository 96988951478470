import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"

import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
} from "@mui/material"
import { API_URL } from "../../config"
import InfoTooltip from "../Items/InfoTooltip"
import ReCAPTCHA from "react-google-recaptcha"
import exampleAccCode from "../../assets/example_acc_code2.png"

const Register = () => {
  const navigate = useNavigate()
  const [accCode, setAccCode] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [number, setNumber] = useState("")
  const [message, setMessage] = useState("")

  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    document.title = "BGA Warranty | Register"
  }, [])

  const handleRegister = (e) => {
    e.preventDefault()

    axios
      .post(`${API_URL}/auth/register`, {
        accCode,
        email,
        name,
        company,
        number,
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage(
            "Thank you for making a request, a member of our team will be in contact with you shortly"
          )
          setButtonDisabled(true)
          setTimeout(() => {
            navigate("/login")
          }, 5000) // Redirect to login after 3 seconds
        } else {
          setMessage("Registration failed, please try again.")
        }
      })
      .catch((error) => {
        setMessage("Error creating user")
      })
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 4,
          mb: 100,
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Request an account
        </Typography>

        <form onSubmit={handleRegister}>
          <p>
            <strong>
              1. Identify your account code in your invoices from BGA. This will
              be under 'A/C No.'
            </strong>
          </p>
          <Typography variant="body1" component="h2"></Typography>
          <img src={exampleAccCode} alt="" height="100%" width="100%" />
          <Typography variant="body1" component="h2">
            In the image above, the account code is AAA001
          </Typography>
          <br />
          <p>
            <strong>
              2. Fill out the form below to send a request to our team.
            </strong>
          </p>
          <Typography variant="body1" component="h2">
            Please note that if the account code or the email provided is
            incorrect, it may be difficult to correctly identify and create your
            account. You may be asked to repeat this process.
          </Typography>
          <TextField
            label="Account Code"
            variant="outlined"
            placeholder="e.g. AAA001"
            fullWidth
            value={accCode}
            onChange={(e) => setAccCode(e.target.value)}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="Company"
            variant="outlined"
            fullWidth
            margin="normal"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            required
          />
          <TextField
            label="Contact Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            required
          />
          {
            // <ReCAPTCHA sitekey="6Lc6kVAqAAAAABsYIpdtt9tL0h2aKW-h20W60_7y" />
            // <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} />
          }
          <Typography variant="body1" component="h2">
            Once you have made a request, you will need to wait for up to{" "}
            <strong>3 working days</strong> for our team to process your
            request. For any further queries, please contact support at{" "}
            <a href="mailto:warranty@bgautomotive.co.uk">
              warranty@bgautomotive.co.uk
            </a>
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={buttonDisabled}
            sx={{ mt: 2 }}
          >
            Send Request
          </Button>
        </form>
        {message && (
          <Alert severity={"success"} sx={{ mt: 2 }}>
            Thank you for submitting with your details. Our team will contact
            you with details on registering your account
          </Alert>
        )}
      </Box>
    </Container>
  )
}

export default Register
