import * as React from "react"
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Tooltip,
  Typography,
  Badge,
  Container,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  Paper,
  Popover,
} from "@mui/material"
import NotificationsIcon from "@mui/icons-material/Notifications"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { useNavigate, useLocation } from "react-router-dom"
import queryString from "query-string"
import { styled } from "@mui/material/styles"

const Pointer = styled(Paper)(({ theme }) => ({
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderBottom: `8px solid ${theme.palette.background.paper}`,
    top: -8,
    left: "calc(50% - 8px)",
  },
}))

function ResponsiveToolBar() {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1)

  const getTitle = (pathname, search) => {
    if (pathname.startsWith("/claim")) {
      const queryParams = queryString.parse(search)
      if (queryParams.id) {
        return `Claim #${queryParams.id}`
      }
    }
    if (pathname.startsWith("/new-claim")) {
      return "New Claim"
    }

    const titles = {
      "/dashboard": "Dashboard",
      "/profile": "Profile",
      "/settings": "Settings",
      "/claims": "Claims",
      "/support": "Support",
      "/admin": "Admin",
      "/no-quibble": "No Quibble",
      "/full-claim": "Full Claim",
      "/resolved": "Resolved",
      "/login": "Login",
      "/register": "Register",
      "/home": "Home",
      "/success": "Success",
      "/draft/success": "Draft Success",
      "/": "Welcome",
    }

    if (titles[pathname]) {
      return titles[pathname]
    }

    // Replace hyphens with spaces and capitalize each word
    return pathname
      .split("/")
      .filter(Boolean)
      .map((part) => part.split("-").map(capitalize).join(" "))
      .join(" ")
  }

  const showBackButton = ![
    "/home",
    "/dashboard",
    "/login",
    "/register",
    "/",
  ].includes(location.pathname)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [notifications, setNotifications] = React.useState([])

  const handleNotificationsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationsClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position="relative" sx={{ zIndex: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              color: "white",
              position: "relative",
            }}
          >
            {showBackButton && (
              <IconButton
                color="inherit"
                onClick={() => navigate(-1)}
                sx={{ position: "absolute", left: isMobile ? "0.5em" : "1em" }}
              >
                <ArrowBackIosIcon /> <p style={{ fontSize: "0.7em" }}>Back</p>
              </IconButton>
            )}

            <Typography
              variant={isMobile ? "h6" : "h5"}
              noWrap
              component="div"
              sx={{
                margin: "auto",
                fontWeight: 700,
                color: "white",
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              {getTitle(location.pathname, location.search)}
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default ResponsiveToolBar
