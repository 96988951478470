import React, { useState, useEffect } from "react"
import { Document, Page } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { pdfjs } from "react-pdf"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link,
  IconButton,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import DownloadIcon from "@mui/icons-material/Download"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"

// Correctly set the workerSrc using a URL with the appropriate version
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

export default function Policy() {
  useEffect(() => {
    document.title = "BGA Warranty | Policy"
  }, [])
  const location = useLocation()
  const navigate = useNavigate() // Initialize the navigate function
  const fileLink = location.state?.fileLink
  const [loading, setLoading] = useState(true) // Add a loading state
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(0.6)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (fileLink) {
      setLoading(false)
    }
  }, [fileLink])

  function onDocumentLoadSuccess({ numPages }) {
    setLoading(false)
    setNumPages(numPages)
    setError(null)
  }

  function onDocumentLoadError(err) {
    setLoading(false)
    setError("Failed to load PDF document. Please refresh.")
    window.location.reload()
  }

  const zoomIn = () => setScale(scale + 0.2)
  const zoomOut = () => setScale(scale - 0.2)
  const downloadPDF = () => {
    const link = document.createElement("a")
    link.href = fileLink
    link.download = "Document.pdf"
    link.click()
  }

  return (
    <Paper
      sx={{
        padding: 4,
        margin: 4,
        maxWidth: 800,
        mx: "auto",
        mt: 6,
        mb: 18,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Parts Warranty Policy
      </Typography>
      <Typography variant="body1" paragraph>
        BG Automotive Limited (BGA) will replace or repair any genuine BGA part
        (the PART) free of charge if it requires repair or replacement as a
        result of defective material or workmanship in manufacture within 3
        years of the date of fitment/purchase, provided that:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="1. Immediately upon discovery of a potential defect with the PART, the purchaser returns the PART to the
            seller or authorised BGA Distributor together with a receipt showing the date and place of
            fitment/purchase. Particulars of each claim as required on the relevant BGA On-Line Claim Form must
            also be provided in order to fully investigate the matter in question."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="2. The PART has not been abused or damaged in any way by neglect or accident/improper storage, fitting,
            use or removal."
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. The PART has not been altered, modified, or adapted in any way except with BGA’s written consent." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. The PART has only been fitted to a vehicle application as specified in the current BGA catalogue." />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="5. The PART, and any vehicle to which the PART has been fitted, has not been used for competition,
            racing, or record attempts."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="6. The PART has been fitted in accordance with the PART and vehicle manufacturer’s published
            instructions."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="7. The PART, and any vehicle to which it has been fitted, has been maintained in accordance with the
            PART and vehicle manufacturer’s published recommendations. (Written evidence may be required.)"
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="8. The replacement or repair is not necessary as a result of fair wear and tear." />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        BGA will consider claims for additional parts that have to be replaced
        either because the defective PART has caused damage, or that they must
        be replaced in the proper repair of the defective PART. Where BGA parts
        are available, these must be fitted as part of the repair. Any
        additional part being claimed must be returned for assessment with the
        defective PART (except fluids and sealants).
      </Typography>
      <Typography variant="body1" paragraph>
        BGA will also consider paying claims from qualified motor repair agents
        for the labour necessary to repair the defective PART. Any labour
        payment made will not exceed the published Autodata or equivalent labour
        time at the BGA published labour rate.
      </Typography>
      <Typography variant="body1" paragraph>
        As an alternative to the above, within the same time period, BGA offer a
        free replacement or refund for the BGA PART originally purchased and
        fitted to the published vehicle application. No additional repair costs
        will be paid under this option, and this also covers non-material or
        manufacturing defect.
      </Typography>
      <Typography variant="body1" paragraph>
        If any PART is replaced or repaired under this warranty, then the
        replaced or repaired PART will have the benefit of this warranty for the
        balance of the original 3 years.
      </Typography>
      <Typography variant="body1" paragraph>
        This warranty specifically excludes liability for towing or vehicle
        recovery, re-delivery charges, car hire, loss of earnings, Original
        Equipment parts if BGA can supply, or excessive vehicle mileages.
      </Typography>
      <Typography variant="body1" paragraph>
        This warranty is in addition to, and does not detract from, a consumer’s
        statutory rights.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>BGA Aftersales Department</strong>
        <br />
        Email:{" "}
        <Link href="mailto:warranty@bgautomotive.co.uk">
          warranty@bgautomotive.co.uk
        </Link>
        <br />
        Tel: +44 (0) 1793 491777 (Option 5)
        <br />
        Warranty Claim Portal:{" "}
        <Link href="https://warranty.bgautomotive.co.uk/">
          warranty.bgautomotive.co.uk
        </Link>
      </Typography>
    </Paper>
  )
}
