import { useState, useEffect, useRef, useContext } from "react"
import { IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload"
import { Paper, Button } from "@mui/material"
import Spinner from "../Items/Spinner"
import axios from "axios"
import AuthContext from "../../AuthContext"
import { useSearchParams } from "react-router-dom"
import { RenderInspection } from "../Reports/GenerateInspection.js"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { Select } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import { TextField } from "@mui/material"
import { fetchClaimData } from "./Report.js"
import reportStyle from "../Reports/ReportStyle.js"
const { API_URL } = require("../../config")

/**
 * Report for Inspection
 * @returns {JSX.Element}
 * @constructor
 */
export default function InspectionReport() {
  const [claim, setClaim] = useState({})
  const [jsonOutput, setJsonOutput] = useState(null)
  const { token } = useContext(AuthContext)

  const claimsAdvisorRef = useRef(null)
  const inspectedAtRef = useRef(new Date().toDateString())
  const contactDetailsRef = useRef(null)
  const contactNameRef = useRef(null)
  const referenceStatusRef = useRef(null)
  const imagesRef = useRef([])

  const complaintRef = useRef(null)
  const observationsRef = useRef(null)
  const conclusionRef = useRef(null)

  const partNumberRef = useRef(null)
  const vehicleRegistrationRef = useRef(null)
  const chassisNumberRef = useRef(null)

  // const chassisNumberRef = useRef(null)
  // const chassisNumberRef = useRef(null)
  // const chassisNumberRef = useRef(null)
  //
  const vehicleManufacturerRef = useRef(null)
  const vehicleModelRef = useRef(null)
  const yearVehicleManufacturedRef = useRef(null)
  const engineCCRef = useRef(null)
  const engineCodeRef = useRef(null)

  const mileageRef = useRef(null)

  const [sections, setSections] = useState([])
  const [loading, setLoading] = useState(true)

  const [searchParams] = useSearchParams()
  const claimNo = searchParams.get("id")

  useEffect(() => {
    async function getData() {
      try {
        const result = await fetchClaimData(claimNo, token)
        setClaim(result)
      } catch (err) {
      } finally {
        setLoading(false)
      }
    }
    getData()
  }, [claimNo, token])

  const handleImageUpload = (event) => {
    imagesRef.current = Array.from(event.target.files)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = {
      claim: claim,
      info: {
        claimsAdvisor: claimsAdvisorRef.current.value,
        contactDetails: contactDetailsRef.current.value,
        inspectedAt: inspectedAtRef.current.value,
        contactName: contactNameRef.current.value,
        partNumber: partNumberRef.current.value,
        vehicleRegistration: vehicleRegistrationRef.current.value,
        chassisNumber: chassisNumberRef.current.value,

        vehicleManufacturer: vehicleManufacturerRef.current.value,
        vehicleModel: vehicleModelRef.current.value,
        yearVehicleManufactured: yearVehicleManufacturedRef.current.value,
        engineCC: engineCCRef.current.value,
        engineCode: engineCodeRef.current.value,

        mileage: mileageRef.current.value,

        complaint: complaintRef.current.value,
        observations: observationsRef.current.value,
        conclusion: conclusionRef.current.value,

        images: imagesRef.current,
      },
      sections: sections.map((section) => ({
        title: section.title,
        description: section.description,
        actions: section.actions,
      })),
    }

    setJsonOutput(formData)
  }

  return (
    <div style={reportStyle.outerStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ ...reportStyle.cardStyle, padding: 3, boxShadow: 3 }}>
            {loading ? (
              <Spinner />
            ) : (
              <div className="main-wrapper">
                <header>
                  <div className="header-wrapper">
                    <h2>Inspection #{claim.details.unique_ref}</h2>
                  </div>
                </header>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  {/* customer details */}
                  {/* admin details */}
                  <TextField
                    label="Claims Advisor"
                    defaultValue="Kyall Stead"
                    inputRef={claimsAdvisorRef}
                    fullWidth
                  />
                  <TextField
                    label="Contact Details"
                    defaultValue="warranty@bgautomotive.co.uk"
                    inputRef={contactDetailsRef}
                    fullWidth
                  />
                  <TextField
                    label="Date of Examination"
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    inputRef={inspectedAtRef}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Contact Name"
                    defaultValue="Warranty Admin"
                    inputRef={contactNameRef}
                    fullWidth
                  />
                  <TextField
                    label="Product Part Number"
                    defaultValue={
                      claim.adminOverride.new_art_num !== null
                        ? claim.adminOverride.new_art_num
                        : claim.details.art_num
                    }
                    inputRef={partNumberRef}
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="Vehicle Registration"
                    defaultValue={claim.details.vehicle_reg}
                    inputRef={vehicleRegistrationRef}
                    fullWidth
                  />
                  <TextField
                    label="Chassis Number"
                    defaultValue={claim.details.chassis_num}
                    inputRef={chassisNumberRef}
                    fullWidth
                  />

                  <TextField
                    label="Vehicle Manufacturer"
                    inputRef={vehicleManufacturerRef}
                  />
                  <TextField label="Vehicle Model" inputRef={vehicleModelRef} />
                  <TextField
                    label="Vehicle Manufacturer Year"
                    type="date"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    inputRef={yearVehicleManufacturedRef}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField label="Engine CC" inputRef={engineCCRef} />
                  <TextField label="Engine Code" inputRef={engineCodeRef} />

                  <TextField
                    label={
                      "Mileage of claimed component (" +
                      claim.details.mileage_units +
                      ")"
                    }
                    defaultValue={
                      claim.details.mileage_failed -
                      claim.details.mileage_fitted
                    }
                    inputRef={mileageRef}
                    fullWidth
                  />
                  <TextField
                    label="Complaint"
                    defaultValue={claim.details.claim_description}
                    inputRef={complaintRef}
                    multiline
                    rows={3}
                    fullWidth
                  />
                  <TextField
                    label="Observations"
                    inputRef={observationsRef}
                    multiline
                    rows={6}
                    fullWidth
                  />
                  <TextField
                    label="Conclusion"
                    inputRef={conclusionRef}
                    multiline
                    rows={6}
                    fullWidth
                  />

                  <Button
                    variant="contained"
                    component="label"
                    sx={{ marginBottom: 4, margin: "auto" }}
                  >
                    <DriveFolderUploadIcon sx={{ marginRight: "3px" }} />
                    <p>Upload Images</p>
                    <input
                      type="file"
                      multiple
                      hidden
                      onChange={(e) => handleImageUpload(e)}
                    />
                  </Button>
                  <Button type="submit" variant="contained" color="secondary">
                    <p>Render</p>
                  </Button>
                </Box>
              </div>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ ...reportStyle.cardStyle, padding: 3, boxShadow: 3 }}>
            {jsonOutput && (
              <RenderInspection
                formData={jsonOutput}
                style={{ width: "100%", minHeight: "90vh" }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
