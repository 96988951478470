import * as React from "react"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Copyright from "../Items/Copyright"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { mainListItems, secondaryListItems } from "../Nav/listItems"
import Chart from "../Items/Chart"
import Overview from "../Items/Overview"
import FullTable from "../Items/FullTable"
import { useNavigate } from "react-router-dom"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { getClaimTypeColor, getClaimStatusColor } from "../Items/Colors"
import { useEffect } from "react"

export default function FullClaim() {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "BGA Warranty | Full Claim"
  }, [])

  const columns = [
    { field: "unique_ref", headerName: "ID", width: 130 },
    { field: "customer_ref", headerName: "Customer Ref", width: 120 },
    { field: "acc_code", headerName: "Account Code", width: 120 },
    {
      field: "claim_type",
      headerName: "Claim Type",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: getClaimTypeColor(params.row.claim_type),
            padding: "0.25em 0.5em",
            borderRadius: "4px",
          }}
        >
          {params.row.claim_type}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,

      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: getClaimStatusColor(params.row.status),
            padding: "0.25em 0.5em",
            borderRadius: "4px",
          }}
        >
          {params.row.status}
        </span>
      ),
    },
    { field: "claim_date", headerName: "Date", width: 150 },
    { field: "art_num", headerName: "Part Number", width: 150 },
    {
      field: "claim_description",
      headerName: "Description",
      width: 300,
      align: "right",
      headerAlign: "right",
    },
  ]

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          paddingBottom: "120px",
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {/* Recent RecentTable */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <FullTable
                title={"Full Claim"}
                type="full_claim"
                columns={columns}
              />
            </Paper>
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  )
}
