import React, { useState, useEffect, useContext, useRef } from "react"
import AuthContext from "../../AuthContext"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import StepBar from "../Items/StepBar"
import Divider from "@mui/material/Divider"
import "../Pages/Claim.css"
import JohansImageGallery from "../Items/JohansImageGallery"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"
import axios from "axios"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"
import ImageGallery from "react-image-gallery"
import "./Claim.css"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import Copyright from "../Items/Copyright"
import Spinner from "../Items/Spinner"
import GetAppIcon from "@mui/icons-material/GetApp"
import { GenerateCAR, RenderCAR } from "../Reports/GenerateCAR.js"
import {
  GenerateInspection,
  RenderInspection,
} from "../Reports/GenerateInspection.js"
import { GenerateFDR, RenderFDR } from "../Reports/GenerateFDR.js"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  CircularProgress,
} from "@mui/material"
import "stream-chat-react/dist/css/v2/index.css"

/**
 * Component to display the details of a claim, and allow for the management of the claim.
 * Different views are shown based on the user's role. The user can view the claim details, collect the claim, price the claim, and make a decision on the claim.
 * @param {Object} claimDetails - The details of the claim.
 * @param {Object} claimCollection - The collection details of the claim.
 * @param {Object} claimDecision - The decision details of the claim.
 * @param {Object} customerDetails - The details of the customer.
 * @param {Function} renderField - Function to render a text field.
 * @param {Function} renderMultilineField - Function to render a multiline text field.
 * @param {Function} renderDateField - Function to render a date field.
 * @param {Function} renderSelectField - Function to render a select field.
 * @param {Function} renderValueField - Function to render a value field.
 * @param {Function} handleInputChange - Function to handle input changes.
 * @param {Function} handleCollectChange - Function to handle collection changes.
 * @param {Function} handlePricingChange - Function to handle pricing changes.
 * @param {Function} handleDecisionChange - Function to handle decision changes.
 * @param {Function} handleCheckboxChange - Function to handle checkbox changes.
 * @param {Function} handleFileChange - Function to handle file changes.
 * @param {Boolean} isEditMode - Whether the user is in edit mode.
 * @param {String} userRole - The role of the user.
 * @param {Function} downloadTemplate - Function to download a template.
 * @returns {JSX.Element}
 */

const { API_URL } = require("../../config")

/// Helper function to render a detail field
function Detail({ name, content, ...props }) {
  return (
    <p className="detail-field">
      <strong>{name}</strong>
      {content}
    </p>
  )
}

const ClaimDetails = ({
  claimDetails,
  adminOverride,
  setAdminOverride,
  claimCollection,
  claimDecision,
  customerDetails,
  renderField,
  renderBGAPartRef,
  renderValueField,
  renderMultilineField,
  handleInputChange,
  userRole,
  renderFieldBlur,
}) => (
  <Grid item xs={12} md={6} lg={6}>
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        minHeight: "700px",
      }}
      className="paper-box"
    >
      <div className="main-wrapper">
        <header>
          <div className="header-wrapper" style={{ "margin-bottom": "1rem" }}>
            <h2>Claim Details #{claimDetails.unique_ref}</h2>
          </div>
        </header>
        <div className="claim-details">
          <div className="text-wrapper">
            <p style={{ fontSize: "1.2em" }}>
              <strong>Customer Reference: </strong>
              {claimDetails.customer_ref}
            </p>
          </div>
        </div>
        <div className="claim-details">
          <div className="text-wrapper">
            <Detail name="Claim Type: " content={claimDetails.claim_type} />
            <Detail name="Account Code: " content={customerDetails.acc_code} />
            <Detail name="Date: " content={claimDetails.claim_date} />

            {userRole === "admin" ? (
              renderBGAPartRef(
                claimDetails.art_num,
                "BGA Part Ref",
                adminOverride,
                adminOverride.trim() !== "" ? (
                  <>
                    <span style={{ textDecoration: "line-through" }}>
                      {claimDetails.art_num}
                    </span>{" "}
                    <span style={{ color: "red" }}>{adminOverride}</span>
                  </>
                ) : (
                  claimDetails.art_num
                ),
                "",
                (e) => {
                  setAdminOverride(e.target.value)
                }
              )
            ) : (
              <Detail name="BGA Part Ref: " content={claimDetails.art_num} />
            )}
            {userRole === "admin" ? (
              renderField(
                "Batch Code",
                claimDetails.batch_code,
                "batch_code",
                handleInputChange
              )
            ) : (
              <Detail name="Batch Code: " content={claimDetails.batch_code} />
            )}
          </div>
          {userRole === "admin" && (
            <div className="text-wrapper">
              {renderFieldBlur(
                "Supplier",
                claimDetails.supplier_acc,
                "supplier_acc",
                handleInputChange,
                claimDetails
              )}
            </div>
          )}
          <div className="text-wrapper">
            {renderField(
              "Vehicle Reg",
              claimDetails.vehicle_reg,
              "vehicle_reg",
              handleInputChange
            )}
            {renderField(
              "Chassis Num",
              claimDetails.chassis_num,
              "chassis_num",
              handleInputChange
            )}

            <Detail
              name="Contact Number: "
              content={claimDetails.contact_number}
            />
          </div>
          <div className="text-wrapper">
            <Detail
              name="Mileage Units: "
              content={claimDetails.mileage_units}
            />
            <Detail
              name="Mileage Fitted: "
              content={claimDetails.mileage_fitted}
            />
            <Detail
              name="Mileage Failed: "
              content={claimDetails.mileage_failed}
            />
            <Detail
              name="Installation Date: "
              content={claimDetails.installation_date}
            />
            <Detail name="Removal Date: " content={claimDetails.removal_date} />
          </div>
          <div className="text-wrapper">
            <Detail
              name="Total Parts Value: "
              content={claimDetails.parts_value}
            />
            {
              // <Detail name="Quantity: " content={claimDetails.quantity} />
            }
          </div>
          <div className="text-wrapper">
            {claimDetails.claim_type === "Full Claim" && (
              <>
                <Detail
                  name="Labour Value: "
                  content={claimDetails.labour_value}
                />
                <Detail
                  name="Labour Rate: "
                  content={claimDetails.labour_rate}
                />
                <Detail
                  name="Labour Time: "
                  content={claimDetails.labour_time}
                />
                {renderField(
                  "Installer Name",
                  claimDetails.installer_name,
                  "installer_name",
                  handleInputChange
                )}
                {renderField(
                  "Installer Address",
                  claimDetails.installer_address1,
                  "installer_address1",
                  handleInputChange
                )}
                {renderField(
                  "Installer City",
                  claimDetails.installer_address2,
                  "installer_address2",
                  handleInputChange
                )}
                {renderField(
                  "Installer County",
                  claimDetails.installer_county,
                  "installer_county",
                  handleInputChange
                )}
                {renderField(
                  "Installer Country",
                  claimDetails.installer_country,
                  "installer_country",
                  handleInputChange
                )}
                {renderField(
                  "Installer Post Code",
                  claimDetails.installer_postcode,
                  "installer_postcode",
                  handleInputChange
                )}
                {renderMultilineField(
                  "Labour Description",
                  claimDetails.labour_description,
                  "labour_description",
                  handleInputChange
                )}
                <br></br>
                <Detail
                  name="Expense Value: "
                  content={claimDetails.expense_value}
                />
                {renderMultilineField(
                  "Expense Description",
                  claimDetails.expense_description,
                  "expense_description",
                  handleInputChange
                )}
              </>
            )}
          </div>
          <div className="text-wrapper">
            <Detail
              name="Customer Name: "
              content={customerDetails.first_name}
            />
            <Detail name="Customer Email: " content={customerDetails.email} />
          </div>

          <div className="text-wrapper" style={{ minHeight: "150px" }}>
            {renderMultilineField(
              "Description",
              claimDetails.claim_description,
              "claim_description",
              handleInputChange
            )}
          </div>
          <br />
        </div>
      </div>
    </Paper>
  </Grid>
)

const ClaimImages = ({ claimDetails }) => {
  const [numPages, setNumPages] = useState(null)
  const [showPdf, setShowPdf] = useState(false)
  const [pdfFile, setPdfFile] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const navigate = useNavigate()

  const viewPDF = (pdfLink) => {
    navigate("/pdf-viewer", { state: { fileLink: pdfLink } })
  }

  const images =
    claimDetails?.files?.filter((file) =>
      /\.(jpg|jpeg|png|gif)(\?.*)?$/i.test(file)
    ) || []

  const pdfs =
    claimDetails?.files?.filter((file) => /\.pdf(\?.*)?$/i.test(file)) || []

  const imageGalleryItems = images.map((item) => ({
    original: item,
    thumbnail: item,
    thumbnailHeight: 50,
    thumbnailWidth: 50,
  }))

  const getFileName = (url) => {
    return url.split("/").pop().split("?")[0]
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          width: "600px",
          height: "fit-content",
        }}
        className="paper-box"
      >
        <div className="main-wrapper">
          <header>
            <div className="header-wrapper" style={{ marginBottom: "20px" }}>
              <h2>Media/Files</h2>
            </div>
          </header>
          <div
            style={{
              alignContent: "center",
              display: "flex",
              overflowX: "scroll",
            }}
          >
            {pdfs.map((pdf, index) => (
              <button
                className="pdf-view-btn"
                style={{ marginRight: "10px", marginBottom: "5px" }}
                key={index}
                onClick={() => viewPDF(pdf)}
              >
                <PictureAsPdfIcon style={{ marginRight: "5px" }} />
                {getFileName(pdf)}
              </button>
            ))}
          </div>
          <div
            className="image-gallery-wrapper"
            style={{
              // border: "1px solid red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {imageGalleryItems.length > 0 ? (
              // <ImageGallery
              //   items={imageGalleryItems}
              //   thumbnailPosition="bottom"
              //   showBullets={true}
              // />
              <JohansImageGallery urls={images} />
            ) : (
              <p>No images available</p>
            )}
          </div>
        </div>
      </Paper>
    </Grid>
  )
}

const ClaimCollect = ({
  claimCollect,
  renderField,
  renderMultilineField,
  renderDateField,
  renderSelectField,
  handleCollectChange,
  sendCollectionEmail,
  isEditMode,
  userRole,
}) => (
  <Grid item xs={6} md={6} lg={6}>
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        width: "600px",
        height: "fit",
      }}
      className="paper-box"
    >
      <div className="main-wrapper">
        <header>
          <div className="header-wrapper">
            <h2>Collection</h2>
          </div>
        </header>
        <div className="claim-details">
          <div className="text-wrapper">
            {renderField(
              "Address",
              claimCollect.address1,
              "address1",
              handleCollectChange
            )}
            {renderField(
              "City",
              claimCollect.address2,
              "address2",
              handleCollectChange
            )}
            {renderField(
              "County",
              claimCollect.county,
              "county",
              handleCollectChange
            )}
            {renderField(
              "Country",
              claimCollect.country,
              "country",
              handleCollectChange
            )}
            {renderField(
              "Postcode",
              claimCollect.postcode,
              "postcode",
              handleCollectChange
            )}
          </div>
          <br />
          <div className="text-wrapper">
            {renderDateField(
              "Collection Date",
              claimCollect.date,
              "date",
              handleCollectChange
            )}

            {userRole === "admin" ? (
              renderField(
                "Tracking Number",
                claimCollect.tracking_number,
                "tracking_number",
                handleCollectChange
              )
            ) : (
              <p className="detail-field">
                <strong>Tracking Number: </strong>
                {claimCollect.tracking_number}
              </p>
            )}
            {userRole === "admin" ? (
              renderField(
                "Collection Reference",
                claimCollect.collection_ref,
                "collection_ref",
                handleCollectChange
              )
            ) : (
              <p className="detail-field">
                <strong>Collection Reference: </strong>
                {claimCollect.collection_ref}
              </p>
            )}
            {userRole === "admin" ? (
              renderField(
                "Courier",
                claimCollect.courier,
                "courier",
                handleCollectChange
              )
            ) : (
              <p className="detail-field">
                <strong>Courier: </strong>
                {claimCollect.courier}
              </p>
            )}
            {userRole === "admin" ? (
              renderField("SRN", claimCollect.srn, "srn", handleCollectChange)
            ) : (
              <p className="detail-field">
                <strong>SRN: </strong>
                {claimCollect.srn}
              </p>
            )}
            <br />

            {userRole === "admin" &&
              (claimCollect.arranged !== true ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    isEditMode ||
                    claimCollect.address1 === null ||
                    claimCollect.address1 === "" ||
                    claimCollect.address2 === null ||
                    claimCollect.address2 === "" ||
                    claimCollect.county === null ||
                    claimCollect.county === "" ||
                    claimCollect.country === null ||
                    claimCollect.country === "" ||
                    claimCollect.postcode === null ||
                    claimCollect.postcode === "" ||
                    claimCollect.date === null ||
                    claimCollect.date === "" ||
                    claimCollect.tracking_number === null ||
                    claimCollect.tracking_number === "" ||
                    claimCollect.collection_ref === null ||
                    claimCollect.collection_ref === "" ||
                    claimCollect.courier === null ||
                    claimCollect.courier === "" ||
                    claimCollect.srn === null ||
                    claimCollect.srn === ""
                  }
                  onClick={sendCollectionEmail}
                >
                  <p>Send Collection Email</p>
                </Button>
              ) : (
                <h3>Collection email has been sent</h3>
              ))}
          </div>

          <br />
          <div className="text-wrapper">
            <FormControlLabel
              label="This part has arrived"
              sx={{ display: "block" }}
              control={
                <Checkbox
                  checked={claimCollect.returned || false}
                  onChange={(e) =>
                    handleCollectChange({
                      target: {
                        name: "returned",
                        value: e.target.checked,
                      },
                    })
                  }
                  disabled={!isEditMode || userRole != "admin"}
                />
              }
            />
          </div>
          <div className="text-wrapper">
            {userRole === "admin" ? (
              renderMultilineField(
                "Comments",
                claimCollect.comments,
                "comments",
                handleCollectChange
              )
            ) : (
              <p className="detail-field">
                <strong>Comments: </strong>
                {claimCollect.comments}
              </p>
            )}
          </div>
        </div>
      </div>
    </Paper>
  </Grid>
)

const ClaimPricing = ({
  claimDetails,
  claimPricing,
  renderValueField,
  renderMultilineField,
  handlePricingChange,
  handleCheckboxChange,
  // handleSignOffChange,
  handleFileChange,
  isEditMode,
  downloadTemplate,
  userRole,
}) => {
  const navigate = useNavigate()

  const viewPDF = (pdfLink) => {
    navigate("/pdf-viewer", { state: { fileLink: pdfLink } })
  }

  const fdr_pdf = claimDetails.reports?.fdr

  const getFileNameReport = (url) => {
    const filename = url.split("/").pop(3).split("?")[0]
    return filename
  }

  // Filter out labour time and rate parts
  const labourTimePart = claimPricing.parts?.find(
    (part) => part.part === "Labour time"
  )
  const labourRatePart = claimPricing.parts?.find(
    (part) => part.part === "Labour rate"
  )

  const filteredParts = claimPricing.parts?.filter(
    (part) => part.part !== "Labour time" && part.part !== "Labour rate"
  )

  // Calculate the totals for claim value and confirmed value ignoring labour time and rate
  const totalOriginalValue =
    filteredParts?.reduce((sum, part) => sum + part.original_value, 0) || 0

  const totalCreditValue =
    filteredParts?.reduce((sum, part) => {
      return part.confirmed ? sum + parseFloat(part.credit_value || 0) : sum
    }, 0) || 0

  const totalDebitValue =
    filteredParts?.reduce((sum, part) => {
      return part.confirmed ? sum + parseFloat(part.debit_value || 0) : sum
    }, 0) || 0

  if (userRole !== "admin") {
    navigate("/unauthorized")
    return null
  }

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          minHeight: {
            xs: "500px", // Mobile
            sm: "600px", // Tablet
            md: "700px", // Desktop
          },
          minWidth: {
            xs: "120%", // Mobile
            sm: "120%", // Tablet
            lg: "120%", // Desktop
          },
          overflowX: "auto", // Allow horizontal scrolling if needed
        }}
        className="paper-box"
      >
        <Box className="main-wrapper">
          <header>
            <Box className="header-wrapper">
              <h2>Pricing</h2>
            </Box>
          </header>
          <Box className="claim-details">
            <TableContainer>
              <Table
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  overflow: "hidden",
                  tableLayout: "auto", // Allow the table to adjust its width automatically
                }}
              >
                <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: 1,
                        fontWeight: "bold",
                        border: "1px solid #e0e0e0",
                      }}
                    >
                      Part
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 1,
                        fontWeight: "bold",
                        border: "1px solid #e0e0e0",
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 1,
                        fontWeight: "bold",
                        border: "1px solid #e0e0e0",
                        textAlign: "right",
                      }}
                    >
                      Original Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 1,
                        fontWeight: "bold",
                        border: "1px solid #e0e0e0",
                        textAlign: "right",
                      }}
                    >
                      Credit Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 4,
                        fontWeight: "bold",
                        border: "1px solid #e0e0e0",
                        textAlign: "right",
                      }}
                    >
                      Debit Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 1,
                        fontWeight: "bold",
                        border: "1px solid #e0e0e0",
                      }}
                    >
                      Confirmed
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredParts?.map((part, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{ padding: 1, border: "1px solid #e0e0e0" }}
                      >
                        {part.part}
                      </TableCell>
                      <TableCell
                        sx={{ padding: 1, border: "1px solid #e0e0e0" }}
                      >
                        {part.description}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: 1,
                          border: "1px solid #e0e0e0",
                          textAlign: "right",
                          fontFamily: "monospace",
                        }}
                      >
                        {part.original_value.toFixed(2)}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: 1,
                          border: "1px solid #e0e0e0",
                          textAlign: "right",
                          fontFamily: "monospace",
                        }}
                      >
                        {renderValueField(
                          "",
                          part.credit_value,
                          `parts[${claimPricing.parts.indexOf(
                            part
                          )}].credit_value`,
                          handlePricingChange
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: 1,
                          border: "1px solid #e0e0e0",
                          textAlign: "right",
                          fontFamily: "monospace",
                        }}
                      >
                        {renderValueField(
                          "",
                          part.debit_value,
                          `parts[${claimPricing.parts.indexOf(
                            part
                          )}].debit_value`,
                          handlePricingChange
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ padding: 1, border: "1px solid #e0e0e0" }}
                      >
                        <Checkbox
                          checked={part.confirmed}
                          onChange={(e) =>
                            handleCheckboxChange(
                              `parts[${claimPricing.parts.indexOf(
                                part
                              )}].confirmed`,
                              e
                            )
                          }
                          disabled={!isEditMode}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        fontWeight: "bold",
                        border: "1px solid #e0e0e0",
                        padding: 1,
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: 1,
                        textAlign: "right",
                        fontFamily: "monospace",
                      }}
                    >
                      {totalOriginalValue.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: 1,
                        textAlign: "right",
                        fontFamily: "monospace",
                      }}
                    >
                      {totalCreditValue.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: 1,
                        textAlign: "right",
                        fontFamily: "monospace",
                      }}
                    >
                      {totalDebitValue.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid #e0e0e0",
                        padding: 1,
                        textAlign: "right",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* Additional Labour, Comments, and File Upload sections */}
            <Box sx={{ mt: 2 }}>
              <div className="text-wrapper">
                {labourTimePart &&
                  renderValueField(
                    "Labour Time (Hours): ",
                    labourTimePart.credit_value,
                    `parts[${claimPricing.parts.indexOf(
                      labourTimePart
                    )}].credit_value`,
                    handlePricingChange
                  )}
                {labourRatePart &&
                  renderValueField(
                    "Labour Rate: ",
                    labourRatePart.credit_value,
                    `parts[${claimPricing.parts.indexOf(
                      labourRatePart
                    )}].credit_value`,
                    handlePricingChange
                  )}
              </div>
              <div className="text-wrapper">
                <Box sx={{ mt: 0, minHeight: "9rem" }}>
                  {renderMultilineField(
                    "Comments",
                    claimPricing.comments,
                    "comments",
                    handlePricingChange
                  )}
                </Box>
              </div>
              <Divider sx={{ my: 3 }} />
              {/* File upload section */}
              <Box justifyContent="center" alignItems="center">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={5}
                    justifyContent="center"
                    alignContent="center"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h6" gutterBottom align="center">
                        FDR Report
                      </Typography>
                      {fdr_pdf && (
                        <button
                          className="pdf-view-btn"
                          style={{ marginRight: "10px", marginBottom: "5px" }}
                          onClick={() => viewPDF(fdr_pdf)}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "5px" }} />
                          {getFileNameReport(fdr_pdf)}
                        </button>
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ mb: 2 }}
                        onClick={() => downloadTemplate("fdr")}
                      >
                        Template
                      </Button>
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={(e) => handleFileChange(e, "fdr")}
                        style={{ display: "none" }}
                        id="fdr-upload"
                      />
                      <label htmlFor="fdr-upload">
                        <Button
                          variant="contained"
                          component="span"
                          sx={{ mb: 2 }}
                        >
                          Upload file
                        </Button>
                      </label>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider sx={{ my: 2 }} />
              {/* Signatures */}
              <FormControlLabel
                label="Signed by Warranty"
                sx={{ display: "block" }}
                control={
                  <Checkbox
                    checked={claimPricing.sign_warranty || false}
                    onChange={(e) =>
                      handlePricingChange({
                        target: {
                          name: "sign_warranty",
                          value: e.target.checked,
                        },
                      })
                    }
                    disabled={!isEditMode}
                  />
                }
              />
              <FormControlLabel
                label="Signed by Matt"
                sx={{ display: "block" }}
                control={
                  <Checkbox
                    checked={claimPricing.sign_matt || false}
                    onChange={(e) =>
                      handlePricingChange({
                        target: { name: "sign_matt", value: e.target.checked },
                      })
                    }
                    disabled={true}
                  />
                }
              />
              <FormControlLabel
                label="Signed by Chris"
                sx={{ display: "block" }}
                control={
                  <Checkbox
                    checked={claimPricing.sign_chris || false}
                    onChange={(e) =>
                      handlePricingChange({
                        target: { name: "sign_chris", value: e.target.checked },
                      })
                    }
                    disabled={true}
                  />
                }
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

const ClaimDecision = ({
  claimDecision,
  claimDetails,
  renderField,
  renderSelectField,
  renderMultilineField,
  handleDecisionChange,
  isEditMode,
  handleFileChange,
  userRole,
  downloadTemplate,
}) => {
  const navigate = useNavigate()

  const viewPDF = (pdfLink) => {
    navigate("/pdf-viewer", { state: { fileLink: pdfLink } })
  }

  const pdfs =
    claimDetails?.files?.filter((file) => /\.pdf(\?.*)?$/i.test(file)) || []

  const car_pdf = claimDetails?.reports?.car

  const inspection_pdf = claimDetails?.reports?.inspection

  const getFileName = (url) => {
    return url.split("/").pop().split("?")[0]
  }

  const getFileNameReport = (url) => {
    const filename = url.split("/").pop(3).split("?")[0]
    return filename
  }

  return (
    <Grid item xs={6} md={6} lg={6}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          width: "600px",
          height: "fit",
          minHeight: "300px",
        }}
        className="paper-box"
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <header>
            <div className="header-wrapper" style={{ marginBottom: 0 }}>
              <h2>Decision</h2>
            </div>
          </header>
          <Divider />
          <Box sx={{ mt: 2 }}>
            {renderMultilineField(
              "Comments",
              claimDecision.comments,
              "comments",
              handleDecisionChange
            )}
          </Box>
          {renderSelectField(
            "Outcome",
            [
              { text: "-- Undecided --", value: "" },
              { text: "Claim Approved", value: "Claim Approved" },
              { text: "Claim Rejected", value: "Claim Rejected" },
            ],
            claimDecision.outcome || "",
            "outcome",
            handleDecisionChange
          )}
          {userRole == "admin" && (
            <Box>
              <Box sx={{ mt: 2 }}>
                {renderSelectField(
                  "Category",
                  [
                    { text: "-- Undecided --", value: "" },
                    { text: "Manufacturing", value: "manufacturing" },
                    { text: "Installation", value: "installation" },
                    { text: "Commercial", value: "commercial" },
                  ],
                  claimDecision.category || "",
                  "category",
                  handleDecisionChange
                )}
                <Divider sx={{ mt: 2 }} />
                <h3>Reports</h3>

                <FormControlLabel
                  label="CAR attached"
                  control={
                    <Checkbox
                      checked={claimDecision.car_report || false}
                      onChange={(e) =>
                        handleDecisionChange({
                          target: {
                            name: "car_report",
                            value: e.target.checked,
                          },
                        })
                      }
                      disabled={!isEditMode}
                    />
                  }
                />
                <FormControlLabel
                  label="Inspection report attached"
                  control={
                    <Checkbox
                      checked={claimDecision.inspection_report || false}
                      onChange={(e) =>
                        handleDecisionChange({
                          target: {
                            name: "inspection_report",
                            value: e.target.checked,
                          },
                        })
                      }
                      disabled={!isEditMode}
                    />
                  }
                />
                <FormControlLabel
                  label="FDR report attached"
                  control={
                    <Checkbox
                      checked={claimDecision.fdr_report || false}
                      onChange={(e) =>
                        handleDecisionChange({
                          target: {
                            name: "fdr_report",
                            value: e.target.checked,
                          },
                        })
                      }
                      disabled={!isEditMode}
                    />
                  }
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Files:
                </Typography>
                <Box>
                  {pdfs.map((pdf, index) => (
                    <button
                      className="pdf-view-btn"
                      style={{ marginRight: "10px", marginBottom: "5px" }}
                      key={index}
                      onClick={() => viewPDF(pdf)}
                    >
                      <PictureAsPdfIcon style={{ marginRight: "5px" }} />
                      {getFileName(pdf)}
                    </button>
                  ))}
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box justifyContent="center" alignItems="center">
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={5} justifyContent="center" alignItems="center">
                    <Box
                      sx={{
                        flexGrow: 1,
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h6" gutterBottom align="center">
                        CAR Report
                      </Typography>
                      {car_pdf && (
                        <button
                          className="pdf-view-btn"
                          style={{ marginRight: "10px", marginBottom: "5px" }}
                          onClick={() => viewPDF(car_pdf)}
                        >
                          <PictureAsPdfIcon style={{ marginRight: "5px" }} />
                          {getFileNameReport(car_pdf)}
                        </button>
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ mb: 2 }}
                        onClick={() => downloadTemplate("car")}
                      >
                        <p>Template</p>
                      </Button>
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={(e) => handleFileChange(e, "car")}
                        style={{ display: "none" }}
                        id="car-upload"
                      />
                      <label htmlFor="car-upload">
                        <Button
                          variant="contained"
                          component="span"
                          sx={{ mb: 2 }}
                        >
                          <p>Upload file</p>
                        </Button>
                      </label>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                    <Box
                      sx={{
                        flexGrow: 1,
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h6" gutterBottom align="center">
                        Inspection Report
                      </Typography>
                      {inspection_pdf && (
                        <button
                          className="pdf-view-btn"
                          style={{ marginRight: "10px", marginBottom: "5px" }}
                          onClick={() => viewPDF(inspection_pdf)}
                        >
                          <PictureAsPdfIcon style={{ marginRight: "5px" }} />
                          {getFileNameReport(inspection_pdf)}
                        </button>
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ mb: 2 }}
                        onClick={() => downloadTemplate("inspection")}
                      >
                        <p>Template</p>
                      </Button>
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={(e) => handleFileChange(e, "inspection")}
                        style={{ display: "none" }}
                        id="inspection-upload"
                      />
                      <label htmlFor="inspection-upload">
                        <Button variant="contained" component="span">
                          <p>Upload file</p>
                        </Button>
                      </label>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Grid>
  )
}

const ClaimResolved = ({
  userRole,
  claimResolved,
  claimPricing,
  pricingTotal,
  claimDetails,
  claimDecision,
  renderSelectField,
  renderField,
  isEditMode,
  handleResolvedChange,
  handleDispute,
}) => {
  const navigate = useNavigate()

  const pdfs =
    claimDetails?.files?.filter((file) => /\.pdf(\?.*)?$/i.test(file)) || []

  const inspection_pdf = claimDetails?.reports?.inspection

  const getFileName = (url) => {
    return url.split("/").pop().split("?")[0]
  }

  const viewPDF = (pdfLink) => {
    navigate("/pdf-viewer", { state: { fileLink: pdfLink } })
  }

  const filteredParts = claimPricing.parts?.filter(
    (part) => part.part !== "Labour time" && part.part !== "Labour rate"
  )

  // Calculate the totals for claim value and confirmed value ignoring labour time and rate
  const totalOriginalValue =
    filteredParts?.reduce((sum, part) => sum + part.original_value, 0) || 0
  const totalCreditValue =
    userRole === "admin"
      ? filteredParts?.reduce((sum, part) => {
          return part.confirmed ? sum + parseFloat(part.credit_value || 0) : sum
        }, 0) || 0
      : pricingTotal
  const totalDebitValue =
    filteredParts?.reduce((sum, part) => {
      return part.confirmed ? sum + parseFloat(part.debit_value || 0) : sum
    }, 0) || 0

  const getFileNameReport = (url) => {
    const filename = url.split("/").pop(3).split("?")[0]
    return filename
  }
  return (
    <Grid item xs={6} md={6} lg={6}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          width: "600px",
          height: "700px",
        }}
        className="paper-box"
      >
        <div className="main-wrapper" style={{ height: "100%" }}>
          <header>
            <div className="header-wrapper">
              <h2>Resolved</h2>
            </div>
          </header>
          <div className="claim-details" style={{ flexGrow: 1 }}>
            {claimDetails.claim_type === "Full Claim" && (
              <div className="text-wrapper" style={{ flexGrow: 1 }}>
                <p>
                  <strong>Total Credit Amount: </strong>
                  <span style={{ fontFamily: "monospace", fontSize: "1.2rem" }}>
                    {totalCreditValue}
                  </span>
                </p>
              </div>
            )}
            <div className="text-wrapper" style={{ flexGrow: 1 }}>
              {
                //   renderSelectField(
                //   "Resolved",
                //   [
                //     { text: "Resolved", value: true },
                //     { text: "Unresolved", value: false },
                //   ],
                //   claimResolved.resolved ? "Resolved" : "Unresolved",
                //   "resolved",
                //   handleResolvedChange
                // )
              }

              <FormControlLabel
                label="Resolved"
                control={
                  <Checkbox
                    checked={claimResolved.resolved || false}
                    onChange={(e) =>
                      handleResolvedChange({
                        target: {
                          name: "resolved",
                          value: e.target.checked,
                        },
                      })
                    }
                    disabled={!isEditMode}
                  />
                }
              />
            </div>
            <div className="text-wrapper" style={{ flexGrow: 1 }}>
              {renderField(
                "Comments",
                claimResolved.comments,
                "comments",
                handleResolvedChange
              )}
              <Divider sx={{ my: 3 }} />
              {renderField(
                "SCR",
                claimResolved.scr,
                "scr",
                handleResolvedChange
              )}
            </div>
          </div>
        </div>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            Files:
          </Typography>
          {pdfs.map((pdf, index) => (
            <button
              className="pdf-view-btn"
              style={{ marginRight: "10px", marginBottom: "5px" }}
              key={index}
              onClick={() => viewPDF(pdf)}
            >
              <PictureAsPdfIcon style={{ marginRight: "5px" }} />
              {getFileName(pdf)}
            </button>
          ))}
          <Box>
            {inspection_pdf && claimDecision.outcome === "Claim Rejected" && (
              <button
                className="pdf-view-btn"
                style={{ marginRight: "10px", marginBottom: "5px" }}
                onClick={() => viewPDF(inspection_pdf)}
              >
                <PictureAsPdfIcon style={{ marginRight: "5px" }} />
                {getFileNameReport(inspection_pdf)}
              </button>
            )}
          </Box>
        </Box>
        {claimDetails.claim_type !== "No Quibble" && (
          <>
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                margin: "auto",
                mb: 2,
              }}
            >
              {claimResolved.dispute === null &&
                (userRole !== "admin" ? (
                  <h3>Pending action</h3>
                ) : (
                  <h3>Pending response from customer.</h3>
                ))}
              {claimResolved.dispute === true &&
                (userRole !== "admin" ? (
                  <h3>
                    You have disputed this claim. Check your inbox for an email
                    from the Warranty team.
                  </h3>
                ) : (
                  <h3>The customer has disputed this claim.</h3>
                ))}
              {claimResolved.dispute === false &&
                (userRole !== "admin" ? (
                  <h3>You have confirmed this claim.</h3>
                ) : (
                  <h3>The customer has confirmed this claim.</h3>
                ))}
            </Box>

            {!(claimResolved.dispute !== null)
              ? userRole !== "admin" && (
                  <Box
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      margin: "auto",
                      mb: 2,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDispute(true)} //choose to DISPUTE
                        sx={{ marginRight: "2em" }}
                      >
                        <p>Dispute</p>
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleDispute(false)} //choose to CONFIRM
                      >
                        <p>Confirm</p>
                      </Button>
                    </div>
                  </Box>
                )
              : (claimResolved.dispute === true ||
                  (userRole === "admin" &&
                    claimResolved.dispute === false)) && (
                  <Box
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      margin: "auto",
                      mb: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDispute(null)} //reset the dispute
                      sx={{ width: "110%" }}
                    >
                      <p>{userRole === "admin" ? "Reset" : "Remove Dispute"}</p>
                    </Button>
                  </Box>
                )}
          </>
        )}
      </Paper>
    </Grid>
  )
}

export default function Claim() {
  const [searchParams] = useSearchParams()
  const claimNo = searchParams.get("id")
  const stage = searchParams.get("stage")
  const [claimDetails, setClaimDetails] = useState({})
  const [claimImages, setClaimImages] = useState([])
  const [claimCollect, setClaimCollect] = useState({})
  const [claimDecision, setClaimDecision] = useState({})
  const [claimPricing, setClaimPricing] = useState({})
  const [pricingTotal, setPricingTotal] = useState(0.0)
  const [claimResolved, setClaimResolved] = useState({})
  const [claimSupplier, setClaimSupplier] = useState({})
  const [originalClaimDetails, setOriginalClaimDetails] = useState({})
  const [originalClaimCollect, setOriginalClaimCollect] = useState({})
  const [originalClaimDecision, setOriginalClaimDecision] = useState({})
  const [originalClaimResolved, setOriginalClaimResolved] = useState({})
  const [originalClaimPricing, setOriginalClaimPricing] = useState({})
  const [customerDetails, setCustomerDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [dateError, setDateError] = useState(null)
  const { token, userRole } = useContext(AuthContext)
  const [isEditMode, setIsEditMode] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [comment, setComment] = useState("")
  const [showCommentBox, setShowCommentBox] = useState(false)
  const [requestDetailsMessage, setRequestDetailsMessage] = useState("")
  const [adminCommentMessage, setAdminCommentMessage] = useState("")
  const [adminOverride, setAdminOverride] = useState(null)
  const [fileType, setFileType] = useState(null)
  const adminCommentRef = useRef("")
  const adminComment = "This is an admin comment"
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    files: [],
  })

  const stageOrder = ["submit", "collection", "decision", "pricing", "resolved"]
  const [isAuthorized, setIsAuthorized] = useState(false)

  const dialogRef = useRef(null)

  const deleteRef = useRef(null)
  const [deleteReason, setDeleteReason] = useState("")
  const [deleteCheck, setDeleteCheck] = useState(false)

  const rejectRef = useRef(null)
  const [rejectReason, setRejectReason] = useState("")

  useEffect(() => {
    document.title = `BGA Warranty | ${claimNo}`
  }, [])

  useEffect(() => {
    const fetchClaimData = async () => {
      setLoading(true)
      try {
        if (!token) {
          throw new Error("No token available")
        }

        const userDetailsResponse = await axios
          .get(`${API_URL}/auth/whoami`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .catch((error) => {
            navigate("/session-expired")
            return
          })

        const claimDetailsResponse = await axios.get(
          `${API_URL}/claims/${claimNo}/submit`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const claimDebitResponse = await axios.get(
          `${API_URL}/claims/${claimNo}/submit/debit`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const customerDetailsResponse = await axios.get(
          `${API_URL}/customer/${claimDetailsResponse.data.customer_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const claimCollectResponse = await axios.get(
          `${API_URL}/claims/${claimNo}/collection`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const claimDecisionResponse = await axios.get(
          `${API_URL}/claims/${claimNo}/decision`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const claimPricingResponse = await axios.get(
          `${API_URL}/claims/${claimNo}/pricing`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const pricingTotalResponse = await axios.get(
          `${API_URL}/claims/${claimNo}/pricing/total`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const claimResolvedResponse = await axios.get(
          `${API_URL}/claims/${claimNo}/resolved`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )

        if (userDetailsResponse.data.is_admin) {
          const adminOverrideResponse = await axios.get(
            `${API_URL}/claims/${claimNo}/submit/admin_override`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          const adminCommentResponse = await axios.get(
            `${API_URL}/claims/${claimNo}/admin_comment`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          setAdminOverride(adminOverrideResponse.data.new_art_num)
          console.log(adminOverrideResponse)
          setAdminCommentMessage(adminCommentResponse.data.admin_comment)
        }

        // Add the claimDebitResponse data as a new property "supplier_acc" in claimDetailsResponse
        claimDetailsResponse.data.supplier_acc = claimDebitResponse.data

        // Log the updated claimDetailsResponse

        setClaimDetails(claimDetailsResponse.data)
        setOriginalClaimDetails(claimDetailsResponse.data) // Set original data
        setCustomerDetails(customerDetailsResponse.data)

        setClaimCollect(claimCollectResponse.data)
        setOriginalClaimCollect(claimCollectResponse.data) // Set original data

        setClaimDecision(claimDecisionResponse.data)
        setOriginalClaimDecision(claimDecisionResponse.data) // Set original data

        setClaimPricing(claimPricingResponse.data)
        setPricingTotal(pricingTotalResponse.data)
        setOriginalClaimPricing(claimPricingResponse.data) // Set original data

        setClaimResolved(claimResolvedResponse.data)
        setOriginalClaimResolved(claimResolvedResponse.data) // Set original data

        const currentStage = claimDetailsResponse.data.status
          ?.split(" ")[0]
          .toLowerCase()
        const currentStageIndex = stageOrder.indexOf(currentStage)
        const requestedStageIndex = stageOrder.indexOf(stage)

        if (requestedStageIndex <= currentStageIndex) {
          setIsAuthorized(true)
        }
      } catch (error) {
        setError("Error fetching data")
      }
      setLoading(false)
    }

    fetchClaimData()
  }, [claimNo, token, stage])

  const nextStage =
    stageOrder[
      stageOrder.indexOf(claimDetails.status?.split(" ")[0].toLowerCase()) + 1
    ]
  const claimType = claimDetails.claim_type

  if (loading) {
    return <Spinner />
  }

  if (!isAuthorized) {
    navigate("/unauthorized")
  }

  const handleDispute = (customerDispute) => {
    axios
      .post(
        `${API_URL}/claims/${claimNo}/dispute`,
        {
          dispute: customerDispute,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        if (customerDispute === null) {
          // empty
          setSnackbarMessage("Dispute has been reset")
        } else if (customerDispute) {
          // true
          setSnackbarMessage("Dispute raised")
        } else {
          // false
          setSnackbarMessage("Claim resolved")
        }
        window.location.reload() // Refresh the page
        setShowSnackbar(true)
      })
  }

  const handleFileChange = (event, type) => {
    const files = event.target.files
    setFormData({
      ...formData,
      files: files,
    })
    setFileType(type)
    dialogRef.current.showModal()
  }

  const handleUploadFiles = () => {
    setLoading(true)
    const type = fileType

    if (type !== null && type !== undefined) {
      const file = formData.files[0]
      if (file) {
        axios
          .post(
            `${API_URL}/claims/${claimNo}/upload_report/${type}`,
            {
              files: file,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            dialogRef.current.close() // Close the dialog
            // window.location.reload(); // Refresh the page
            setSnackbarMessage("File uploaded successfully")
            setShowSnackbar(true)
          })
          .catch((error) => {
            setError("Error uploading files")
          })
      } else {
      }
    } else {
      const data = new FormData()
      Array.from(formData.files).forEach((file) => {
        data.append("files", file)
      })

      axios
        .post(`${API_URL}/claims/${claimNo}/upload_files`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dialogRef.current.close() // Close the dialog
          // window.location.reload(); // Refresh the page
          setSnackbarMessage("Files uploaded successfully")
          setShowSnackbar(true)
          setTimeout(() => {
            window.location.reload()
          }, "1500")
        })
        .catch((error) => {
          setError("Error uploading files")
        })
    }
    setLoading(false)
    setFileType(null)
  }

  const handleRejectClaim = () => {
    setLoading(true)
    rejectRef.current.close() // Close the dialog
    axios
      .put(
        `${API_URL}/claims/${claimNo}/submit/reject`,
        { reason: rejectReason },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setSnackbarMessage("Claim has been Rejected")
        setShowSnackbar(true)
        navigate(`/claim?id=${claimNo}&stage=resolved`, {
          state: { message: "Claim has been rejected" },
        })
        setLoading(false)
      })
  }
  const handleCancelReject = () => {
    rejectRef.current.close() // Close the dialog
    setRejectReason("")
  }
  const handleDeleteClaim = () => {
    setLoading(true)
    deleteRef.current.close() // Close the dialog
    axios
      .delete(`${API_URL}/claims/${claimNo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          reason: deleteReason,
        },
      })
      .then((response) => {
        setSnackbarMessage("Claim has been deleted")
        setShowSnackbar(true)
        setLoading(false)
        navigate("/admin", { state: { message: "Claim has been deleted" } })
      })
  }
  const handleCancelDelete = () => {
    deleteRef.current.close() // Close the dialog
    setDeleteReason("")
    setDeleteCheck(false)
  }

  const handleCancel = () => {
    dialogRef.current.close() // Close the dialog
    setFormData({
      files: [],
    }) // Clear selected files
  }

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleNextStageClick = () => {
    axios
      .put(`${API_URL}/claims/${claimNo}/progress`, claimDetails, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOriginalClaimDetails(claimDetails) // Update original data
        setIsEditMode(false)
        {
          if (claimType === "No Quibble") {
            if (customerDetails.acc_code === "FPS002") {
              if (claimDetails.status === "Submit") {
                navigate(`/claim?id=${claimNo}&stage=collection`)
              } else if (claimDetails.status === "Collection") {
                navigate(`/claim?id=${claimNo}&stage=resolved`)
              }
            } else {
              navigate(`/claim?id=${claimNo}&stage=resolved`)
            }
          } else {
            // all full claims
            navigate(`/claim?id=${claimNo}&stage=${nextStage}`)
          }
        }
      })
      .catch((error) => {
        setError("Error updating claim")
      })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setClaimDetails({ ...claimDetails, [name]: value })
  }

  const handleCollectChange = (e) => {
    const { name, value } = e.target
    setClaimCollect({ ...claimCollect, [name]: value })
  }

  const sendCollectionEmail = (e) => {
    axios
      .post(
        `${API_URL}/claims/${claimNo}/collection/send_collection_email`,
        {
          address1: claimCollect.address1,
          address2: claimCollect.address2,
          county: claimCollect.county,
          country: claimCollect.country,
          postcode: claimCollect.postcode,

          date: claimCollect.date,
          tracking_number: claimCollect.tracking_number,
          collection_ref: claimCollect.collection_ref,
          courier: claimCollect.courier,
          srn: claimCollect.srn,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // setOriginalClaimDetails(claimDetails) // Update original data
        window.location.reload() // Refresh the page
      })
      .catch((error) => {
        setError("Error updating claim")
      })
  }

  const handleDecisionChange = (e) => {
    const { name, value } = e.target
    setClaimDecision({ ...claimDecision, [name]: value })
  }

  const handlePricingChange = (e) => {
    const { name, value } = e.target

    // Match the name with the expected pattern
    const match = name.match(/(.*)\[(\d+)\]\.(.*)/)

    if (match && match.length === 4) {
      const [field, index, subField] = match.slice(1, 4)

      if (field === "parts") {
        // Ensure claimPricing.parts is an array before proceeding
        const updatedParts = Array.isArray(claimPricing.parts)
          ? [...claimPricing.parts]
          : []
        updatedParts[Number(index)][subField] = value
        setClaimPricing({ ...claimPricing, parts: updatedParts })
      }
    } else {
      setClaimPricing({ ...claimPricing, [name]: value })
    }
  }

  const handleResolvedChange = (e) => {
    const { name, value } = e.target
    setClaimResolved({ ...claimResolved, [name]: value })
  }

  const handleCheckboxChange = (fieldName, event) => {
    const [field, index] = fieldName.match(/(.*)\[(\d+)\]\.(.*)/).slice(1, 4)
    const { checked } = event.target

    if (field === "parts") {
      const updatedParts = [...claimPricing.parts]
      updatedParts[Number(index)][fieldName.split(".").pop()] = checked
      setClaimPricing({ ...claimPricing, parts: updatedParts })
    } else {
      setClaimDetails({ ...claimDetails, [fieldName]: checked })
    }
  }

  const downloadTemplate = (templateType) => {
    navigate(`/${templateType}-report?id=${claimDetails.unique_ref}`)
  }

  const isValidDate = (dateString) => {
    // Create a date object from the dateString
    const date = new Date(dateString)
    // Check if the date is valid
    if (!(date instanceof Date && !isNaN(date))) {
      return false
    }
    // Extract the year part from the dateString
    const year = dateString.split("-")[0]
    // Check if the year has exactly 4 digits
    if (year.length !== 4) {
      return false
    }

    return true
  }

  const handleRequestDetailsSubmit = () => {
    axios
      .post(
        `${API_URL}/claims/${claimNo}/request_details`,
        { message: requestDetailsMessage },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setSnackbarMessage("Request details submitted successfully")
        setShowSnackbar(true)
        setShowCommentBox(false)
        setRequestDetailsMessage("")
        setComment("")
      })
      .catch((error) => {
        setSnackbarMessage("Error submitting request details")
        setShowSnackbar(true)
      })
  }

  const handleAdminCommentSubmit = () => {
    axios
      .post(
        `${API_URL}/claims/${claimNo}/admin_comment`,
        { admin_comment: adminCommentMessage, tickbox: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setSnackbarMessage("Admin Comment saved successfully")
        setShowSnackbar(true)
        setShowCommentBox(false)
        setRequestDetailsMessage("")
        setComment("")
      })
      .catch((error) => {
        setSnackbarMessage("Error saving Admin Comment")
        setShowSnackbar(true)
      })
  }

  const handleSaveClick = (stage) => {
    axios
      .post(`${API_URL}/claims/${claimNo}/submit`, claimDetails, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOriginalClaimDetails(claimDetails) // Update original data
      })
      .catch((error) => {
        setError("Error updating claim")
      })
    if (userRole === "admin") {
      axios.post(
        `${API_URL}/claims/${claimNo}/submit/debit`,
        {
          unique_ref: claimDetails.unique_ref,
          supplier_acc: claimDetails.supplier_acc,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      axios.post(
        `${API_URL}/claims/${claimNo}/submit/admin_override`,
        {
          new_art_num: adminOverride,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    }
    switch (stage) {
      case "collection":
        axios
          .post(`${API_URL}/claims/${claimNo}/collection`, claimCollect, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setOriginalClaimCollect(claimCollect) // Update original data
          })
          .catch((error) => {
            setError("Error updating claim")
          })
        break

      case "decision":
        axios
          .post(`${API_URL}/claims/${claimNo}/decision`, claimDecision, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setOriginalClaimDecision(claimDecision) // Update original data
          })
          .catch((error) => {
            setError("Error updating claim")
          })
        break

      case "pricing":
        const formattedClaimPricing = {
          ...claimPricing,
          comments: claimPricing.comments || "",
          parts: claimPricing.parts.map((part) => ({
            unique_ref: part.unique_ref,
            part: part.part,
            description: part.description,
            original__value: parseFloat(part.original_value),
            credit_value: parseFloat(part.credit_value),
            debit_value: parseFloat(part.debit_value),
            confirmed: part.confirmed,
          })),
        }

        axios
          .post(`${API_URL}/claims/${claimNo}/pricing`, formattedClaimPricing, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setOriginalClaimPricing(claimPricing) // Update original data
          })
          .catch((error) => {
            setError("Error updating claim")
          })
        break

      // added this on 2024-09-25
      case "resolved":
        axios
          .post(`${API_URL}/claims/${claimNo}/resolved`, claimResolved, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setOriginalClaimResolved(claimResolved) // Update original data
          })
          .catch((error) => {
            setError("Error updating claim")
          })
        break
    }
    setIsEditMode(false)
  }

  const handleCancelClick = () => {
    // Create deep copies of the original states
    const deepCopy = (obj) => JSON.parse(JSON.stringify(obj))

    setClaimDetails(deepCopy(originalClaimDetails)) // Revert to original data
    setClaimCollect(deepCopy(originalClaimCollect)) // Revert to original data
    setClaimDecision(deepCopy(originalClaimDecision)) // Revert to original data
    setClaimResolved(deepCopy(originalClaimResolved)) // Revert to original data
    setClaimPricing(deepCopy(originalClaimPricing)) // Revert to original data
    setIsEditMode(false)

    if (dateError) {
      setDateError(false)
    }
  }

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <div>{error}</div>
  }

  const renderField = (label, value, fieldName, handleChange) => {
    if (isEditMode) {
      return (
        <TextField
          label={label}
          name={fieldName}
          value={value || ""}
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "10px" }}
          onChange={handleChange}
        />
      )
    } else {
      return (
        <p className="detail-field">
          <strong>{label}: </strong>
          {value}
        </p>
      )
    }
  }
  const renderBGAPartRef = (
    editingLabel,
    label,
    editingValue,
    value,
    fieldName,
    handleChange
  ) => {
    if (isEditMode) {
      return (
        <TextField
          label={editingLabel}
          name={fieldName}
          value={editingValue || ""}
          variant="outlined"
          fullWidth
          sx={{
            marginBottom: "10px",
            input: { color: "red" },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "red",
            },
          }}
          onChange={handleChange}
        />
      )
    } else {
      return (
        <p className="detail-field">
          <strong>{label}: </strong>
          {value}
        </p>
      )
    }
  }

  const renderFieldBlur = (
    label,
    value,
    fieldName,
    handleChange,
    claimDetails
  ) => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState(value || "")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      let active = true

      if (!inputValue) {
        setOptions([])
        return undefined
      }

      // Fetch options from the endpoint when input changes
      setLoading(true)
      axios
        .get(`${API_URL}/products/supplier?q=${inputValue}`)
        .then((response) => {
          if (active) {
            setOptions(response.data || [])
            setLoading(false)
          }
        })
        .catch((error) => {
          setLoading(false)
        })

      return () => {
        active = false
      }
    }, [inputValue])

    if (isEditMode) {
      return (
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={options}
          loading={loading}
          inputValue={inputValue}
          onInputChange={(event, newValue) => setInputValue(newValue)}
          // Display {acc_code} - {company} in the dropdown
          getOptionLabel={(option) => `${option.acc_code} - ${option.company}`}
          // When an option is selected, set the value to acc_code only
          onChange={(event, newValue) =>
            handleChange({
              target: {
                name: fieldName,
                value: newValue ? newValue.acc_code : "",
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "10px" }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )
    } else {
      return (
        <p className="detail-field">
          <strong>{label}: </strong>
          {value}
        </p>
      )
    }
  }

  const renderMultilineField = (label, value, fieldName, handleChange) => {
    if (isEditMode) {
      return (
        <TextField
          label={label}
          name={fieldName}
          value={value || ""}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          sx={{ marginBottom: "10px" }}
          onChange={handleChange}
        />
      )
    } else {
      return (
        <p className="detail-field">
          <strong>{label}: </strong>
          {value}
        </p>
      )
    }
  }

  const renderDateField = (
    label,
    value,
    fieldName,
    handleChange,
    disabled = false
  ) => {
    if (userRole === "user") {
      disabled = true
    }

    const handleDateChange = (event) => {
      const date = event.target.value
      if (!isValidDate(date)) {
        setDateError(true)
        handleChange({
          target: {
            name: fieldName,
            value: "",
          },
        })
      } else {
        setDateError(false)
        handleChange({
          target: {
            name: fieldName,
            value: date,
          },
        })
      }
    }

    if (isEditMode && !disabled) {
      return (
        <div style={{ marginBottom: "10px" }}>
          <p
            style={{
              width: "fit",
              lineHeight: "5px",
            }}
          >
            {label}:{" "}
          </p>
          <input
            className="form-input"
            type="date"
            name={fieldName}
            value={value}
            onChange={handleDateChange}
            style={{ width: "100%", padding: "5px", height: "40px" }}
          />
          {dateError && <Typography color="error">Invalid date</Typography>}
        </div>
      )
    } else {
      return (
        <p className="detail-field" style={{}}>
          <strong>{label}: </strong>
          {value}
        </p>
      )
    }
  }

  const renderValueField = (label, value, fieldName, handleChange) => {
    const floatValue = parseFloat(value)
    const displayValue = isNaN(floatValue) ? "" : floatValue.toFixed(2)

    if (isEditMode) {
      return (
        <TextField
          label={label}
          name={fieldName}
          value={floatValue || ""}
          variant="outlined"
          fullWidth
          type="number"
          inputProps={{ step: "0.01" }}
          sx={{ marginBottom: "10px" }}
          onChange={handleChange}
        />
      )
    } else {
      return (
        <p className="detail-field" style={{ fontFamily: "monospace" }}>
          <strong>{label}</strong>{" "}
          {value !== undefined ? parseFloat(value).toFixed(2) : ""}
        </p>
      )
    }
  }

  const handleadminComment = () => {
    // post to admin comments for this account
  }

  const renderSelectField = (
    label,
    table,
    value,
    fieldName,
    handleChange,
    disabled = false
  ) => {
    if (userRole === "admin") {
      disabled = false
    }

    const handleSelectChange = (event) => {
      const { value } = event.target
      handleChange({
        target: {
          name: fieldName,
          value: value,
        },
      })
    }

    // // Default to true if value is undefined or null
    // const resolvedValue = value !== undefined && value !== null ? value : true;

    if (isEditMode && !disabled) {
      return (
        <FormControl variant="outlined" fullWidth sx={{ marginBottom: "10px" }}>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            name={fieldName}
            value={value}
            onChange={handleSelectChange}
            fullWidth
          >
            {table.map((record) => (
              <MenuItem value={record.value}>{record.text}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    } else {
      const selection = table.filter((record) => record.value == value)
      return (
        <p className="detail-field">
          <strong>{label}: </strong>
          {value || ""}
        </p>
      )
    }
  }

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <StepBar currentStatus={claimDetails.status} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mx: "auto",
          }}
        >
          <div
            style={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              overflowY: "auto",
              display: "flex",
              flexDirection: "row-reverse",
              flexWrap: "wrap-reverse",
              width: "100%",
            }}
          >
            <div
              style={{
                flex: "1 0 30rem",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
              }}
            >
              {
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {((userRole == "user" && stage === "submit" && !isEditMode) ||
                    (userRole == "user" &&
                      stage === "collection" &&
                      !isEditMode) ||
                    (userRole === "admin" && !isEditMode)) && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleEditClick}
                      disabled={
                        (userRole !== "admin" &&
                          stage === "submit" &&
                          claimDetails.status != "Submit") ||
                        (userRole !== "admin" &&
                          stage === "collection" &&
                          claimCollect.srn !== null &&
                          claimCollect.srn !== "")
                      }
                    >
                      <p>Edit</p>
                    </Button>
                  )}

                  {(((stage === "submit" || stage === "collection") &&
                    isEditMode) ||
                    (userRole === "admin" && isEditMode)) && (
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          if (stage === "submit") {
                            handleSaveClick("submit")
                          } else if (stage === "collection") {
                            handleSaveClick("collection")
                          } else if (stage === "decision") {
                            handleSaveClick("decision")
                          } else if (stage === "pricing") {
                            handleSaveClick("pricing")
                          } else if (stage === "resolved") {
                            handleSaveClick("resolved")
                          }
                        }}
                      >
                        <p>Save</p>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ marginLeft: "10px" }}
                        onClick={handleCancelClick}
                      >
                        <p>Cancel</p>
                      </Button>
                    </div>
                  )}
                </Box>
              }
              {userRole === "admin" && (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "10px" }}
                    onClick={() => setShowCommentBox(true)}
                    disabled={isEditMode}
                  >
                    <p>Request Details</p>
                  </Button>
                </Box>
              )}
              {showCommentBox && (
                <Box sx={{ mt: 2, width: "100%", maxWidth: "600px" }}>
                  <TextField
                    label="Comments"
                    name="comment"
                    value={requestDetailsMessage}
                    onChange={(e) => setRequestDetailsMessage(e.target.value)}
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: "10px" }}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleRequestDetailsSubmit}
                    >
                      Submit Request
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setRequestDetailsMessage(""), setShowCommentBox(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              )}
              {userRole === "admin" && (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "10px" }}
                    onClick={handleNextStageClick}
                    disabled={
                      isEditMode ||
                      stage !== claimDetails.status.toLowerCase() ||
                      (stage === "submit" && !claimDetails.supplier_acc) ||
                      (stage === "collection" && !claimCollect.returned) ||
                      (stage === "decision" &&
                        (!claimDecision.outcome || !claimDecision.category)) ||
                      (stage === "pricing" &&
                        (!claimPricing.sign_warranty ||
                          !claimPricing.sign_matt ||
                          !claimPricing.sign_chris)) ||
                      claimDetails.status === "Resolved"
                    }
                  >
                    <p>Next Stage</p>
                  </Button>
                </Box>
              )}
              {(userRole === "user" || userRole === "admin") && (
                <div className="file-upload">
                  <input
                    id="fileInput"
                    className="form-input"
                    type="file"
                    accept="image/*, application/pdf"
                    name="files"
                    multiple
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "10px" }}
                    onClick={() => document.getElementById("fileInput").click()}
                    disabled={isEditMode}
                  >
                    <DriveFolderUploadIcon sx={{ marginRight: "3px" }} />
                    <p>Upload Files</p>
                  </Button>
                  <dialog ref={dialogRef}>
                    <div>
                      <h2>Are you sure you want to upload these files?</h2>
                      <ul>
                        {Array.from(formData.files).map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>

                      <div className="confirm-buttons">
                        <Button
                          onClick={handleUploadFiles}
                          variant="contained"
                          color="secondary"
                          sx={{ mx: { xs: 0, md: 5 } }}
                        >
                          <p>YES</p>
                        </Button>
                        <Button
                          onClick={handleCancel}
                          variant="contained"
                          color="secondary"
                          sx={{ mx: { xs: 0, md: 5 } }}
                        >
                          <p>NO</p>
                        </Button>
                      </div>
                    </div>
                  </dialog>
                </div>
              )}
              {userRole === "admin" && (
                <Box>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      padding: "1rem",
                    }}
                  >
                    <TextField
                      label="Admin Comments"
                      name="comment"
                      value={adminCommentMessage}
                      onChange={(e) => setAdminCommentMessage(e.target.value)}
                      multiline
                      rows={2}
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: "10px", width: "20rem" }}
                    />
                    <Button
                      sx={{ mx: "auto", mt: "10px" }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleAdminCommentSubmit()
                      }}
                    >
                      <p>SAVE COMMENT</p>
                    </Button>

                    {claimType === "No Quibble" &&
                      claimDetails.status !== "Resolved" && (
                        <Button
                          sx={{ mx: "auto", mt: "10px" }}
                          variant="contained"
                          color="warning"
                          onClick={() => {
                            // handleDeleteClaim()
                            rejectRef.current.showModal()
                          }}
                        >
                          <p>Reject Claim</p>
                        </Button>
                      )}

                    <Button
                      sx={{
                        mx: "auto",
                        mt: "10px",
                      }}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        // handleDeleteClaim()
                        deleteRef.current.showModal()
                      }}
                    >
                      <p>Delete Claim</p>
                    </Button>
                    <Box sx={{ mt: 0 }}>
                      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        <strong>Next Step:</strong> {claimDetails.next_step}
                      </Typography>
                    </Box>
                  </div>
                </Box>
              )}
            </div>
          </div>
          <dialog ref={rejectRef}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2>Are you sure you want to reject this claim?</h2>
              <TextField
                label="Reason"
                value={rejectReason}
                onChange={(e) => {
                  setRejectReason(e.target.value)
                }}
                multiline
                sx={{ flex: "1", mt: "1rem" }}
                rows={7}
              />{" "}
              <div className="confirm-buttons">
                <Button
                  onClick={handleRejectClaim}
                  variant="contained"
                  color="secondary"
                  sx={{}}
                  disabled={rejectReason == ""}
                >
                  <p>YES, reject this claim</p>
                </Button>
                <Button
                  onClick={handleCancelReject}
                  variant="contained"
                  color="secondary"
                  sx={{}}
                >
                  <p>NO</p>
                </Button>
              </div>
            </div>
          </dialog>

          <dialog ref={deleteRef}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2>Are you sure you want to delete this claim?</h2>
              <TextField
                label="Reason"
                value={deleteReason}
                onChange={(e) => {
                  setDeleteReason(e.target.value)
                }}
                multiline
                sx={{ flex: "1", mt: "1rem" }}
                rows={7}
              />{" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deleteCheck}
                    onChange={(e) => {
                      setDeleteCheck(e.target.checked)
                    }}
                  />
                }
                label="I confirm that this claim, all of its attachments, reports (Inspection, CAR, FDR) and details will be deleted and there is no way to restore them. (The following will NOT be deleted: SCRs, PCRs and sent emails)."
              />
              <div className="confirm-buttons">
                <Button
                  onClick={handleDeleteClaim}
                  variant="contained"
                  color="error"
                  sx={{}}
                  disabled={deleteReason == "" || !deleteCheck}
                >
                  <p>YES, delete this claim</p>
                </Button>
                <Button
                  onClick={handleCancelDelete}
                  variant="contained"
                  color="secondary"
                  sx={{}}
                >
                  <p>NO</p>
                </Button>
              </div>
            </div>
          </dialog>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4, margin: "20px" }}>
            <Grid container spacing={2}>
              <ClaimDetails
                claimDetails={claimDetails}
                adminOverride={adminOverride || ""}
                setAdminOverride={setAdminOverride}
                claimCollection={claimCollect}
                claimDecision={claimDecision}
                customerDetails={customerDetails}
                renderField={renderField}
                renderBGAPartRef={renderBGAPartRef}
                renderValueField={renderValueField}
                renderMultilineField={renderMultilineField}
                handleInputChange={handleInputChange}
                userRole={userRole}
                renderFieldBlur={renderFieldBlur}
              />
              {stage === "submit" && (
                <ClaimImages claimDetails={claimDetails} />
              )}
              {stage === "collection" && (
                <ClaimCollect
                  claimCollect={claimCollect}
                  renderField={renderField}
                  renderMultilineField={renderMultilineField}
                  renderDateField={renderDateField}
                  renderSelectField={renderSelectField}
                  handleCollectChange={handleCollectChange}
                  sendCollectionEmail={sendCollectionEmail}
                  isEditMode={isEditMode}
                  userRole={userRole}
                />
              )}
              {stage === "decision" && (
                <ClaimDecision
                  claimDecision={claimDecision}
                  claimDetails={claimDetails}
                  renderField={renderField}
                  renderSelectField={renderSelectField}
                  renderMultilineField={renderMultilineField}
                  handleDecisionChange={handleDecisionChange}
                  isEditMode={isEditMode}
                  handleFileChange={handleFileChange}
                  userRole={userRole}
                  downloadTemplate={downloadTemplate}
                />
              )}
              {stage === "pricing" && (
                <ClaimPricing
                  claimDetails={claimDetails}
                  claimPricing={claimPricing}
                  renderValueField={renderValueField}
                  renderMultilineField={renderMultilineField}
                  handlePricingChange={handlePricingChange}
                  handleCheckboxChange={handleCheckboxChange}
                  // handleSignOffChange={handleSignOffChange}
                  handleFileChange={handleFileChange}
                  isEditMode={isEditMode}
                  downloadTemplate={downloadTemplate}
                  userRole={userRole}
                />
              )}
              {stage === "resolved" && (
                <ClaimResolved
                  userRole={userRole}
                  claimResolved={claimResolved}
                  claimPricing={claimPricing}
                  pricingTotal={pricingTotal}
                  claimDetails={claimDetails}
                  claimDecision={claimDecision}
                  renderSelectField={renderSelectField}
                  renderField={renderField}
                  handleResolvedChange={handleResolvedChange}
                  isEditMode={isEditMode}
                  handleDispute={handleDispute}
                />
              )}
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <Snackbar
        name="snackbar"
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          marginTop: "13vh",
          border: "2px solid lightgray",
          borderRadius: "5px",
        }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}
