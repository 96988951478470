import React, { useContext, useEffect, useState } from "react"
import { Container, TextField, Button, Typography, Box } from "@mui/material"
import { API_URL } from "../../config"
import AuthContext from "../../AuthContext"
import { useNavigate } from "react-router-dom"
import Spinner from "../Items/Spinner"

const Profile = () => {
  const { username, userRole, token, firstName, email } =
    useContext(AuthContext)
  const [profileData, setProfileData] = useState({
    acc_code: "",
    role: "",
    first_name: "",
    email: "",
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "BGA Warranty | Profile"
  }, [])
  // Fetch profile data on mount
  React.useEffect(() => {
    if (token) {
      setLoading(true)
      fetch(`${API_URL}/auth/whoami`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setProfileData({
            acc_code: data.acc_code,
            role: data.is_admin ? "admin" : "user",
            first_name: data.first_name ? data.first_name : "Anonymous",
            email: data.email ? data.email : "None",
          })
          setLoading(false)
        })
        .catch((error) => {
          setError("Failed to load profile data")
          setLoading(false)
        })
    }
  }, [token])

  const handleChange = (e) => {
    const { name, value } = e.target
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/change-password")
  }

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <Typography color="error">{error}</Typography>
  }

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Account code"
            name="acc_code"
            value={profileData.acc_code}
            onChange={handleChange}
            disabled
          />
          <TextField
            fullWidth
            margin="normal"
            label="First name"
            name="first_name"
            value={profileData.first_name}
            onChange={handleChange}
            disabled
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            value={profileData.email}
            onChange={handleChange}
            disabled
          />

          <Button
            // onClick={}
            variant="contained"
            color="secondary"
            sx={{ my: 4 }}
            type="submit"
          >
            <p>Change password</p>
          </Button>

          {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Update Profile
          </Button> */}
        </form>
      </Box>
    </Container>
  )
}

export default Profile
