import React, { useState, useEffect, useContext, useRef, useMemo } from "react"
import axios from "axios"
import exampleBatchCode from "../../assets/example_batch_code.png"
import { saveAs } from "file-saver"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import { useNavigate, useLocation } from "react-router-dom"
import "../Pages/style.css"
import AuthContext from "../../AuthContext"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import Copyright from "../Items/Copyright"
import CssBaseline from "@mui/material/CssBaseline"
import DeleteIcon from "@mui/icons-material/Delete"
import FieldRenderer from "./FieldRenderer"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import InfoTooltip from "../Items/InfoTooltip"
import Paper from "@mui/material/Paper"
import Spinner from "../Items/Spinner.js"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { CircularProgress } from "@mui/material"
const { API_URL } = require("../../config")

const StyledAutocompletePaper = styled(Paper)({
  width: "95%",
  marginLeft: "1px",
})

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    padding: "8.5px 14px",
  },
  "& .MuiInputLabel-root": {
    top: "-6px",
  },
})

/**
 * Form for submitting a No Quibble claim
 * @returns {JSX.Element}
 */
function NoQuibbleForm() {
  useEffect(() => {
    document.title = "BGA Warranty | NQ Form"
  }, [])

  const [formData, setFormData] = useState({
    customer_acc_code: "",

    art_num: "",
    batch_code: "",
    vehicle_reg: "",
    customer_ref: "",
    chassis_num: "",
    contact_number: "",
    mileage_units: "miles",
    mileage_fitted: "",
    mileage_failed: "",
    installation_date: "",
    removal_date: "",
    claim_description: "",
    // quantity: "",
    parts_value: "",
    labour_value: "",
    labour_time: "",
    labour_hourly_rate: "",
    labour_description: "",
    expense_value: "",
    expense_description: "",
    files: [],
  })
  const [customerOptions, setCustomerOptions] = useState([]) // New state for customer options
  const [customerLoading, setCustomerLoading] = useState(false) // Loading state for autocomplete
  const [errors, setErrors] = useState({})
  // const [errorState, setErrorState] = useState(false)
  const errorState = useMemo(() => Object.keys(errors).length > 0, [errors])
  const [partOptions, setPartOptions] = useState([])
  const [bulkFile, setBulkFile] = useState(null)
  const [drafts, setDrafts] = useState([])
  const [selectedDraft, setSelectedDraft] = useState(null)
  const [policyAccepted, setPolicyAccepted] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const { token, userRole, isAuthenticated } = useContext(AuthContext)
  const draftId = new URLSearchParams(location.search).get("draftId")
  const draftIndex = new URLSearchParams(location.search).get("draftIndex")

  const dialogRef = useRef(null)
  useEffect(() => {
    axios
      .get(`${API_URL}/drafts/all?type=no_quibble`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDrafts(response.data)
      })
      .catch((error) => {})
  }, [token])

  useEffect(() => {
    if (draftId) {
      axios
        .get(`${API_URL}/drafts/${draftId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          handleLoadDraft(response.data)
        })
        .catch((error) => {})
    }
  }, [draftId, token])

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const fetchCustomers = async (query) => {
    if (query.length < 2) return // Only fetch after 2 characters
    setCustomerLoading(true)
    try {
      const response = await axios.get(
        `${API_URL}/customer/search?q=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setCustomerOptions(response.data) // Assuming the response is an array of customer objects
    } catch (error) {
      console.error("Error fetching customers", error)
    } finally {
      setCustomerLoading(false)
    }
  }

  const handleCustomerChange = (event, value) => {
    // Handle customer selection
    if (value) {
      console.log("Selected customer:", value.acc_code)
      setFormData({
        ...formData,
        customer_acc_code: value.acc_code,
      })
    }
  }

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      files: event.target.files,
    })
  }

  const handleBulkFileChange = (event) => {
    setBulkFile(event.target.files[0])
  }

  const validateForm = () => {
    let newErrors = {}

    if (!formData.art_num) newErrors.art_num = "Part Number is required"
    if (!formData.batch_code) newErrors.batch_code = "Batch Code is required"

    // if (!formData.quantity) newErrors.quantity = "Quantity is required"

    //either provide vehicle reg or chassis num
    if (!formData.vehicle_reg && !formData.chassis_num) {
      newErrors.chassis_num =
        "You must provide either a Vehicle Registration or a Chassis Number"
    }

    if (!formData.contact_number)
      newErrors.contact_number = "You must provide your contact number"

    //mileage units
    if (!formData.mileage_units)
      newErrors.mileage_units = "Mileage Units are required"

    //mileage fitted and failed
    if (
      parseInt(formData.mileage_fitted, 10) >
      parseInt(formData.mileage_failed, 10)
    ) {
      newErrors.mileage_failed =
        "Mileage failed cannot be smaller than mileage fitted"
    }
    if (!formData.mileage_fitted)
      newErrors.mileage_fitted = "Mileage Fitted is required"
    if (!formData.mileage_failed)
      newErrors.mileage_failed = "Mileage Failed is required"

    //installation and removal date
    if (formData.installation_date > formData.removal_date) {
      newErrors.removal_date =
        "Removal date cannot occur before installation date"
    }
    if (!formData.installation_date)
      newErrors.installation_date = "Installation Date is required"
    if (!formData.removal_date)
      newErrors.removal_date = "Removal Date is required"

    if (!formData.claim_description)
      newErrors.claim_description = "Reason/Details are required"

    if (formData.files.length === 0)
      newErrors.files = "You must upload files with this claim"

    for (const file of formData.files) {
      if (!file.type.startsWith("image") && !file.type.endsWith("pdf")) {
        newErrors.files =
          "The accepted file types are .jpg, .jpeg, .png and .pdf"
      }
    }

    setErrors(newErrors)
    // setErrorState(Object.keys(newErrors).length !== 0)

    return Object.keys(newErrors).length === 0
  }

  const handleSubmitConfirmation = async (event) => {
    await axios
      .get(`${API_URL}/auth/whoami`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        navigate("/session-expired")
      })

    if (!validateForm()) return

    dialogRef.current.showModal()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!policyAccepted) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        policy: "You must accept the policy before submitting.",
      }))
      return
    }

    const data = new FormData()
    data.append("customer_acc_code", formData.customer_acc_code)
    data.append("art_num", formData.art_num)
    data.append("batch_code", formData.batch_code)
    data.append("customer_ref", formData.customer_ref || "")
    data.append("vehicle_reg", formData.vehicle_reg)
    data.append("chassis_num", formData.chassis_num)
    data.append("contact_number", formData.contact_number)
    data.append("mileage_units", formData.mileage_units)
    data.append("mileage_fitted", parseInt(formData.mileage_fitted, 10))
    data.append("mileage_failed", parseInt(formData.mileage_failed, 10))
    data.append("installation_date", formData.installation_date)
    data.append("removal_date", formData.removal_date)
    data.append("claim_description", formData.claim_description)
    // data.append("quantity", parseInt(formData.quantity, 10))
    data.append("parts_value", parseFloat(formData.parts_value) || 0.0)
    data.append("labour_value", 0)
    data.append("labour_time", 0)
    data.append("labour_hourly_rate", 0)
    data.append("labour_description", "")
    data.append("expense_value", 0)
    data.append("expense_description", "")
    data.append("claim_type", "No Quibble")
    data.append("product", 1)
    data.append("customer", 1)
    data.append("claim_date", new Date().toISOString().split("T")[0])
    data.append("status", "Submit")

    Array.from(formData.files).forEach((file) => {
      data.append("files", file)
    })

    dialogRef.current.close()

    setLoading(true)
    try {
      const response = await axios.post(
        `${API_URL}/claims/new/no_quibble`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (selectedDraft) {
        deleteDraft(selectedDraft.id) // Delete the current draft after successful submission
      }
      navigate("/success")
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          art_num: "Invalid part number",
        }))
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "An error occurred. Please try again later.",
        }))
      }
    }
    setLoading(false)
  }

  const handleBulkUpload = async (event) => {
    event.preventDefault()
    await axios
      .get(`${API_URL}/auth/whoami`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        navigate("/session-expired")
        return
      })

    if (!bulkFile) {
      return
    }

    const data = new FormData()
    data.append("file", bulkFile)

    try {
      const response = await axios.post(
        `${API_URL}/claims/new/no_quibble_csv`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      navigate("/new-claim/confirm", {
        state: { uploadedData: response.data },
      })
    } catch (error) {}
  }

  const handleSaveDraft = (event) => {
    event.preventDefault()

    const data = new FormData()
    data.append("claim_type", "No Quibble")

    data.append("art_num", formData.art_num || "")
    data.append("batch_code", formData.batch_code || "")
    data.append("customer_ref", formData.customer_ref || "")
    // data.append("quantity", parseInt(formData.quantity, 10) || 0)
    data.append("parts_value", parseFloat(formData.parts_value) || 0.0)
    data.append("vehicle_reg", formData.vehicle_reg || "")
    data.append("chassis_num", formData.chassis_num || "")
    data.append("contact_number", formData.contact_number || "")
    data.append("mileage_units", formData.mileage_units || "miles")
    data.append("mileage_fitted", parseInt(formData.mileage_fitted, 10) || 0)
    data.append("mileage_failed", parseInt(formData.mileage_failed, 10) || 0)
    data.append("installation_date", formData.installation_date)
    data.append("removal_date", formData.removal_date)
    data.append("claim_description", formData.claim_description || "")

    data.append("installer_name", "")
    data.append("installer_address1", "")
    data.append("installer_address2", "")
    data.append("installer_postcode", "")
    data.append("installer_county", "")
    data.append("installer_country", "")

    data.append("collection_address1", "")
    data.append("collection_address2", "")
    data.append("collection_postcode", "")
    data.append("collection_county", "")
    data.append("collection_country", "")

    data.append("labour_value", "")
    data.append("labour_time", "")
    data.append("labour_hourly_rate", "")
    data.append("labour_description", "")

    data.append("expense_value", "")
    data.append("expense_description", "")

    Array.from(formData.files).forEach((file) => {
      data.append("files", file)
    })

    setLoading(true)
    axios
      .post(`${API_URL}/drafts/no_quibble`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        navigate("/draft/success")
        window.location.reload()
      })
      .catch((error) => {})
    setLoading(false)
  }

  const handleLoadDraft = (draft) => {
    if (!draft) {
      clearDraftSelection()
      return
    }

    setFormData({
      art_num: draft.art_num || "",
      batch_code: draft.batch_code || "",
      customer_ref: draft.customer_ref || "",
      vehicle_reg: draft.vehicle_reg || "",
      chassis_num: draft.chassis_num || "",
      contact_number: draft.contact_number || "",
      mileage_units: draft.mileage_units || "",
      mileage_fitted: draft.mileage_fitted || "",
      mileage_failed: draft.mileage_failed || "",
      installation_date: draft.installation_date || "",
      removal_date: draft.removal_date || "",
      claim_description: draft.claim_description || "",
      // quantity: draft.quantity || "",
      parts_value: draft.parts_value || "",
      labour_value: draft.labour_value || "",
      labour_time: draft.labour_time || "",
      labour_hourly_rate: draft.labour_hourly_rate || "",
      labour_description: draft.labour_description || "",
      expense_value: draft.expense_value || "",
      expense_description: draft.expense_description || "",
      files: [], // Files need to be re-uploaded
    })
    setSelectedDraft(draft)
  }

  const handleDeleteDraft = (draftId) => {
    axios
      .delete(`${API_URL}/drafts/${draftId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDrafts(drafts.filter((draft) => draft.id !== draftId))
        if (selectedDraft && selectedDraft.id === draftId) {
          clearDraftSelection()
        }
      })
      .catch((error) => {})
  }

  const fields = [
    {
      name: "Customer Reference",
      required: false,
      description:
        "An optional reference name for this claim, so that this claim can be found easier",
      fieldName: "customer_ref",
      type: "text",
      value: formData.customer_ref,
      // error: errors.customer_ref,
    },
    {
      name: "Part Number",
      required: true,
      description: "This is the BGA reference part number",
      fieldName: "art_num",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.art_num,
      error: errors.art_num,
    },
    {
      name: "Batch Code",
      required: true,
      description: (
        <>
          {/* <!-- <p>{"You can find the batch code in the form B____-__-__"}</p> --> */}
          <img src={exampleBatchCode} alt="" height="100%" width="100%" />
          <p>
            {
              "In the image above, the batch code is B2405-33-0721 (B2405330721 also acceptable)"
            }
          </p>
        </>
      ),

      fieldName: "batch_code",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.batch_code,
      error: errors.batch_code,
    },
    // {
    //   name: "Quantity",
    //   required: true,
    //   description: "",
    //   fieldName: "quantity",
    //   type: "number",
    //   value: formData.quantity,
    //   error: errors.quantity,
    // },
    {
      name: "Total Part Value",
      required: true,
      description: (
        <>
          <p>Total price of:</p>
          <p>{"(BGA part)×(Quantity claimed)"} </p>
        </>
      ),
      fieldName: "parts_value",
      type: "number",
      value: formData.parts_value,
      error: errors.parts_value,
      inputProps: {
        step: "0.01",
      },
    },
    { type: "line" },
    {
      name: "Vehicle Registration",
      required: true,
      description: "The unique identifier assigned to a vehicle",
      fieldName: "vehicle_reg",
      type: "text",
      value: formData.vehicle_reg,
      error: errors.vehicle_reg,
    },
    {
      name: "Chassis Number",
      required: true,
      description: "The serial number of the vehicle's chassis",
      fieldName: "chassis_num",
      type: "text",
      value: formData.chassis_num,
      error: errors.chassis_num,
    },
    {
      name: "Contact Number",
      required: true,
      fieldName: "contact_number",
      type: "text",
      value: formData.contact_number,
      error: errors.contact_number,
    },
    { type: "line" },
    {
      name: "Mileage Units",
      required: true,
      description: "",
      fieldName: "mileage_units",
      type: "select",
      default: "miles",
      options: [
        { text: "Miles", value: "miles" },
        { text: "Kilometers (km)", value: "kilometers" },
      ],
      value: formData.mileage_units,
      error: errors.mileage_units,
    },
    {
      name: "Mileage Fitted",
      required: true,
      description:
        "The mileage recorded when the part was fitted to the vehicle",
      fieldName: "mileage_fitted",
      type: "number",
      style: { width: "50%", display: "inline-block" },
      value: formData.mileage_fitted,
      error: errors.mileage_fitted,
    },
    {
      name: "Mileage Failed",
      required: true,
      description: "The mileage recorded when the part failed",
      fieldName: "mileage_failed",
      type: "number",
      style: { width: "50%", display: "inline-block" },
      value: formData.mileage_failed,
      error: errors.mileage_failed,
    },
    { type: "line" },
    {
      name: "Installation Date",
      required: true,
      description: "",
      fieldName: "installation_date",
      type: "date",
      style: { width: "50%", display: "inline-block" },
      value: formData.installation_date,
      error: errors.installation_date,
    },

    {
      name: "Removal Date",
      required: true,
      description: "",
      fieldName: "removal_date",
      type: "date",
      style: { width: "50%", display: "inline-block" },
      value: formData.removal_date,
      error: errors.removal_date,
    },
    { type: "line" },
    {
      name: "Reason/Details",
      required: true,
      description: "",
      fieldName: "claim_description",
      type: "textarea",
      value: formData.claim_description,
      error: errors.claim_description,
    },
  ]

  const deleteDraft = (draftId) => {
    axios
      .delete(`${API_URL}/drafts/${draftId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDrafts(drafts.filter((draft) => draft.id !== draftId))
      })
      .catch((error) => {})
  }

  const accountField = (label, value, fieldName, handleChange, formData) => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState(value)
    const [loading, setLoading] = useState(false)
    const selectedValue = formData[fieldName]

    useEffect(() => {
      let active = true

      if (!inputValue) {
        setOptions([])
        return undefined
      }

      setLoading(true)
      axios
        .get(`${API_URL}/customer/search?q=${inputValue}`)
        .then((response) => {
          if (active) {
            setOptions(response.data || [])
            setLoading(false)
          }
        })
        .catch((error) => {
          setLoading(false)
          console.error("Error fetching customer data:", error)
        })

      return () => {
        active = false
      }
    }, [inputValue])

    return (
      <Autocomplete
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        loading={loading}
        inputValue={selectedValue}
        value={selectedValue}
        onInputChange={(event, newValue) => setInputValue(newValue)}
        onChange={(event, newValue) => {
          // setSelectedValue(newValue)
          handleChange({
            target: {
              name: fieldName,
              value: newValue ? newValue.acc_code : "",
            },
          })
        }}
        // isOptionEqualToValue={(option, value) => option.acc_code === value}
        getOptionLabel={(option) => `${option.acc_code} - ${option.customer}`}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            sx={{ marginBottom: "10px" }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    )
  }

  const clearDraftSelection = () => {
    setSelectedDraft(null)
    setFormData({
      customer_ref: "",
      art_num: "",
      batch_code: "",
      customer_ref: "",
      vehicle_reg: "",
      chassis_num: "",
      mileage_units: "miles",
      mileage_fitted: "",
      mileage_failed: "",
      installation_date: "",
      removal_date: "",
      claim_description: "",
      // quantity: "",
      parts_value: "",
      labour_value: "",
      labour_time: "",
      labour_hourly_rate: "",
      labour_description: "",
      expense_value: "",
      expense_description: "",
      files: [],
    })
  }

  const downloadTemplate = () => {
    // const csvContent = `Customer Ref,Part Number,Batch Code,Quantity,Total Parts Value,Vehicle Registration,Chassis Number,Mileage Units (miles/kilometers/km),Mileage Fitted,Mileage Failed,Installation Date (DD/MM/YYYY),Removal Date (DD/MM/YYYY),Description\n`
    const csvContent = `Customer Ref,Part Number,Batch Code,Total Parts Value (Do not include currency symbol),Vehicle Registration,Chassis Number,Mileage Units (miles/kilometers/km),Mileage Fitted,Mileage Failed,Installation Date (DD/MM/YYYY),Removal Date (DD/MM/YYYY),Description\n`
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    saveAs(blob, "form_template.csv")
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          paddingBottom: "80px",
        }}
      >
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 4,
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 0, mb: 5 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
              >
                No Quibble Form
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="text.secondary"
                paragraph
              >
                Please fill out all the required fields.
              </Typography>

              <Autocomplete
                options={[...drafts]} // Create a shallow copy without reversing
                getOptionLabel={(option) => {
                  const index = drafts.indexOf(option)
                  return index >= 0
                    ? `Draft ${index + 1}`
                    : `Draft ${draftIndex}`
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  handleLoadDraft(newValue)
                }}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    label="Start from Draft"
                    variant="outlined"
                    margin="normal"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <span>
                      {(() => {
                        const draftIndex = drafts.indexOf(option)
                        return draftIndex >= 0
                          ? `Draft ${draftIndex + 1}`
                          : `Draft ${draftIndex}`
                      })()}
                    </span>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDeleteDraft(option.id)
                      }}
                      sx={{ marginLeft: "auto" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                sx={{ width: "70%", mb: 2 }}
                value={selectedDraft}
              />

              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <form className="claim-form" onSubmit={handleSubmit}>
                  <p
                    className="form-text"
                    style={{ marginBottom: "10px", fontSize: "0.9em" }}
                  >
                    Fields marked with * are required.
                  </p>
                  {errors.general && (
                    <Typography color="error" align="center" gutterBottom>
                      {errors.general}
                    </Typography>
                  )}

                  {userRole === "admin" && (
                    <Autocomplete
                      options={customerOptions}
                      getOptionLabel={(option) =>
                        `${option.acc_code} - ${option.customer}`
                      }
                      loading={customerLoading}
                      onInputChange={(event, newInputValue) => {
                        fetchCustomers(newInputValue)
                      }}
                      onChange={handleCustomerChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Account"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              backgroundColor: "#fff",
                              borderRadius: "4px",
                              padding: "8.5px 14px",
                            },
                            endAdornment: (
                              <>
                                {customerLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      sx={{ width: "96.5%", mb: 2 }}
                    />
                  )}
                  <FieldRenderer fields={fields} handler={handleChange} />
                  <label className="form-label">
                    File Upload*
                    <InfoTooltip
                      placement="right-end"
                      title="File Upload"
                      content="Files/Images that are necessary for this claim. This could include: Images of the part to be claimed, invoices of the purchase and other related documents"
                    />
                    <small style={{ opacity: "40%" }}>
                      <p>Required: </p>
                      <p> - Image of Batch Code</p>
                    </small>
                    <input
                      className="form-input"
                      type="file"
                      accept="image/*, application/pdf"
                      name="files"
                      multiple
                      onChange={handleFileChange}
                    />
                  </label>
                  <small style={{ opacity: "40%" }}>
                    (accepted file types: .jpg, .jpeg, .png, .pdf)
                  </small>
                  <br />
                  <small style={{ opacity: "40%" }}>
                    (Maximum upload size: 10MB)
                  </small>
                  {errors.files && (
                    <Typography color="error">{errors.files}</Typography>
                  )}
                  <FormControlLabel
                    sx={{ mb: "10px" }}
                    control={
                      <Checkbox
                        checked={policyAccepted}
                        onChange={(e) => setPolicyAccepted(e.target.checked)}
                        color="primary"
                        name="policyAccepted"
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "0.8em" }}>
                        I accept the{" "}
                        <a
                          style={{ color: "#F2AC00" }}
                          href="/policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          name="policy"
                        >
                          claim policy
                        </a>{" "}
                        and have submitted the required details/documents.
                      </Typography>
                    }
                  />
                  {loading && <Spinner />}
                  {errors.policy && (
                    <Typography color="error" sx={{ mb: "10px" }}>
                      {errors.policy}
                    </Typography>
                  )}
                  {errorState === true && (
                    <Box>
                      <p style={{ color: "red" }}>
                        One or more of the fields you submitted are incorrect.
                      </p>
                    </Box>
                  )}
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      name="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!policyAccepted}
                      onClick={handleSubmitConfirmation}
                    >
                      <p>Submit</p>
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleSaveDraft}
                      sx={{ color: "#F2AC00" }}
                    >
                      <p>Save Draft</p>
                    </Button>
                    <dialog ref={dialogRef}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        You are about to submit a no quibble. Please make sure
                        the details submitted are correct:
                        <div style={{ display: "flex", flex: 0, gap: "3rem" }}>
                          <Button
                            style={{ flex: 1 }}
                            variant="contained"
                            color="secondary"
                            disabled={!policyAccepted}
                            onClick={handleSubmit}
                            name="submit_confirm"
                          >
                            <p>Submit</p>
                          </Button>
                          <Button
                            style={{ flex: 1 }}
                            variant="contained"
                            color="error"
                            disabled={!policyAccepted}
                            onClick={() => dialogRef.current.close()}
                          >
                            <p>Cancel</p>
                          </Button>
                        </div>
                      </div>
                    </dialog>
                  </Box>
                </form>
              </Paper>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <hr
                  style={{
                    border: "1px solid #ccc",
                    width: "93%",
                    margin: "5px",
                    marginBottom: "20px",
                    marginTop: "25px",
                  }}
                />
                <Typography
                  marginTop="10px"
                  variant="h6"
                  align="center"
                  color="text.secondary"
                  paragraph
                >
                  Or upload a CSV file for bulk entries.
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadTemplate}
                  sx={{ mb: 2 }}
                >
                  <p>Download Template</p>
                </Button>

                <Paper
                  sx={{
                    p: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <form className="claim-form" onSubmit={handleBulkUpload}>
                    <label className="form-label">
                      Upload CSV:
                      <input
                        className="form-input"
                        type="file"
                        name="csv_file"
                        accept=".csv"
                        onChange={handleBulkFileChange}
                      />
                    </label>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        name="submit_csv"
                        sx={{ mb: 2 }}
                      >
                        <p>Upload CSV</p>
                      </Button>
                    </Box>
                  </form>
                </Paper>
              </Box>
            </Box>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default NoQuibbleForm
