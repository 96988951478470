import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer"
import reportStyle from "../Reports/ReportStyle.js"
import axios from "axios"

const { API_URL } = require("../../config")

/**
 * Fetch claim data from the backend
 * @param {*} claimNo
 * @param {*} token
 * @returns
 */
async function fetchClaimData(claimNo, token) {
  try {
    if (!token) throw new Error("No token available")
    const headers = { headers: { Authorization: `Bearer ${token}` } }

    const accCode = claimNo.split("-")[0]

    const [
      detailsResponse,
      collectionResponse,
      decisionResponse,
      pricingResponse,
      adminOverrideResponse,
      // customerDetailsResponse,
    ] = await Promise.all([
      axios.get(`${API_URL}/claims/${claimNo}/submit`, headers),
      axios.get(`${API_URL}/claims/${claimNo}/collection`, headers),
      axios.get(`${API_URL}/claims/${claimNo}/decision`, headers),
      axios.get(`${API_URL}/claims/${claimNo}/pricing`, headers),
      axios.get(`${API_URL}/claims/${claimNo}/submit/admin_override`, headers),
      // axios.get(`${API_URL}/customer/acc_code/${accCode}`, headers),
    ]).then()

    const customerDetailsResponse = await axios.get(
      `${API_URL}/customer/acc_code/${detailsResponse.data.acc_code}`,
      headers
    )

    const debitResponse = await axios.get(
      `${API_URL}/claims/${claimNo}/submit/debit`,
      headers
    )

    const supplierResponse = await axios.get(
      `${API_URL}/products/supplier?q=${debitResponse.data}`,
      headers
    )
    // setClaimDetails(detailsResponse.data)
    // setClaimCollection(collectionResponse.data)
    //
    // setClaimDecision(decisionResponse.data)
    return {
      details: detailsResponse.data,
      collection: collectionResponse.data,
      decision: decisionResponse.data,
      pricing: pricingResponse.data,
      // customerDetails: customer,
      customerDetails: customerDetailsResponse.data,
      supplier: supplierResponse.data,
      adminOverride: adminOverrideResponse.data,
    }
  } catch (error) {
    console.error("Error fetching data:", error)
  }
}

/**
 * Container for images in the PDF
 * @param {*} param0
 * @returns
 */
const PDFImageContainer = ({ images, index }) => (
  <View style={reportStyle.imageContainer}>
    {images &&
      images.map((image, imgIndex) => (
        <>
          <View style={reportStyle.image}>
            <Image key={imgIndex} src={URL.createObjectURL(image)} />
            <Text style={reportStyle.imageCaption}>{`Figure ${index + 1}.${
              imgIndex + 1
            }`}</Text>
          </View>
        </>
      ))}
  </View>
)

export { fetchClaimData, PDFImageContainer }
