import React, { useState } from "react"
import Button from "@mui/material/Button"
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer"
import logo from "../../assets/POPPY-BGA-LOGO.png"

import reportStyle from "./ReportStyle.js"

import { PDFImageContainer } from "../Items/Report.js"

const PDFRow = ({ label, value }) => (
  <View style={reportStyle.row}>
    <Text style={reportStyle.label}>{label}</Text>
    <Text style={reportStyle.value}>{value}</Text>
  </View>
)

const FDRDocument = ({ formData }) => (
  <Document>
    <Page size="A4" style={reportStyle.page}>
      {/* Header with Logo */}
      <View style={reportStyle.header}>
        <Image style={reportStyle.logo} src={logo} />
      </View>
      <Text style={reportStyle.title}>Factory Debit Report (FDR1)</Text>
      {/* Claim Information Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Claim Information</Text>
      </View>
      <PDFRow label="Claim Number:" value={formData.info.claimNumber} />
      <PDFRow label="SRN:" value={formData.info.srn} />
      {/* Product Information Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Product Information</Text>
      </View>
      <PDFRow label="Supplier Number:" value={formData.info.supplierNumber} />
      <PDFRow label="Supplier:" value={formData.info.supplier} />

      <PDFRow
        label="CAR Reference Number:"
        value={formData.info.carReferenceNumber}
      />
      <PDFRow label="BGA Part Number:" value={formData.info.bgaPartNumber} />
      <PDFRow label="Batch Code:" value={formData.info.batchCode} />
      <PDFRow
        label="Claim Cost:"
        value={formData.info.currency + formData.info.claimCost}
      />

      <PDFRow
        label="Date Fitted:"
        value={new Date(formData.info.dateFitted).toDateString()}
      />
      <PDFRow
        label="Date Removed:"
        value={new Date(formData.info.dateRemoved).toDateString()}
      />
      <PDFRow
        label="Distance Covered:"
        value={
          formData.info.distanceCovered + " " + formData.info.distanceUnits
        }
      />
      <PDFRow
        label="Date Raised:"
        value={new Date(formData.info.dateRaised).toDateString()}
      />

      <PDFRow label="Report Raised By:" value={formData.info.raisedBy} />
      {/* Explanation Section */}
      <View style={reportStyle.sectionHeader}>
        <Text>Explanation</Text>
      </View>
      <PDFRow label="Explanation:" value={formData.info.explanation} />
    </Page>

    <Page size="A4" style={reportStyle.page}>
      <View style={reportStyle.sectionHeader}>
        <Text>Images</Text>
      </View>
      <PDFImageContainer images={formData.info.images.slice(0, 4)} index={0} />
    </Page>
    <Page size="A4" style={reportStyle.page}>
      <View style={reportStyle.sectionHeader}>
        <Text>Images</Text>
      </View>
      <PDFImageContainer images={formData.info.images.slice(4, 8)} index={1} />
    </Page>
  </Document>
)

function RenderFDR({ formData, style }) {
  return (
    <PDFViewer style={style}>
      <FDRDocument formData={formData} />
    </PDFViewer>
  )
}

function DownloadFDR({ formData, children }) {
  return (
    <PDFDownloadLink
      document={<FDRDocument formData={formData} />}
      fileName="FDR_Report.pdf"
    >
      {children}
    </PDFDownloadLink>
  )
}

export { RenderFDR, DownloadFDR }
