import React, { useState, useEffect, useContext, useRef, useMemo } from "react"
import axios from "axios"
import exampleBatchCode from "../../assets/example_batch_code.png"
import { saveAs } from "file-saver"
import { styled } from "@mui/material/styles"
import { useNavigate, useLocation } from "react-router-dom"
import "../Pages/style.css"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import AuthContext from "../../AuthContext"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Container from "@mui/material/Container"
import Copyright from "../Items/Copyright"
import CssBaseline from "@mui/material/CssBaseline"
import DeleteIcon from "@mui/icons-material/Delete"
import FieldRenderer from "./FieldRenderer"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import InfoTooltip from "../Items/InfoTooltip"
import Paper from "@mui/material/Paper"
import Spinner from "../Items/Spinner.js"
import TextField from "@mui/material/TextField"
import { CircularProgress } from "@mui/material"
import Typography from "@mui/material/Typography"

const { API_URL } = require("../../config")

const StyledAutocompletePaper = styled(Paper)({
  width: "95%",
  marginLeft: "1px",
})

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    padding: "8.5px 14px",
  },
  "& .MuiInputLabel-root": {
    top: "-6px",
  },
})

/**
 * Form for submitting a full claim
 * @returns {JSX.Element}
 */
function FullClaimForm() {
  useEffect(() => {
    document.title = "BGA Warranty | FC Form"
  }, [])
  const [formData, setFormData] = useState({
    art_num: "",
    batch_code: "",
    vehicle_reg: "",
    customer_ref: "",
    chassis_num: "",
    contact_number: "",
    mileage_units: "miles",
    mileage_fitted: "",
    mileage_failed: "",
    installation_date: "",
    removal_date: "",
    installer_name: "",
    installer_address1: "",
    installer_address2: "",
    installer_postcode: "", // or zip code
    installer_county: "", // county
    installer_country: "", //

    collection_address1: "",
    collection_address2: "",
    collection_postcode: "", // or zip code
    collection_county: "", // county
    collection_country: "", //

    claim_description: "",
    // quantity: "",
    parts_value: "",
    labour_value: "",
    labour_time: "",
    labour_hourly_rate: "",
    labour_description: "",
    expense_value: "",
    expense_description: "",
    files: [],
  })
  const [customerOptions, setCustomerOptions] = useState([]) // New state for customer options
  const [customerLoading, setCustomerLoading] = useState(false) // Loading state for autocomplete
  const [additionalParts, setAdditionalParts] = useState([])
  const [errors, setErrors] = useState({})
  // const [errorState, setErrorState] = useState(false)
  const errorState = useMemo(() => Object.keys(errors).length > 0, [errors])
  const [partOptions, setPartOptions] = useState([])
  const [bulkFile, setBulkFile] = useState(null)
  const [drafts, setDrafts] = useState([])
  const [selectedDraft, setSelectedDraft] = useState(null)
  const [policyAccepted, setPolicyAccepted] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const { token, userRole } = useContext(AuthContext)
  const draftId = new URLSearchParams(location.search).get("draftId")
  const draftIndex = new URLSearchParams(location.search).get("draftIndex")

  const dialogRef = useRef(null)

  useEffect(() => {
    axios
      .get(`${API_URL}/drafts/all?type=full_claim`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDrafts(response.data)
      })
      .catch((error) => {
        console.error("Error fetching drafts:", error)
      })
  }, [token])

  useEffect(() => {
    if (draftId) {
      axios
        .get(`${API_URL}/drafts/${draftId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          handleLoadDraft(response.data)
        })
        .catch((error) => {
          console.error("Error loading draft:", error)
        })
    }
  }, [draftId, token])

  useEffect(() => {
    setLoading(false)
  }, [])

  const fetchCustomers = async (query) => {
    if (query.length < 2) return // Only fetch after 2 characters
    setCustomerLoading(true)
    try {
      const response = await axios.get(
        `${API_URL}/customer/search?q=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setCustomerOptions(response.data) // Assuming the response is an array of customer objects
    } catch (error) {
      console.error("Error fetching customers", error)
    } finally {
      setCustomerLoading(false)
    }
  }

  const handleCustomerChange = (event, value) => {
    // Handle customer selection
    if (value) {
      console.log("Selected customer:", value.acc_code)
      setFormData({
        ...formData,
        customer_acc_code: value.acc_code,
      })
    }
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      files: event.target.files,
    })
  }

  const handleBulkFileChange = (event) => {
    setBulkFile(event.target.files[0])
  }

  const validateForm = () => {
    let newErrors = {}

    if (!formData.art_num) newErrors.art_num = "Part Number is required"
    if (!formData.batch_code) newErrors.batch_code = "Batch Code is required"

    // if (!formData.quantity) newErrors.quantity = "Quantity is required"
    if (!formData.parts_value)
      newErrors.parts_value = "Total Part Value is required"

    //either provide vehicle reg or chassis num
    if (!formData.vehicle_reg && !formData.chassis_num) {
      newErrors.chassis_num =
        "You must provide either a Vehicle Registration or a Chassis Number"
    }

    if (!formData.contact_number)
      newErrors.contact_number = "Contact number is required"

    //mileage units
    if (!formData.mileage_units)
      newErrors.mileage_units = "Mileage Units are required"

    //mileage fitted and failed
    if (
      parseInt(formData.mileage_fitted, 10) >
      parseInt(formData.mileage_failed, 10)
    ) {
      newErrors.mileage_failed =
        "Mileage failed cannot be smaller than mileage fitted"
    }
    if (!formData.mileage_fitted)
      newErrors.mileage_fitted = "Mileage Fitted is required"
    if (!formData.mileage_failed)
      newErrors.mileage_failed = "Mileage Failed is required"

    //installation and removal date
    if (formData.installation_date > formData.removal_date) {
      newErrors.removal_date =
        "Removal date cannot occur before installation date"
    }
    if (!formData.installation_date)
      newErrors.installation_date = "Installation Date is required"
    if (!formData.removal_date)
      newErrors.removal_date = "Removal Date is required"

    if (!formData.installer_name)
      newErrors.installer_name = "Installer Name is required"
    if (!formData.installer_address1)
      newErrors.installer_address1 = "Installer Address is required"
    if (!formData.installer_address2)
      newErrors.installer_address2 = "Installer City is required"
    if (!formData.installer_county)
      newErrors.installer_county = "Installer County/State is required"
    if (!formData.installer_country)
      newErrors.installer_country = "Installer Country is required"
    if (!formData.installer_postcode)
      newErrors.installer_postcode = "Installer Post Code/Zip Code is required"

    if (!formData.collection_address1)
      newErrors.collection_address1 = "Collection Address is required"
    if (!formData.collection_address2)
      newErrors.collection_address2 = "Collection City is required"
    if (!formData.collection_postcode)
      newErrors.collection_postcode =
        "Collection Post Code/Zip Code is required"
    if (!formData.collection_county)
      newErrors.collection_county = "Collection County/State is required"
    if (!formData.collection_country)
      newErrors.collection_country = "Collection Country is required"

    if (!formData.claim_description)
      newErrors.claim_description = "Reason/Details are required"
    if (!formData.labour_value)
      newErrors.labour_value = "Labour Value is required"
    if (!formData.labour_time) newErrors.labour_time = "Labour Time is required"
    if (!formData.labour_hourly_rate)
      newErrors.labour_hourly_rate = "Labour Hourly Rate is required"
    if (!formData.labour_description)
      newErrors.labour_description = "Labour Description is required"

    if (!formData.labour_description)
      newErrors.labour_description = "Labour Description is required"

    if (formData.files.length === 0)
      newErrors.files = "You must upload files with this claim"

    for (const file of formData.files) {
      if (!file.type.startsWith("image") && !file.type.endsWith("pdf")) {
        newErrors.files =
          "The accepted file types are .jpg, .jpeg, .png and .pdf"
      }
    }

    if (!policyAccepted) newErrors.policy = "You must accept the policy"

    additionalParts.forEach((part, index) => {
      if (!part.part) {
        newErrors[`part_${index}`] = `Part ${index + 1} is required`
      }
      if (!part.description) {
        newErrors[`part_description_${index}`] = `Part ${
          index + 1
        } description is required`
      }
      if (!part.value) {
        newErrors[`part_value_${index}`] = `Part ${index + 1} value is required`
      }
    })

    setErrors(newErrors)
    // setErrorState(Object.keys(newErrors).length !== 0)

    return Object.keys(newErrors).length === 0
  }

  const handleSubmitConfirmation = async (event) => {
    await axios
      .get(`${API_URL}/auth/whoami`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        navigate("/session-expired")
      })
    if (!validateForm()) return
    dialogRef.current.showModal()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const data = new FormData()
    data.append("customer_acc_code", formData.customer_acc_code)
    data.append("art_num", formData.art_num)
    data.append("batch_code", formData.batch_code)
    data.append("customer_ref", formData.customer_ref || "")

    data.append("vehicle_reg", formData.vehicle_reg)
    data.append("chassis_num", formData.chassis_num)
    data.append("contact_number", formData.contact_number)

    data.append("mileage_units", formData.mileage_units)
    data.append("mileage_fitted", parseInt(formData.mileage_fitted, 10))
    data.append("mileage_failed", parseInt(formData.mileage_failed, 10))

    data.append("installation_date", formData.installation_date)
    data.append("removal_date", formData.removal_date)

    data.append("installer_name", formData.installer_name)
    data.append("installer_address1", formData.installer_address1)
    data.append("installer_address2", formData.installer_address2)
    data.append("installer_postcode", formData.installer_postcode)
    data.append("installer_county", formData.installer_county)
    data.append("installer_country", formData.installer_country)

    data.append("collection_address1", formData.collection_address1)
    data.append("collection_address2", formData.collection_address2)
    data.append("collection_postcode", formData.collection_postcode)
    data.append("collection_county", formData.collection_county)
    data.append("collection_country", formData.collection_country)

    data.append("claim_description", formData.claim_description)
    // data.append("quantity", parseInt(formData.quantity, 10))
    data.append("parts_value", parseFloat(formData.parts_value) || 0.0)
    data.append("labour_value", parseFloat(formData.labour_value) || 0.0)
    data.append("labour_time", parseFloat(formData.labour_time) || 0.0)
    data.append(
      "labour_hourly_rate",
      parseFloat(formData.labour_hourly_rate) || 0.0
    )
    data.append("labour_description", formData.labour_description)
    data.append("expense_value", parseFloat(formData.expense_value) || 0.0)
    data.append("expense_description", formData.expense_description)

    Array.from(additionalParts).forEach((part) => {
      data.append(`additional_part`, part.part)
    })

    Array.from(additionalParts).forEach((part) => {
      data.append(`additional_part_description`, part.description)
    })

    Array.from(additionalParts).forEach((part) => {
      data.append(`additional_part_value`, part.value)
    })

    Array.from(formData.files).forEach((file) => {
      data.append("files", file)
    })
    data.append("claim_type", "Full Claim")
    data.append("product", 1) // Replace with actual product ID
    data.append("customer", 1) // Replace with actual customer ID
    data.append("claim_date", new Date().toISOString().split("T")[0])
    data.append("status", "Submit")

    Array.from(formData.files).forEach((file) => {
      data.append("files", file)
    })

    dialogRef.current.close()

    setLoading(true)
    try {
      const response = await axios.post(
        `${API_URL}/claims/new/full_claim`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (selectedDraft) {
        deleteDraft(selectedDraft.id) // Delete the current draft after successful submission
      }
      navigate("/success")
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          art_num: "Invalid part number",
        }))
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "An error occurred. Please try again later.",
        }))
      }
    }

    setLoading(false)
  }

  const handleLoadDraft = (draft) => {
    if (!draft) {
      clearDraftSelection()
      return
    }

    setFormData({
      art_num: draft.art_num || "",
      batch_code: draft.batch_code || "",
      customer_ref: draft.customer_ref || "",
      vehicle_reg: draft.vehicle_reg || "",
      chassis_num: draft.chassis_num || "",
      contact_number: draft.contact_number || "",
      mileage_units: draft.mileage_units || "",
      mileage_fitted: draft.mileage_fitted || "",
      mileage_failed: draft.mileage_failed || "",
      installation_date: draft.installation_date || "",
      removal_date: draft.removal_date || "",

      installer_name: draft.installer_name || "",
      installer_address1: draft.installer_address1 || "",
      installer_address2: draft.installer_address2 || "",
      installer_postcode: draft.installer_postcode || "",
      installer_county: draft.installer_county || "",
      installer_country: draft.installer_country || "",

      collection_address1: draft.collection_address1 || "",
      collection_address2: draft.collection_address2 || "",
      collection_postcode: draft.collection_postcode || "",
      collection_county: draft.collection_county || "",
      collection_country: draft.collection_country || "",

      claim_description: draft.claim_description || "",
      // quantity: draft.quantity || "",
      parts_value: draft.parts_value || "",
      labour_value: draft.labour_value || "",
      labour_time: draft.labour_time || "",
      // SQL: labour_hourly_rate, Backend/Frontend: labour_rate
      labour_hourly_rate: draft.labour_rate || "", // yeah this was a weird choice of naming
      labour_description: draft.labour_description || "",
      expense_value: draft.expense_value || "",
      expense_description: draft.expense_description || "",
      files: [], // Files need to be re-uploaded
    })
    setSelectedDraft(draft)
  }

  const handleSaveDraft = (event) => {
    event.preventDefault()

    const data = new FormData()
    data.append("art_num", formData.art_num)
    data.append("batch_code", formData.batch_code)
    data.append("customer_ref", formData.customer_ref)

    data.append("vehicle_reg", formData.vehicle_reg)
    data.append("chassis_num", formData.chassis_num)
    data.append("contact_number", formData.contact_number)
    data.append("mileage_units", formData.mileage_units)
    data.append("mileage_fitted", parseInt(formData.mileage_fitted, 10) || 0)
    data.append("mileage_failed", parseInt(formData.mileage_failed, 10) || 0)
    data.append("installation_date", formData.installation_date)
    data.append("removal_date", formData.removal_date)

    data.append("installer_name", formData.installer_name)
    data.append("installer_address1", formData.installer_address1)
    data.append("installer_address2", formData.installer_address2)
    data.append("installer_postcode", formData.installer_postcode)
    data.append("installer_county", formData.installer_county)
    data.append("installer_country", formData.installer_country)

    data.append("collection_address1", formData.collection_address1)
    data.append("collection_address2", formData.collection_address2)
    data.append("collection_postcode", formData.collection_postcode)
    data.append("collection_county", formData.collection_county)
    data.append("collection_country", formData.collection_country)

    data.append("claim_description", formData.claim_description)
    // data.append("quantity", parseInt(formData.quantity, 10) || 0)
    data.append("parts_value", parseFloat(formData.parts_value) || 0)
    data.append("labour_value", parseFloat(formData.labour_value) || 0)
    data.append("labour_time", parseFloat(formData.labour_time) || 0)
    data.append(
      "labour_hourly_rate",
      parseFloat(formData.labour_hourly_rate) || 0
    )
    data.append("labour_description", formData.labour_description)
    data.append("expense_value", parseFloat(formData.expense_value) || 0)
    data.append("expense_description", formData.expense_description)
    Array.from(additionalParts).forEach((part) => {
      data.append(`additional_part`, part.part)
    })

    Array.from(additionalParts).forEach((part) => {
      data.append(`additional_part_description`, part.description)
    })

    Array.from(additionalParts).forEach((part) => {
      data.append(`additional_part_value`, parseFloat(part.value) || 0)
    })

    data.append("claim_type", "Full Claim")
    data.append("product", 1) // Replace with actual product ID
    data.append("customer", 1) // Replace with actual customer ID
    data.append("claim_date", new Date().toISOString().split("T")[0])
    data.append("status", "Submit")

    // Append each file to the form data
    Array.from(formData.files).forEach((file) => {
      data.append("files", file)
    })

    setLoading(true)
    axios
      .post(`${API_URL}/drafts/full_claim`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        navigate("/draft/success")
        window.location.reload()
      })
      .catch((error) => {
        console.error("Error saving draft:", error)
      })
    setLoading(false)
  }

  const handleDeleteDraft = (draftId) => {
    axios
      .delete(`${API_URL}/drafts/${draftId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDrafts(drafts.filter((draft) => draft.id !== draftId))
        if (selectedDraft && selectedDraft.id === draftId) {
          clearDraftSelection()
        }
      })
      .catch((error) => {
        console.error("Error deleting draft:", error)
      })
  }

  const clearDraftSelection = () => {
    setSelectedDraft(null)
    setFormData({
      art_num: "",
      batch_code: "",
      customer_ref: "",
      claim_description: "",
      vehicle_reg: "",
      chassis_num: "",
      contact_number: "",
      mileage_units: "miles",
      mileage_fitted: "",
      mileage_failed: "",
      installation_date: "",
      removal_date: "",

      installer_name: "",
      installer_address1: "",
      installer_address2: "",
      installer_postcode: "",
      installer_county: "",
      installer_country: "",

      collection_address1: "",
      collection_address2: "",
      collection_postcode: "",
      collection_county: "",
      collection_country: "",

      // quantity: "",
      parts_value: "",
      labour_value: "",
      labour_time: "",
      labour_hourly_rate: "",
      labour_description: "",
      expense_value: "",
      expense_description: "",
      files: [],
    })
    setAdditionalParts([])
  }

  const deleteDraft = (draftId) => {
    axios
      .delete(`${API_URL}/drafts/${draftId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDrafts(drafts.filter((draft) => draft.id !== draftId))
      })
      .catch((error) => {
        console.error("Error deleting draft:", error)
      })
  }

  const addPart = () => {
    if (additionalParts.length < 10) {
      setAdditionalParts([
        ...additionalParts,
        { part: "", description: "", value: "" },
      ])
    }
  }

  const handlePartChange = (index, event) => {
    const newParts = additionalParts.slice()
    newParts[index][event.target.name] = event.target.value
    setAdditionalParts(newParts)
  }

  const handleDeletePart = (index) => {
    const newParts = additionalParts.slice()
    newParts.splice(index, 1)
    setAdditionalParts(newParts)
  }

  const fields = [
    {
      name: "Customer Reference",
      required: false,
      description:
        "An optional reference name for this claim, so that this claim can be found easier",
      fieldName: "customer_ref",
      type: "text",
      value: formData.customer_ref,
      // error: errors.customer_ref,
    },
    {
      name: "Part Number",
      required: true,
      description: "This is the BGA reference part number",
      fieldName: "art_num",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.art_num,
      error: errors.art_num,
    },
    {
      name: "Batch Code",
      required: true,
      description: (
        <>
          {/* <p>{"You can find the batch code in the form B____-__-__"}</p> */}
          <img src={exampleBatchCode} alt="" height="100%" width="100%" />
          <p>{"In the image above, the batch code is B2405-33-0721"}</p>
        </>
      ),

      fieldName: "batch_code",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.batch_code,
      error: errors.batch_code,
    },
    // {
    //   name: "Quantity",
    //   required: true,
    //   description: "",
    //   fieldName: "quantity",
    //   type: "number",
    //   value: formData.quantity,
    //   error: errors.quantity,
    // },
    {
      name: "Total Part Value",
      required: true,
      description: (
        <>
          <p>Total price of the BGA Part being claimed</p>
        </>
      ),
      fieldName: "parts_value",
      type: "number",
      value: formData.parts_value,
      error: errors.parts_value,
      inputProps: {
        step: "0.01",
      },
    },
    {
      name: "Reason/Details",
      required: true,
      description: "",
      fieldName: "claim_description",
      type: "textarea",
      value: formData.claim_description,
      error: errors.claim_description,
    },
    { type: "line" },
    {
      name: "Vehicle Registration",
      required: true,
      description: "The unique identifier assigned to a vehicle",
      fieldName: "vehicle_reg",
      type: "text",
      value: formData.vehicle_reg,
      error: errors.vehicle_reg,
    },
    {
      name: "Chassis Number",
      required: true,
      description: "The serial number of the vehicle's chassis",
      fieldName: "chassis_num",
      type: "text",
      value: formData.chassis_num,
      error: errors.chassis_num,
    },
    {
      name: "Contact Number",
      required: true,
      description: "Your contact number",
      fieldName: "contact_number",
      type: "text",
      value: formData.contact_number,
      error: errors.contact_number,
    },
    { type: "line" },
    {
      name: "Mileage Units",
      required: true,
      description: "",
      fieldName: "mileage_units",
      type: "select",
      default: "miles",
      options: [
        { text: "Miles", value: "miles" },
        { text: "Kilometers (km)", value: "kilometers" },
      ],
      value: formData.mileage_units,
      error: errors.mileage_units,
    },
    {
      name: "Mileage Fitted",
      required: true,
      description:
        "The mileage recorded when the part was fitted to the vehicle",
      fieldName: "mileage_fitted",
      type: "number",
      style: {
        width: "50%",
        display: "inline-block",
      },
      value: formData.mileage_fitted,
      error: errors.mileage_fitted,
    },
    {
      name: "Mileage Failed",
      required: true,
      description: "The mileage recorded when the part failed",
      fieldName: "mileage_failed",
      type: "number",
      style: {
        width: "50%",
        display: "inline-block",
      },
      value: formData.mileage_failed,
      error: errors.mileage_failed,
    },
    { type: "line" },
    {
      name: "Installation Date",
      required: true,
      description: "",
      fieldName: "installation_date",
      type: "date",
      style: { width: "50%", display: "inline-block" },
      value: formData.installation_date,
      error: errors.installation_date,
    },

    {
      name: "Removal Date",
      required: true,
      description: "",
      fieldName: "removal_date",
      type: "date",
      style: { width: "50%", display: "inline-block" },
      value: formData.removal_date,
      error: errors.removal_date,
    },

    {
      name: "Installer Name",
      required: true,
      description: "",
      fieldName: "installer_name",
      type: "text",
      value: formData.installer_name,
      error: errors.installer_name,
    },
    {
      name: "Installer Address",
      required: true,
      description: "",
      fieldName: "installer_address1",
      type: "text",
      value: formData.installer_address1,
      error: errors.installer_address1,
    },
    {
      name: "Installer City",
      required: true,
      description: "",
      fieldName: "installer_address2",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.installer_address2,
      error: errors.installer_address2,
    },
    {
      name: "Installer County/State",
      required: true,
      description: "",
      fieldName: "installer_county",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.installer_county,
      error: errors.installer_county,
    },
    {
      name: "Installer Country",
      required: true,
      description: "",
      fieldName: "installer_country",
      style: { width: "50%", display: "inline-block" },
      type: "text",
      value: formData.installer_country,
      error: errors.installer_country,
    },
    {
      name: "Installer Post Code/Zip Code",
      required: true,
      description: "",
      fieldName: "installer_postcode",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.installer_postcode,
      error: errors.installer_postcode,
    },

    { type: "line" },
    {
      name: "Collection Address",
      required: true,
      description: "",
      fieldName: "collection_address1",
      type: "text",
      value: formData.collection_address1,
      error: errors.collection_address1,
    },
    {
      name: "Collection City",
      required: true,
      description: "",
      fieldName: "collection_address2",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.collection_address2,
      error: errors.collection_address2,
    },
    {
      name: "Collection County/State",
      required: true,
      description: "",
      fieldName: "collection_county",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.collection_county,
      error: errors.collection_county,
    },
    {
      name: "Collection Country",
      required: true,
      description: "",
      fieldName: "collection_country",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.collection_country,
      error: errors.collection_country,
    },
    {
      name: "Collection Post Code/Zip Code",
      required: true,
      description: "",
      fieldName: "collection_postcode",
      type: "text",
      style: { width: "50%", display: "inline-block" },
      value: formData.collection_postcode,
      error: errors.collection_postcode,
    },
    { type: "line" },
    {
      name: "Labour Value",
      required: true,
      description: "",
      fieldName: "labour_value",
      type: "number",
      value: formData.labour_value,
      error: errors.labour_value,
    },
    {
      name: "Labour Time (hr)",
      required: true,
      description: "",
      fieldName: "labour_time",
      type: "number",
      style: { width: "50%", display: "inline-block" },
      value: formData.labour_time,
      error: errors.labour_time,
    },
    {
      name: "Labour Hourly Rate",
      required: true,
      description: "",
      fieldName: "labour_hourly_rate",
      type: "number",
      style: { width: "50%", display: "inline-block" },
      value: formData.labour_hourly_rate,
      error: errors.labour_hourly_rate,
    },
    {
      name: "Labour Description",
      required: true,
      description: "",
      fieldName: "labour_description",
      type: "textarea",
      value: formData.labour_description,
      error: errors.labour_description,
    },
    { type: "line" },
    {
      name: "Expense Value",
      required: false,
      description:
        "The cost for any additional expenses, not covered by labour or parts (e.g. Towing costs)",
      fieldName: "expense_value",
      type: "number",
      value: formData.expense_value,
      error: errors.expense_value,
    },
    {
      name: "Expense Description",
      required: false,
      description:
        "Expenses for parts other than the claimed part, labour, expenses, etc. (e.g. Oil)",
      fieldName: "expense_description",
      type: "text_area",
      value: formData.expense_description,
    },
  ]

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
          paddingBottom: "80px",
        }}
      >
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 4,
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 0, mb: 5 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
              >
                Full Claim Form
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="text.secondary"
                paragraph
              >
                Please fill out all the required fields.
              </Typography>

              <Autocomplete
                options={[...drafts].reverse()} // Create a shallow copy and reverse it
                getOptionLabel={(option) => {
                  const index = drafts.indexOf(option)
                  return index >= 0
                    ? `Draft ${drafts.length - index}`
                    : `Draft ${draftIndex}`
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  handleLoadDraft(newValue)
                }}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    label="Start from Draft"
                    variant="outlined"
                    margin="normal"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <span>
                      {(() => {
                        const draftIndex = drafts.indexOf(option)
                        return draftIndex >= 0
                          ? `Draft ${drafts.length - draftIndex}`
                          : `Draft ${draftIndex}`
                      })()}
                    </span>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDeleteDraft(option.id)
                      }}
                      sx={{ marginLeft: "auto" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                sx={{ width: "70%", mb: 2 }}
                value={selectedDraft}
              />

              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <form className="claim-form" onSubmit={handleSubmit}>
                  <p
                    className="form-text"
                    style={{ marginBottom: "10px", fontSize: "0.9em" }}
                  >
                    Fields marked with * are required.
                  </p>
                  {errors.general && (
                    <Typography color="error" align="center" gutterBottom>
                      {errors.general}
                    </Typography>
                  )}
                  {userRole === "admin" && (
                    <Autocomplete
                      options={customerOptions}
                      getOptionLabel={(option) =>
                        `${option.acc_code} - ${option.customer}`
                      }
                      loading={customerLoading}
                      onInputChange={(event, newInputValue) => {
                        fetchCustomers(newInputValue)
                      }}
                      onChange={handleCustomerChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Account"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              backgroundColor: "#fff",
                              borderRadius: "4px",
                              padding: "8.5px 14px",
                            },
                            endAdornment: (
                              <>
                                {customerLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      sx={{ width: "96.5%", mb: 2 }}
                    />
                  )}
                  <FieldRenderer fields={fields} handler={handleChange} />
                  <Typography sx={{ mb: "10px", fontSize: "0.9em " }}>
                    <strong>Additional Expense Parts </strong>
                    <InfoTooltip
                      placement="right-end"
                      title="Expense Parts"
                      content="Expenses for parts other than the claimed part, labour, expenses, etc. (e.g. Oil)"
                    />
                  </Typography>
                  {additionalParts.map((part, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", mb: 2 }}
                    >
                      <TextField
                        label={`Part ${index + 1}`}
                        name="part"
                        value={part.part}
                        onChange={(e) => handlePartChange(index, e)}
                        sx={{ marginRight: 2, flex: 1 }}
                        error={!!errors[`part_${index}`]}
                        helperText={errors[`part_${index}`]}
                      />
                      <TextField
                        label={`Part ${index + 1} Description`}
                        name="description"
                        value={part.description}
                        onChange={(e) => handlePartChange(index, e)}
                        sx={{ marginRight: 2, flex: 1 }}
                        error={!!errors[`part_description_${index}`]}
                        helperText={errors[`part_description_${index}`]}
                      />
                      <TextField
                        label={`Part ${index + 1} Value`}
                        name="value"
                        type="number"
                        step="0.01"
                        value={part.value}
                        onChange={(e) => handlePartChange(index, e)}
                        sx={{ marginRight: 2, flex: 1 }}
                        error={!!errors[`part_value_${index}`]}
                        helperText={errors[`part_value_${index}`]}
                      />
                      <IconButton
                        onClick={() => handleDeletePart(index)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                  {additionalParts.length < 10 && (
                    <Button
                      onClick={addPart}
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleIcon />}
                      sx={{ mb: 2 }}
                    >
                      Add Part
                    </Button>
                  )}
                  <hr
                    style={{
                      border: "1px solid #ccc",
                      width: "93%",
                      margin: "5px",
                      marginBottom: "20px",
                      marginTop: "25px",
                    }}
                  />
                  <label className="form-label">
                    File Upload*
                    <InfoTooltip
                      placement="right-end"
                      title="File Upload"
                      content="Files/Images that are necessary for this claim. This could include: Images of the part to be claimed, invoices of the purchase and other related documents"
                    />
                    <small style={{ opacity: "40%" }}>
                      <p>Required: </p>
                      <p> - Proof of purchase</p>
                      <p> - Proof of fitting</p>
                      <p> - Invoice/Pro former invoice</p>
                    </small>
                    <input
                      className="form-input"
                      type="file"
                      accept="image/*, application/pdf"
                      name="files"
                      multiple
                      onChange={handleFileChange}
                    />
                    <small style={{ opacity: "40%" }}>
                      (accepted file types: .jpg, .jpeg, .png, .pdf)
                    </small>
                    <br />
                    <small style={{ opacity: "40%" }}>
                      (Maximum upload size: 10MB)
                    </small>
                    {errors.files && (
                      <Typography color="error">{errors.files}</Typography>
                    )}
                  </label>
                  <FormControlLabel
                    sx={{ mb: "10px" }}
                    control={
                      <Checkbox
                        checked={policyAccepted}
                        onChange={(e) => setPolicyAccepted(e.target.checked)}
                        color="primary"
                        name="policyAccepted"
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "0.8em" }}>
                        {"I accept the "}
                        <a
                          style={{ color: "#F2AC00" }}
                          href="/policy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          claim policy
                        </a>
                        {" and have submitted the required details/documents."}
                      </Typography>
                    }
                  />
                  {loading && <Spinner />}
                  {errors.policy && (
                    <Typography color="error" sx={{ mb: "10px" }}>
                      {errors.policy}
                    </Typography>
                  )}
                  {errorState && (
                    <Box>
                      <p style={{ color: "red" }}>
                        One or more of the fields you submitted are incorrect.
                      </p>
                    </Box>
                  )}
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={!policyAccepted}
                      onClick={handleSubmitConfirmation}
                      name="submit"
                    >
                      <p>Submit</p>
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleSaveDraft}
                      sx={{ color: "#F2AC00" }}
                    >
                      <p>Save Draft</p>
                    </Button>

                    <dialog ref={dialogRef}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        You are about to submit a full claim. Please make sure
                        the details submitted are correct:
                        <div style={{ display: "flex", flex: 0, gap: "3rem" }}>
                          <Button
                            style={{ flex: 1 }}
                            variant="contained"
                            color="secondary"
                            disabled={!policyAccepted}
                            onClick={handleSubmit}
                            name="submit_confirm"
                          >
                            <p>Submit</p>
                          </Button>
                          <Button
                            style={{ flex: 1 }}
                            variant="contained"
                            color="error"
                            disabled={!policyAccepted}
                            onClick={() => dialogRef.current.close()}
                          >
                            <p>Cancel</p>
                          </Button>
                        </div>
                      </div>
                    </dialog>
                  </Box>
                </form>
              </Paper>
            </Box>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default FullClaimForm
