import React, { useState } from "react"
import axios from "axios"
import { API_URL } from "../../config"

const Invite = () => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleInvite = (e) => {
    e.preventDefault()
    axios
      .post(`${API_URL}/accounts/invite/`, { email })
      .then((response) => {
        setMessage("Invitation sent!")
      })
      .catch((error) => {
        setMessage("Error sending invitation")
      })
  }

  return (
    <div>
      <form onSubmit={handleInvite}>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit">Send Invite</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  )
}

export default Invite
